import * as k02 from '../actions/k02.actions';

export interface State {
  k02: any;
  selected_policy: String;
  policy_list: [];
  policy_details: any;
  data: {};
}

export const initialState = {
  selected_policy: '',
  policy_list: [],
  policy_details: {},
  data: {},
    'personal_information': {
        'K02_03': 'SLA Testing',
        'K02_04': 'Male',
        'K02_05': '07/07/1973'
    },
    'contact_information': {
        'K02_08': 'Flat G, 50/F, standardlife building,',
        'K02_09': '40 - 50 Standardlife Road, Quarry Bay',
        'K02_10': 'Hong Kong',
        'K02_11': 'Hong Kong',
        'K02_13': '2879XXXX33',
        'K02_14': '',
        'K02_15': '9888XXXX',
        'K02_17': 'benovelty.tester1@gmail.com',
        'eStatement': '',
    }
};

export function reducer(state = initialState, action: k02.Actions) {
  switch (action.type) {
    /*
    case k02.GO_TO_PAGE_K02: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'personal_information': {
              'K02_03': payload.clientPolicy.clientName,
              'K02_04': payload.clientPolicy.sex,
              'K02_05': payload.clientPolicy.dob
          },
          'contact_information': {
              'K02_08': payload.clientPolicy.address1,
              'K02_09': payload.clientPolicy.address2,
              'K02_10': payload.clientPolicy.address3,
              'K02_11': payload.clientPolicy.address4,
              'K02_13': payload.clientPolicy.phoneNo_Home,
              'K02_14': payload.clientPolicy.phoneNo_Office,
              'K02_15': payload.clientPolicy.phoneNo_Mobile,
              'K02_17': payload.clientPolicy.emailAddress,
              'eStatement': payload.clientPolicy.eStatement,
          }
      });
    }
    */

    case k02.CHANGE_POLICY: {
      return Object.assign({}, state, {
        ...state,
        selected_policy: action.payload,
        policy_details: state.data[action.payload]
      });
      // const selected_policy = action.payload;
      // const selected_account = state.data[selected_policy] ? state.data[selected_policy]['account_list'][0] : {};
      // return Object.assign({}, state, {
      //   ...state,
      //   future_investment_list: [],
      //   allocation_total_percentage: 0,
      //   h01_page_content: {
      //     ...state.h01_page_content,
      //     ...state.data[selected_policy],
      //     selected_policy: selected_policy,
      //     selected_account: selected_account
      //   }
      // });
    }

    case k02.GO_TO_PAGE_K02: {
      // console.log(JSON.stringify(action.payload));
      const [policy_list, data] = transformResponse(action.payload);

      //  Select the first policy by default.
      const selected_policy = policy_list[0];
      const policy_details = data[selected_policy];
      return Object.assign({}, state, {
        ...state,
        selected_policy: selected_policy,
        policy_list: policy_list,
        policy_details: policy_details,
        data: {
          ...state.data,
          ...data
        }
      });
    }

    default:
      return state;
  }

  function transformResponse(res) {
    try {
      const policies = res.clientPolicy;
      // const policies = res.clientInfo;
      const policy_list = [];
      const data = {};

      //const p_info = policies;
      policies.forEach(p => {
        const p_info = p;
        if (p_info && p_info !== {}) {
          policy_list.push(p_info.policyNo);
          data[p_info.policyNo] = {
            K02_03: p_info.clientName || '-',
            K02_04: p_info.sex || '-',
            K02_05: p_info.dob || '-',
            K02_08: p_info.address1 || '-',
            K02_09: p_info.address2 || '-',
            K02_10: p_info.address3 || '-',
            K02_11: p_info.address4 || '-',
            K02_13: p_info.phoneNo_Home || '-',
            K02_14: p_info.phoneNo_Office || '-',
            K02_15: p_info.phoneNo_Mobile || '-',
            K02_17: p_info.emailAddress || '-',
            eStatement: p_info.eStatement || '-'
          };
        }
      });

      return [policy_list, data];
    } catch (err) {
      console.log('k02 transform failed', err);
    }
  }
}
