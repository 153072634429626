import * as la01 from '../actions/la01.actions';
import _ from 'lodash';
import { convertCurrency } from '../utils';

export interface State {
  selected_policy: String;
  policy_list: [];
  policy_details: any;
  data: {};
}

export const initialState = {
  selected_policy: '',
  policy_list: [],
  policy_details: {},
  data: {}
  // initial_h01_policy: '',
  // future_investment_list: [],
  // allocation_total_percentage: 0,
  // selected_future_investment_index: 0,
  // transaction_no: '',
  // policy_list: [],
  // data: {},
  // h01_page_content: {
  //   tableData: {
  //     tableData: [],
  //     tableSummary: {}
  //   },
  //   chartData: [],
  //   policy_details: {},
  //   account_list: [],
  //   selected_policy: '',
  //   selected_account: {},
  // },
  // h01a_page_content: {
  //   fund_manager_list: [],
  //   fund_list: []
  // }
};

export function reducer(state = initialState, action: la01.Actions) {
  switch (action.type) {
    case la01.CHANGE_POLICY: {
      return Object.assign({}, state, {
        ...state,
        selected_policy: action.payload,
        policy_details: state.data[action.payload]
      });
      // const selected_policy = action.payload;
      // const selected_account = state.data[selected_policy] ? state.data[selected_policy]['account_list'][0] : {};
      // return Object.assign({}, state, {
      //   ...state,
      //   future_investment_list: [],
      //   allocation_total_percentage: 0,
      //   h01_page_content: {
      //     ...state.h01_page_content,
      //     ...state.data[selected_policy],
      //     selected_policy: selected_policy,
      //     selected_account: selected_account
      //   }
      // });
    }

    case la01.GO_TO_PAGE_LA01: {
      // console.log(JSON.stringify(action.payload));
      const [policy_list, data] = transformResponse(action.payload);

      //  Select the first policy by default.
      const selected_policy = policy_list[0];
      const policy_details = data[selected_policy];
      return Object.assign({}, state, {
        ...state,
        selected_policy: selected_policy,
        policy_list: policy_list,
        policy_details: policy_details,
        data: {
          ...state.data,
          ...data
        }
      });
    }

    default:
      return state;
  }
}

function transformResponse(res) {
  try {
    const policies = res[0].policy;
    const policy_list = [];
    const data = {};

    policies.forEach(p => {
      const p_info = p.policyInfoView;
      if (p_info && p_info !== {}) {
        policy_list.push(p_info.policyNo);
        data[p_info.policyNo] = {
          LA01_02: p_info.policyNo,
          LA01_03: p_info.riskToleranceLevel || '-',
          LA01_04: p_info.isEligibleForDFund || '-',
          LA01_05: p_info.isEligibleForIncomeFund || '-',
          LA01_06: p_info.riskProfileLastUpdateDateInStrn || '-',
          is_joint: p_info.isJointOwnerPolicy === 'Y',
          can_enter_in_plus: p_info.canEnterInPlus,
          LoginDateTime: res[0].returnStatus.loginDateTime
        };
      }
    });
    console.log(data);
    return [policy_list, data];
  } catch (err) {
    console.log('la01 transform failed', err);
  }
}
