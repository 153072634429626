import { Action } from '@ngrx/store';

export const GO_TO_PAGE_K05 = '[Navigation Component] GoToPageK05 ';
export const SET_BANK_ACCOUNTS = '[k05 Component] SetBankAccounts';

export class GoToPageK05 implements Action { readonly type = GO_TO_PAGE_K05; constructor(public payload: any) { } }

export class SetK05BankAccounts implements Action { readonly type = SET_BANK_ACCOUNTS; constructor(public payload: any) { } }

export type Actions =
  GoToPageK05 |
  SetK05BankAccounts;
