import * as h01 from '../actions/h01.actions';
import _ from 'lodash';
import { convertCurrency } from '../utils';

export interface State {
  future_investment_list: [];
  allocation_total_percentage: Number;
  selected_future_investment_index: Number;
  transaction_no: String;
  policy_list: [];
  data: any;
  h01_page_content: any;
  h01a_page_content: any;
}

export const initialState = {
  initial_h01_policy: '',
  future_investment_list: [],
  allocation_total_percentage: 0,
  selected_future_investment_index: 0,
  transaction_no: '',
  policy_list: [],
  data: {},
  h01_page_content: {
    tableData: {
      tableData: [],
      tableSummary: []
    },
    chartData: [],
    policy_details: {},
    account_list: [],
    selected_policy: '',
    selected_account: {},
  },
  h01a_page_content: {
    fund_manager_list: [],
    fund_list: []
  }
};

export function reducer(state = initialState, action: h01.Actions) {
  switch (action.type) {
    case h01.SET_SELECTED_FUTURE_INVESTMENT_INDEX: {
      const index = action.payload;
      return Object.assign({}, state, {
        ...state,
        selected_future_investment_index: index
      });
    }

    case h01.ADD_FUTURE_INVESTMENT: {
      const investment = action.payload;
      const new_future_investment = JSON.parse(JSON.stringify(state.future_investment_list));
      new_future_investment.push(investment);
      let percentage = state.allocation_total_percentage;
      percentage += action.payload.allocation_amount;
      return Object.assign({}, state, {
        ...state,
        future_investment_list: new_future_investment,
        allocation_total_percentage: percentage
      });
    }

    case h01.UPDATE_FUTURE_INVESTMENT: {
      const investment = action.payload;
      const new_future_investment = JSON.parse(JSON.stringify(state.future_investment_list));
      const index = state.selected_future_investment_index;
      const percentage = state.allocation_total_percentage - new_future_investment[index].allocation_amount + investment.allocation_amount;
      new_future_investment[index] = investment;
      return Object.assign({}, state, {
        ...state,
        future_investment_list: new_future_investment,
        allocation_total_percentage: percentage
      });
    }

    case h01.DELETE_FUTURE_INVESTMENT: {
      const new_future_investment = JSON.parse(JSON.stringify(state.future_investment_list));
      const index = action.payload;
      const percentage = state.allocation_total_percentage - new_future_investment[index].allocation_amount;
      new_future_investment.splice(index, 1);
      return Object.assign({}, state, {
        ...state,
        future_investment_list: new_future_investment,
        allocation_total_percentage: percentage
      });
    }

    case h01.SET_POLICY_LIST: {
      const policy_list = action.payload;
      return Object.assign({}, state, {
        ...state,
        policy_list: policy_list
      });
    }

    case h01.CHANGE_POLICY: {
      const selected_policy = action.payload;
      const selected_account = state.data[selected_policy] ? state.data[selected_policy]['account_list'][0] : {};
      return Object.assign({}, state, {
        ...state,
        future_investment_list: [],
        allocation_total_percentage: 0,
        h01_page_content: {
          ...state.h01_page_content,
          ...state.data[selected_policy],
          selected_policy: selected_policy,
          selected_account: selected_account
        }
      });
    }

    case h01.CHANGE_ACCOUNT: {
      const account = state.h01_page_content['account_list'].find(acc => acc.policyAcctName === action.payload);
      return Object.assign({}, state, {
        ...state,
        future_investment_list: [],
        allocation_total_percentage: 0,
        h01_page_content: {
          ...state.h01_page_content,
          selected_account: account
        }
      });
    }

    case h01.SET_H01_CHART_AND_TABLE_DATA: {
      const tableData = transformTableData(action.payload.tableData);
      const chartData = transformChartData(action.payload.chartData);
      return Object.assign({}, state, {
        ...state,
        h01_page_content: {
          ...state.h01_page_content,
          tableData: tableData,
          chartData: chartData
        }
      });
    }

    case h01.SET_FUND_MANAGER_LIST: {
      const fund_manager_list = action.payload;
      return Object.assign({}, state, {
        ...state,
        h01a_page_content: {
          ...state.h01a_page_content,
          fund_manager_list: fund_manager_list
        }
      });
    }

    case h01.SET_FUND_LIST: {
      const fund_list = action.payload;
      return Object.assign({}, state, {
        ...state,
        h01a_page_content: {
          ...state.h01a_page_content,
          fund_list: fund_list
        }
      });
    }

    case h01.SET_TRANSACTION_NO: {
      const transaction_no = action.payload;
      return Object.assign({}, state, {
        ...state,
        transaction_no: transaction_no
      });
    }

    case h01.GO_TO_PAGE_H01: {
      const data = transformResponse(action.payload);
      const selected_policy = (state.initial_h01_policy && state.initial_h01_policy !== '') ? state.initial_h01_policy : data['policy_no_list'][0];
      const selected_account = data[selected_policy] && data[selected_policy]['account_list']
        ? data[selected_policy]['account_list'][0]
        : null;
      // const selected_policy = data['policy_no_list'][0];
      // const selected_account = data[data['policy_no_list'][0]]['account_list'] ? data[data['policy_no_list'][0]]['account_list'][0] : null;
      return Object.assign({}, state, {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        h01_page_content: {
          ...state.h01_page_content,
          ...data[selected_policy],
          // ...data[data['policy_no_list'][0]],
          selected_policy: selected_policy,
          selected_account: selected_account
        }
      });
    }

    case h01.SET_SELECTED_POLICY_NO: {
      const policy_no = action.payload;
      return Object.assign({}, state, {
        ...state,
        initial_h01_policy: policy_no,
      });
    }

    case h01.RESET_H01_STATE: {
      return Object.assign({}, initialState);
    }

    default:
      return state;
  }
}

function transformTableData(tableData) {
  if (!tableData.policyCovrFundAllocationList || tableData.policyCovrFundAllocationList.length === 0) {
    return [];
  }
  const transformedTableData = {
    tableData: [],
    tableSummary: []
  };
  transformedTableData['tableData'] = tableData.policyCovrFundAllocationList.sort(function (a, b) {
    return a.fundAllocation < b.fundAllocation ? 1 : -1;
  }).map(row => {
    return {
      H01_12: row['fundName'],
      H01_13: row['fundCode'],
      H01_14: `${row['fundAllocation']}%`,
      H01_15: row['fundAllocationAmount']
    };
  });
  //  TODO: need actual test data to experiment on
  transformedTableData['tableSummary'] = [
    {
      H01_16: convertCurrency(transformedTableData['tableData'].reduce((acc, val) => {
        return acc + Number(val.H01_15);
      }, 0))
    }
  ];

  transformedTableData['tableData'].forEach(row => {
    row['H01_15'] = convertCurrency(row['H01_15']);
  });
  return transformedTableData;
}

function transformChartData(chartData) {
  if (!chartData.policyCovrFundAllocationList || chartData.policyCovrFundAllocationList.length === 0) {
    return [];
  }
  const transformedChartData = chartData.policyCovrFundAllocationList.map(pie => {
    return {
      H01_12: pie['fundName'],
      H01_13: pie['fundCode'],
      H01_14: `${pie['fundAllocation']}%`,
      H01_15: pie['fundAllocationAmount'],
    };
  });
  return transformedChartData;
}

function transformResponse(res) {
  try {
    const getSwitchInOrRedirectionPoliciesRes = res[0];
    const data = {};
    data['policy_no_list'] = getSwitchInOrRedirectionPoliciesRes['policy'].filter(policy => policy['policyInfoView']['policyPaymentModeEng'] !== 'Single');
    data['policy_no_list'] = data['policy_no_list'].map(policy => {
      return policy['policyInfoView']['policyNo'];
    });
    data['policy_no_list'].forEach((policyNo, i) => {
      data[policyNo] = { policy_details: {}, details_of_account: {} };
      data[policyNo]['policy_details'] = {
        H01_03: policyNo,
        H01_04: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyAccountView']['policyAcctName'],
        H01_05: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['owner'],
        H01_06: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['assignee'],
        H01_07: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['productName'],
        H01_08: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['policyCurrency'],
        risk_tol: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['riskToleranceLevel'] || '-',
        d_fund: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['isEligibleForDFund'] || '-',
        income_fund: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['isEligibleForIncomeFund'] || '-',
        last_updated: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['riskProfileLastUpdateDateInStrn'] || '-',
        is_joint: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView']['isJointOwnerPolicy'] === 'Y',
        valid_bank: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'] !== null && getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView']['achStatus'] === 'Y',
        incomeFund_paymentmethod: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'] != null ? getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView']['incomeFundPaymentMethod'] : '-',
        provision_accepted: getSwitchInOrRedirectionPoliciesRes.policy[i]['policyProvisionAcceptance'] !== null && getSwitchInOrRedirectionPoliciesRes.policy[i]['policyProvisionAcceptance']['provisionAcceptanceFlag'] === 'Y',
        server_timestamp: res[0].returnStatus['loginDateTime'] || '-'
      };
      data[policyNo]['account_list'] = getSwitchInOrRedirectionPoliciesRes['policy'][i]['policyAccountView'];
    });
    return data;
  } catch (err) {
    console.log('h01 transform failed', err);
  }
}
