import { combineReducers } from '@ngrx/store';
import * as page from '../actions/page.actions';
import * as b01 from './b01.reducer';
import * as f01 from './f01.reducer';
import * as f02 from './f02.reducer';
import * as f03 from './f03.reducer';
import * as f04 from './f04.reducer';
import * as g01 from './g01.reducer';
import * as h01 from './h01.reducer';
import * as i01 from './i01.reducer';
import * as l05 from './l05.reducer';
import * as ia01 from './ia01.reducer';
import * as ia02a from './ia02a.reducer';
import * as ia02b from './ia02b.reducer';
import * as ia02c from './ia02c.reducer';
import * as ia02d from './ia02d.reducer';
import * as ia02e from './ia02e.reducer';
import * as ib01 from './ib01.reducer';
import * as j01 from './j01.reducer';
import * as j03 from './j03.reducer';
import * as k02 from './k02.reducer';
import * as k05 from './k05.reducer';
import * as k03 from './k03.reducer';
import * as la01 from './la01.reducer';
import * as m02 from './m02.reducer';
import * as m03 from './m03.reducer';
import * as n03 from './n03.reducer';
import * as q01 from './q01.reducer';
import * as r01 from './r01.reducer';

export interface State {
  b01: b01.State;
  f01: f01.State;
  f02: f02.State;
  f03: f03.State;
  f04: f04.State;
  g01: g01.State;
  h01: h01.State;
  i01: i01.State;
  l05: l05.State;
  ia01: ia01.State;
  ia02a: ia02a.State;
  ia02b: ia02b.State;
  ia02c: ia02c.State;
  ia02d: ia02d.State;
  ia02e: ia02e.State;
  ib01: ib01.State;
  j01: j01.State;
  j03: j03.State;
  k02: k02.State;
  k03: k03.State;
  k05: k05.State;
  la01: la01.State;
  m02: m02.State;
  m03: m03.State;
  n03: n03.State;
  q01: q01.State;
  r01: r01.State;
}

export const initialState = {
  b01: b01.initialState,
  f01: f01.initialState,
  f02: f02.initialState,
  f03: f03.initialState,
  f04: f04.initialState,
  g01: g01.initialState,
  h01: h01.initialState,
  i01: i01.initialState,
  l05: l05.initialState,
  ia01: ia01.initialState,
  ia02a: ia02a.initialState,
  ia02b: ia02b.initialState,
  ia02c: ia02c.initialState,
  ia02d: ia02d.initialState,
  ia02e: ia02e.initialState,
  ib01: ib01.initialState,
  j01: j01.initialState,
  j03: j03.initialState,
  k02: k02.initialState,
  k03: k03.initialState.k03,
  k05: k05.initialState,
  la01: la01.initialState,
  m02: m02.initialState,
  m03: m03.initialState,
  n03: n03.initialState,
  q01: q01.initialState,
  r01: r01.initialState
};

export const reducers = {
  b01: b01.reducer,
  f01: f01.reducer,
  f02: f02.reducer,
  f03: f03.reducer,
  f04: f04.reducer,
  g01: g01.reducer,
  h01: h01.reducer,
  i01: i01.reducer,
  l05: l05.reducer,
  ia01: ia01.reducer,
  ia02a: ia02a.reducer,
  ia02b: ia02b.reducer,
  ia02c: ia02c.reducer,
  ia02d: ia02d.reducer,
  ia02e: ia02e.reducer,
  ib01: ib01.reducer,
  j01: j01.reducer,
  j03: j03.reducer,
  k02: k02.reducer,
  k03: k03.reducer,
  k05: k05.reducer,
  la01: la01.reducer,
  m02: m02.reducer,
  m03: m03.reducer,
  n03: n03.reducer,
  q01: q01.reducer,
  r01: r01.reducer
};

const rootReducer = combineReducers(reducers);
export function reducer(state: any, action: any) { // AOT
  return rootReducer(state, action);
}
