import { Action } from '@ngrx/store';

export const SET_SELECTED_FUTURE_INVESTMENT_INDEX = '[h01 Component] SetSelectedFutureInvestmentIndex';
export const ADD_FUTURE_INVESTMENT = '[h01 Component] AddFutureInvestment';
export const UPDATE_FUTURE_INVESTMENT = '[h01 Component] UpdateFutureInvestment';
export const DELETE_FUTURE_INVESTMENT = '[h01 Component] DeleteFutureInvestment';
export const SET_POLICY_LIST = '[h01 Component] SetPolicyList';
export const CHANGE_POLICY = '[h01 Component] ChangePolicy';
export const CHANGE_ACCOUNT = '[h01 Component] ChangeAccount';
export const SET_H01_CHART_AND_TABLE_DATA = '[h01 Component] SetH01ChartAndTableData';
export const SET_FUND_MANAGER_LIST = '[h01 Component] SetFundManagerList';
export const SET_FUND_LIST = '[h01 Component] SetFundList';
export const SET_TRANSACTION_NO = '[h01 Component] SetTransactionNo';
export const GO_TO_PAGE_H01 = '[Navigation Component] GoToPageH01 ';
export const SET_SELECTED_POLICY_NO = '[Navigation Component] InitPolicy';

export const RESET_H01_STATE = '[h01 Component] ResetH01State';

export class SetSelectedFutureInvestmentIndex implements Action {
    readonly type = SET_SELECTED_FUTURE_INVESTMENT_INDEX;
    constructor(public payload: Number) { }
}

export class AddFutureInvestment implements Action {
    readonly type = ADD_FUTURE_INVESTMENT;
    constructor(public payload: any) { }
}

export class UpdateFutureInvestment implements Action {
    readonly type = UPDATE_FUTURE_INVESTMENT;
    constructor(public payload: any) { }
}

export class DeleteFutureInvestment implements Action {
    readonly type = DELETE_FUTURE_INVESTMENT;
    constructor(public payload: any) { }
}

export class SetPolicyList implements Action {
    readonly type = SET_POLICY_LIST;
    constructor(public payload: any) { }
}

export class GoToPageH01 implements Action {
    readonly type = GO_TO_PAGE_H01;
    constructor(public payload: any) { }
}

export class ChangePolicy implements Action {
    readonly type = CHANGE_POLICY;
    constructor(public payload: any) { }
}

export class ChangeAccount implements Action {
    readonly type = CHANGE_ACCOUNT;
    constructor(public payload: any) { }
}

export class SetFundManagerList implements Action {
    readonly type = SET_FUND_MANAGER_LIST;
    constructor(public payload: any) { }
}

export class SetFundList implements Action {
    readonly type = SET_FUND_LIST;
    constructor(public payload: any) { }
}

export class SetTransactionNo implements Action {
    readonly type = SET_TRANSACTION_NO;
    constructor(public payload: any) { }
}

export class SetH01ChartAndTableData implements Action {
    readonly type = SET_H01_CHART_AND_TABLE_DATA;
    constructor(public payload: any) { }
}

export class SetSelectedPolicyNo implements Action {
    readonly type = SET_SELECTED_POLICY_NO;
    constructor(public payload: any) { }
}

export class ResetH01State implements Action {
    readonly type = RESET_H01_STATE;
    constructor() { }
}

export type Actions =
    SetSelectedFutureInvestmentIndex |
    AddFutureInvestment |
    UpdateFutureInvestment |
    DeleteFutureInvestment |
    SetPolicyList |
    GoToPageH01 |
    ChangePolicy |
    ChangeAccount |
    SetH01ChartAndTableData |
    SetFundManagerList |
    SetFundList |
    SetTransactionNo |
    SetSelectedPolicyNo |
    ResetH01State;
