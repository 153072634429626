import * as k05 from '../actions/k05.actions';

export interface State {
  k05: any;
}

export const initialState = {
    // 'banks_list': [
    //   {
    //     "bankInfo": {
    //       "bankCode": "552",
    //       "bankName": "AAREAL BANK AG, WIESBADEN, GERMANY",
    //       "validFlag": 1
    //     },
    //     "bankBranchesInfo": [
    //       {
    //         "bankCode": "552",
    //         "bankBranchName": "Wiesbaden Branch",
    //         "branchCode": "001",
    //         "validFlag": 1
    //       }
    //     ]
    //   }
    // ],
    'banks_list': [],
    'bank_accounts': [],
    'policy_list': [],
    'currency_list': []
};

export function reducer(state = initialState, action: k05.Actions) {
  switch (action.type) {
    case k05.GO_TO_PAGE_K05: {
      const payload = action.payload;
      console.log(payload);
      console.log(action);

      //  Filter the bank accounts
      payload.bankList = payload.bankList.filter(b => b.bankInfo.validFlag === 1);
      console.log('go to page k05 ', payload);
      return Object.assign({}, state, {
        ...state,
        banks_list: payload.bankList,
        currency_list: payload.currList
      });
    }

    case k05.SET_BANK_ACCOUNTS: {
      const payload = action.payload;
      console.log(payload);
      const allowedProduct = ['569', 'HKL01', 'PLP01'];

      const policies = payload[0].policy.filter(p => allowedProduct.includes(p.policyInfoView.productCode));
      console.log('k05 set bank accounts ', payload);
      return Object.assign({}, state, {
        ...state,
        policy_list: policies.map(p => p.policyInfoView),
        bank_accounts: policies.map(p => p.policyIncomeFundBankAccountView).filter(p => p !== null)

      });
    }

    default:
      return state;
  }
}
