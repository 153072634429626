import * as f03 from '../actions/f03.actions';
import { convertCurrency, modifyDate } from '../utils';
import { Store } from '@ngrx/store';
export interface State {
  f03: any;
}

export const initialState = {
  'policy_details': {
    'F03_05': '13888888',
    'F03_06': 'SLA Testing',
    'F03_07': 'SLA Testing',
    'F03_08': 'N/A',
    'F03_09': 'Wealth Amplifier Investment Plan',
    'F03_10': 'In Force',
    'F03_11': '05/02/2012',
    'F03_12': '05/02/2012',
    'F03_13': 'Whole of Life',
    'F03_14': 'Single',
    'F03_15': 'HKD'
  },
  'policy_roles': {
    'F03_18': 'SLA Testing',
    'F03_19': 'QB',
    'F03_20': 'N/A',
    'F03_21': 'SLA Testing',
    'F03_22': '07/07/1973',
    'F03_23': 'Male',
    'F03_24': 'QB',
    'F03_57': 'FakeName1',
    'F03_58': 'FakeName2',
  },
  'payment_information': {
    'F03_26': 'Single',
    'F03_27': 'Direct Billing',
    'F03_28': 'HKD',
    'F03_29': '30,000.00',
    'F03_30': 'N/A',
    'F03_31': 'N/A',
    'F03_32': 'N/A',
    'F03_33': 'N/A',
    'F03_34': 'N/A',
    'F03_35': 'N/A',
    'F03_36': '30,000.00',
    'F03_37': 'N/A',
    'F03_38': '0.00',
    'F03_39': '0.00'
  },
  'benefit_information': [
    {
      'F03_42': 'Wealth Amplifier Investment Plan',
      'F03_43': '05/02/2026',
      'F03_44': '05/20/2022',
      'F03_45': '33,303.82'
    }
  ],
  'consultant_information': {
    'F03_48': 'Good TR',
    'F03_49': 'SLA BROKER',
    'F03_50': '21690700',
    'F03_51': 'QB'
  },
  'income_fund_bank_account_information': {
    'F03_53': 'XXX XXX XXX',
    'F03_54': 'XXXX',
    'F03_55': '000000000',
    'F03_56': 'N',
    'F03_65': 'XXXX'
  },
  'beneficiary_info': {
    'F03_60': 'Good name',
    'F03_61': '100%',
    'F03_62': 'Friend',
    'F03_63': '2001-01-01'
  },
  policy_list: [],
  data: {},
  f03_page_content: {
    selected_policy: null,
  },
};


function convertAddress(...args) {
  let address = '';
  args.forEach(e => {
    if (e !== '') {
      if (address !== '') {
        address = address.concat('<br>');
      }
      address = address.concat(e);
    }
  });
  return address;
}

function convertBenefitInfo(benefit) {
  const benefitsArray = [];
  benefit.forEach(e => {
    benefitsArray.push({
      'F03_42': e.coverageName,
      'F03_43': e.coverageExpiryDate,
      'F03_44': e.premiumExpiryDate,
      'F03_45': convertCurrency(e.sumInsuredOrAmount, 2)
    });
  });
  return benefitsArray;
}

function convertBeneficiaryInfo(beneficiary) {
  const beneficiariesArray = [];
  beneficiary.forEach(e => {
    beneficiariesArray.push({
      'F03_60': e.beneficiaryName,
      'F03_61': e.beneficiaryPer,
      'F03_62': e.beneficiaryRelationship,
      'F03_63': e.beneficiaryStartDate
    });
  });
  return beneficiariesArray;
}

export function reducer(state = initialState, action: f03.Actions) {
  switch (action.type) {

    case f03.GO_TO_PAGE_F03: {
      // console.log('f03.GO_TO_PAGE_F03 action', action);
      const data = transformResponse(action.payload, action.currentPage);
      const selected_policy = state.f03_page_content.selected_policy ? state.f03_page_content.selected_policy : data['policy_no_list'][0];
      // const selected_policy = data['policy_no_list'][0];
      return Object.assign({}, state, {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        f03_page_content: {
          ...state.f03_page_content,
          ...data[selected_policy],
          selected_policy: selected_policy
        }
      });
    }

    case f03.CHANGE_POLICY: {
      const selected_policy = action.payload;
      // const selected_account = state.data[selected_policy]['account_list'] ? state.data[selected_policy]['account_list'][0] : {};
      return Object.assign({}, state, {
        ...state,
        f03_page_content: {
          ...state.f03_page_content,
          ...state.data[selected_policy],
          selected_policy: selected_policy,
        }
      });
    }

    case f03.RESET_F03_STATE: {
      return Object.assign({}, initialState);
    }


    default:
      return state;
  }
}



function transformResponse(res, currentPage) {
  try {
    const getMyPoliciesRes = res[0];
    const data = {};
    data['policy_no_list'] = [];
    getMyPoliciesRes['policy'].forEach(policy => {
      if (currentPage === 'f03') {
        if (policy.policyInfoView.isMyWealthFlag === 'Y') {
          data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
        }
      } else if (currentPage === 'j03') {
        if (policy.policyInfoView.isMyProtectionFlag === 'Y') {
          data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
        }
      }
    }
    );
    data['policy_no_list'].sort();
    data['policy_no_list'].forEach((policyNo) => {
      const getPolicy = getMyPoliciesRes.policy.find(p => p.policyInfoView.policyNo === policyNo);
      // console.log('getPolicy', getPolicy);
      data[policyNo] = { policy_details: {}, details_of_account: {} };
      data[policyNo]['policy_details'] = {
        F03_05: policyNo,
        F03_06: getPolicy['policyInfoView']['owner'],
        F03_07: getPolicy['policyInfoView']['insured'],
        F03_08: getPolicy['policyInfoView']['assignee'],
        F03_09: getPolicy['policyInfoView']['productName'],
        F03_10: getPolicy['policyInfoView']['policyStatus'],
        F03_11: getPolicy['policyInfoView']['policyInceptionDate'],
        F03_12: getPolicy['policyInfoView']['policyRiskAcceptanceDate'],
        F03_13: getPolicy['policyInfoView']['policyTerm'],
        F03_14: getPolicy['policyInfoView']['policyPremTerm'],
        F03_15: getPolicy['policyInfoView']['policyCurrency'],
        F03_IS_NEW_PAR_PRODUCT: getPolicy['policyInfoView']['isNewParProduct'],
        ProductCode: getPolicy['policyInfoView']['productCode'],
        LockinFlag: getPolicy['policyInfoView']['lockinFlag'],
        CiesPolicyFlag: getPolicy['policyInfoView']['ciesPolicyFlag'],
        CiesPolicy: getPolicy['policyInfoView']['ciesPolicy'],
        AppointAsFIFlag: getPolicy['policyInfoView']['appointAsFIFlag'],
        AppointAsFI: getPolicy['policyInfoView']['appointAsFI']

      };
      const policyRoleIndexForPolicyOwner = getPolicy['policyRoleInfoView'].findIndex(e => {
        return getPolicy['policyInfoView']['ownerNo'] === e['clientNo'] && e['policyRoleType'] === 'PO';
      });
      const policyRoleIndexForLifeInsured = getPolicy['policyRoleInfoView'].findIndex(e => {
        return getPolicy['policyInfoView']['insuredCode'] === e['clientNo'] && e['policyRoleType'] === 'LI';
      });
      data[policyNo]['policy_roles'] = {
        F03_18: getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['clientName'],
        F03_19: convertAddress(getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['address1'], getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['address2'], getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['address3'], getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['address4'], getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['address5']),
        F03_20: getPolicy['policyInfoView']['assignee'],
        F03_21: getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['clientName'],
        F03_22: getPolicy['policyInfoView']['dob'],
        F03_23: getPolicy['policyInfoView']['sex'],
        F03_24: convertAddress(getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['address1'], getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['address2'], getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['address3'], getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['address4'], getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['address5']),
        F03_57: getPolicy['policyRoleInfoView'][policyRoleIndexForPolicyOwner]['contingentPolicyOwnerName'],
        F03_58: getPolicy['policyRoleInfoView'][policyRoleIndexForLifeInsured]['contingentLifeInsuredName']



      };
      const policyRoleIndexForJointed = getPolicy['policyRoleInfoView'].findIndex(e => e['policyRoleType'] === 'PO' && e['sequenceNo'] === '01');
      if (policyRoleIndexForJointed !== -1) {
        data[policyNo]['policy_roles']['F03_18a'] = getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['clientName'];
        data[policyNo]['policy_roles']['F03_19a'] = convertAddress(getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['address1'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['address2'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['address3'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['address4'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointed]['address5']);
      }
      // Fixed workitem 78
      const policyRoleIndexForJointLifeInsured = getPolicy['policyRoleInfoView'].findIndex(e => {
        return getPolicy['policyInfoView']['jointInsuredCode'] === e['clientNo'] && e['policyRoleType'] === 'LI';
      });
      if (policyRoleIndexForJointLifeInsured !== -1) {
        data[policyNo]['policy_roles']['F03_21a'] = getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['clientName'];
        data[policyNo]['policy_roles']['F03_22a'] = modifyDate(getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['dob']);
        data[policyNo]['policy_roles']['F03_23a'] = getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['sex'];
        data[policyNo]['policy_roles']['F03_24a'] = convertAddress(getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['address1'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['address2'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['address3'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['address4'], getPolicy['policyRoleInfoView'][policyRoleIndexForJointLifeInsured]['address5']);
      }

      data[policyNo]['payment_information'] = {
        F03_26: getPolicy['policyPaymentInfoView']['policyPaymentMode'],
        F03_27: getPolicy['policyPaymentInfoView']['paymentMethod'],
        F03_28: getPolicy['policyPaymentInfoView']['policyCurrency'],
        F03_29: convertCurrency(getPolicy['policyInfoView']['regPremiumAmount'], 2),
        F03_29_LABEL: getPolicy['policyInfoView']['policyPremTermLabel'],
        F03_30: getPolicy['policyPaymentInfoView']['bankAccountName'],
        F03_31: getPolicy['policyPaymentInfoView']['bankAccountNo'],
        F03_32: getPolicy['policyPaymentInfoView']['bankAccountStatus'],
        F03_33: getPolicy['policyPaymentInfoView']['billingDate'],
        F03_34: getPolicy['policyPaymentInfoView']['ppsNo'],
        F03_35: getPolicy['policyPaymentInfoView']['premiumDueDate'],
        F03_36: convertCurrency(getPolicy['policyInfoView']['totalPremiumReceivedAmount'], 2),
        F03_37: getPolicy['policyPaymentInfoView']['nextExtractionDate'],
        F03_38: convertCurrency(getPolicy['policyPaymentInfoView']['totalOutstandingPremiumAmount'], 2),
        F03_39: convertCurrency(getPolicy['policyPaymentInfoView']['totalPremiumSuspenseAmount'], 2)
      };

      if (getPolicy['policyPaymentInfoView']['isCreditCard'] === 'Y' && getPolicy['policyPaymentInfoView']['expiryDate'] !== null && getPolicy['policyPaymentInfoView']['expiryDate'] !== '') {
        data[policyNo]['payment_information']['F03_31'] = getPolicy['policyPaymentInfoView']['bankAccountNo'] + ' (' + getPolicy['policyPaymentInfoView']['expiryDate'] + ')';
      }

      data[policyNo]['benefit_information'] = convertBenefitInfo(getPolicy['policyBenefitInfoView']);
      data[policyNo]['consultant_information'] = {
        F03_48: getPolicy['policyConsultantInfoView']['consultantName'],
        F03_49: getPolicy['policyConsultantInfoView']['brokerName'],
        F03_50: getPolicy['policyConsultantInfoView']['officePhoneNo'],
        F03_51: getPolicy['policyConsultantInfoView']['contactAddress']
      };

      if (getPolicy['policyIncomeFundBankAccountView']) {
        const bank_account = getPolicy['policyIncomeFundBankAccountView']['bankAccountNo'];
        const bancBranchNo = getPolicy['policyIncomeFundBankAccountView']['incomeFundPaymentMethod'] === 'AUTOPAY' ? bank_account.slice(0, bank_account.length - 4).replace(/\d/g, 'x') + bank_account.slice(bank_account.length - 4) : '-';

        if(getPolicy['policyIncomeFundBankAccountView']['incomeFundPaymentMethod'] === 'AUTOPAY'){
          data[policyNo]['income_fund_bank_account_information'] = {
            F03_53: getPolicy['policyIncomeFundBankAccountView']['bankAccountName'],
            F03_54: getPolicy['policyIncomeFundBankAccountView']['bankName'],
            F03_55: bancBranchNo,      
            F03_65: getPolicy['policyIncomeFundBankAccountView']['incomeFundPaymentMethod']
          };
        } else {
          //Retained
          data[policyNo]['income_fund_bank_account_information'] = {
            F03_53: '-',
            F03_54: '-',
            F03_55: bancBranchNo,      
            F03_65: getPolicy['policyIncomeFundBankAccountView']['incomeFundPaymentMethod']
          };
        }

      } else {
        data[policyNo]['income_fund_bank_account_information'] = {
          F03_53: '-',
          F03_54: '-',
          F03_55: '-',
          F03_65: '-'

        };
      }

      data[policyNo]['beneficiary_info'] = convertBeneficiaryInfo(getPolicy['policyBeneficiaryInfoView']);

    });
    return data;
  } catch (err) {
    console.log('f03 transform failed', err);
  }
}
