import * as ia02e from '../actions/ia02e.actions';

export interface State {
  ia02e: any;
}

export const initialState = {
    'eRPQ_section5': {
      'IA02e_02': '',
      'IA02e_05': '',
      'IA02e_11': '',
      'IA02e_17': '',
      'IA02e_25': ''
    }
};

export function reducer(state = initialState, action: ia02e.Actions) {
  switch (action.type) {
    case ia02e.GO_TO_PAGE_IA02E: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'eRPQ_section5': {
            'IA02e_02': payload.agreement,
            'IA02e_05': payload.netWorth,
            'IA02e_11': payload.reserve,
            'IA02e_17': payload.futureInvestments,
            'IA02e_25': payload.all_policies
          }
      });
    }

    default:
      return state;
  }
}
