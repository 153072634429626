import { ActionReducer } from '@ngrx/store';

/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
import { combineReducers } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromSetting from './setting.reducer';
import * as fromNavigation from './navigation.reducer';
import * as fromPage from './page.reducer';
import * as fromUser from './user.reducer';
import * as fromDevice from './device.reducer';
export interface State {
    setting: fromSetting.State;
    navigation: fromNavigation.State;
    page: fromPage.State;
    user: fromUser.State;
    device: fromDevice.State;
}

const initialState = {
    setting: fromSetting.initialState,
    navigation: fromNavigation.initialState,
    page: fromPage.initialState,
    user: fromUser.initialState,
    device: fromDevice.initialState
};

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers = {
    setting: fromSetting.reducer,
    navigation: fromNavigation.reducer,
    page: fromPage.reducer,
    user: fromUser.reducer,
    device: fromDevice.reducer
};

export function resetAllState(rdc) {
    return function(state, action) {
        if (action.type === '[Navigation Component] ResetAllState') {
            state = undefined;
        }
        return rdc(state, action);
    };
}

const rootReducer = combineReducers(reducers);
export function reducer(state: any, action: any) { // AOT
    return rootReducer(state, action);
}
