import * as f02 from '../actions/f02.actions';
import * as moment from 'moment';
import { convertCurrency } from '../utils';
import { zeroToNA } from '../utils';

export interface State {
  f02: any;
}

export const initialState = {
  policy_list: [],
  data: {},
  f02_page_content: {
    selected_policy: null,
    tableData: {
      tableData: [],
      tableSummary: []
    },
    chartData: [],
    chartTableData: {
      tableData: [],
      tableSummary: []
    },
    cashTableData: {
      tableData: [],
      tableSummary: []
    },
    aspireData: []
  },
};

export function reducer(state = initialState, action: f02.Actions) {
  switch (action.type) {
    case f02.GO_TO_PAGE_F02: {
      console.log('f02.GO_TO_PAGE_F02 action', action);
      const data = transformResponse(action.payload, action.currentPage);
      // const selected_policy = data['policy_no_list'][0];
      const selected_policy = state.f02_page_content.selected_policy ? state.f02_page_content.selected_policy : data['policy_no_list'][0];
      // Fix work item 350 for account with no link products
      console.log(selected_policy);
      // if (selected_policy) {
      if (data[selected_policy]) {
        const selected_account = data[selected_policy]['account_list'] ? data[selected_policy]['account_list'][0] : null;
        // const selected_account = data[data['policy_no_list'][0]]['account_list'] ? data[data['policy_no_list'][0]]['account_list'][0] : null;
        // const selected_account = data.data[selected_policy]['account_list'] ? state.data[selected_policy]['account_list'][0] : {};
        console.log('f02 selected_account', data[selected_policy]['account_list'][0]);
        return Object.assign({}, state, {
          ...state,
          data: {
            ...state.data,
            ...data
          },
          f02_page_content: {
            ...state.f02_page_content,
            ...data[selected_policy],
            selected_policy: selected_policy,
            selected_account: selected_account
          }
        });
      }
      break;
    }

    case f02.CHANGE_POLICY: {
      const selected_policy = action.payload ? action.payload : state.data['policy_no_list'][0];
      // const selected_account = state.data[selected_policy]['account_list'] ? state.data[selected_policy]['account_list'][0] : {};
      const selected_account = state.data[selected_policy] && state.data[selected_policy]['account_list']
        ? state.data[selected_policy]['account_list'][0]
        : {};
      return Object.assign({}, state, {
        ...state,
        f02_page_content: {
          ...state.f02_page_content,
          ...state.data[selected_policy],
          selected_policy: selected_policy,
          selected_account: selected_account
        }
      });
    }

    case f02.CHANGE_ACCOUNT: {
      const account = state.f02_page_content['account_list'].find(acc => acc.policyAcctName === action.payload);
      return Object.assign({}, state, {
        ...state,
        f02_page_content: {
          ...state.f02_page_content,
          selected_account: account
        }
      });
    }

    case f02.SET_F02_CHART_AND_TABLE_DATA: {
      console.log('SET_F02_CHART_AND_TABLE_DATA', action.payload);
      const tableData = transformTableData(action.payload.tableData, state.f02_page_content['payment_information']['F02_24']);
      const chartData = transformChartData(action.payload.newChartData);
      const chartTableData = transformChartTableData(action.payload.chartData, state.f02_page_content['payment_information']['F02_24']);
      return Object.assign({}, state, {
        ...state,
        f02_page_content: {
          ...state.f02_page_content,
          tableData: tableData,
          chartData: chartData,
          chartTableData: chartTableData

        }
      });
    }

    case f02.SET_F02_CASH_TABLE_DATA: {
      console.log('f02.SET_F02_CASH_TABLE_DATA action', action);
      const cashTableData = transformChartTableData(action.payload.chartData, state.f02_page_content['payment_information']['F02_24']);
      return Object.assign({}, state, {
        ...state,
        f02_page_content: {
          ...state.f02_page_content,
          cashTableData: cashTableData
        }
      });
    }

    case f02.SET_F02_ASPIRE_INFORMATION: {
      console.log('f02.SET_F02_ASPIRE_INFORMATION action', action);
      const aspireDataList = action.payload[0]['policyExtraAllocationList'];
      const aspireData = [];
      aspireDataList.forEach(data => {
        aspireData.push({
          title: data.title,
          F02_70: data.effectiveDate,
          F02_71: convertCurrency(data.payAmount),
          F02_72: data.initialCharge.toString() + '%',
          F02_73: convertCurrency(data.initialChargeAmount),
          F02_74: data.extraAllocation.toString() + '%',
          F02_75: convertCurrency(data.extraAllocationAmount),
          F02_76: convertCurrency(data.investedAmount)
        });
      });
      return Object.assign({}, state, {
        ...state,
        f02_page_content: {
          ...state.f02_page_content,
          aspireData: aspireData
        }
      });
    }

    case f02.RESET_F02_STATE: {
      return Object.assign({}, initialState);
    }

    default:
      return state;
  }
}


function transformResponse(res, currentPage) {
  try {
    const getMyPoliciesRes = res[0];
    const data = {};
    data['policy_no_list'] = [];
    getMyPoliciesRes['policy'].forEach(policy => {
      if (currentPage === 'f02' || currentPage === 'f03' || currentPage === 'f04') {
        if (policy.policyInfoView.isMyWealthFlag === 'Y') {
          data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
        }

      } else if (currentPage === 'j02' || currentPage === 'j03' || currentPage === 'j04') {
        if (policy.policyInfoView.isMyProtectionFlag === 'Y') {
          data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
        }
      }
    }

    );
    data['policy_no_list'].sort();
    data['policy_no_list'].forEach((policyNo) => {
      const getPolicy = getMyPoliciesRes.policy.find(p => p.policyInfoView.policyNo === policyNo);
      // console.log('getPolicy', getPolicy);
      data[policyNo] = { policy_details: {}, details_of_account: {} };
      data[policyNo]['policy_details'] = {
        F02_05: policyNo,
        F02_06: getPolicy['policyInfoView']['owner'],
        F02_07: getPolicy['policyInfoView']['insured'],
        F02_08: getPolicy['policyInfoView']['assignee'],
        F02_09: getPolicy['policyInfoView']['productName'],
        F02_10: getPolicy['policyInfoView']['policyStatus'],
        F02_11: getPolicy['policyInfoView']['policyInceptionDate'],
        F02_12: getPolicy['policyInfoView']['policyRiskAcceptanceDate'],
        F02_13: getPolicy['policyInfoView']['policyTerm'],
        F02_14: getPolicy['policyInfoView']['policyPremTerm'],
        F02_15: getPolicy['policyInfoView']['policyCurrency'],
        F02_IS_PROTECTION: getPolicy['policyInfoView']['isMyProtectionFlag'],
        F02_IS_TERM_PRODUCT: getPolicy['policyInfoView']['isTermProductFlag'],
        F02_IS_NEW_PAR_PRODUCT: getPolicy['policyInfoView']['isNewParProduct'],
        F02_IS_ASPIRE_OR_ASPIRATION: getPolicy['policyInfoView']['isAspireOrAspirationFlag'],
        policyValueInHKD: convertCurrency(getPolicy['policyInfoView']['policyValueInHKD'], 2),
        PHSentence: getPolicy['policyInfoView']['premiumHolidaySentence'],
        CanSWFlag: getPolicy['policyInfoView']['canSwitchingFlag'],
        CanFPAFlag: getPolicy['policyInfoView']['canRedirectionFlag'],
        // Bug 570 - Start
        ShowLoyaltyBonusFlag: getPolicy['policyInfoView']['showLoyaltyBonusFlag'],
        LoyaltyBonusSentence: getPolicy['policyInfoView']['loyaltyBonusSentence'],
        // Bug 570 - End
        // Bug 450 - Start
        ShowPHSentenceFlag: getPolicy['policyInfoView']['showPremiumHolidaySentenceFlag'],
        // Bug 450 - End
        ProductCode: getPolicy['policyInfoView']['productCode'],
        LockinFlag: getPolicy['policyInfoView']['lockinFlag'],
        CiesPolicyFlag: getPolicy['policyInfoView']['ciesPolicyFlag'],
        CiesPolicy: getPolicy['policyInfoView']['ciesPolicy'],
        AppointAsFIFlag: getPolicy['policyInfoView']['appointAsFIFlag'],
        AppointAsFI: getPolicy['policyInfoView']['appointAsFI'],
        CanEnterInPlus: getPolicy['policyInfoView']['canEnterInPlus'],
        LoginDateTime: getMyPoliciesRes.returnStatus.loginDateTime
      };

      data[policyNo]['risk_profile'] = {
        F02_78: getPolicy['policyInfoView']['riskToleranceLevel'] || '-',
        F02_79: getPolicy['policyInfoView']['isEligibleForDFund'] || '-',
        F02_80: getPolicy['policyInfoView']['isEligibleForIncomeFund'] || '-',
        F02_81: getPolicy['policyInfoView']['riskProfileLastUpdateDateInStrn'] || '-',
        F02_82: getPolicy['policyInfoView']['fnaLastUpdateDateInStrn'] || '-',
        F02_83: getPolicy['policyInfoView']['remarks'] || '-'
      };
      data[policyNo]['policy_value_summary'] = {
        F02_18: convertCurrency(getPolicy['policyInfoView']['totalPremiumReceivedAmount']),
        F02_19: convertCurrency(getPolicy['policyInfoView']['totalWithdrawalAmount'], 2),
        F02_20: convertCurrency(getPolicy['policyInfoView']['policyValueInPolicyCurrency']) + getPolicy['policyInfoView']['policyValueSymbol'],
        // CR1391
        F02_90: convertCurrency(getPolicy['policyInfoView']['surrenderValueInPolicyCurrency'], 2) + getPolicy['policyInfoView']['policyValueSymbol'],

        F02_92: convertCurrency(getPolicy['policyInfoView']['guaranteedCashValueAmnt']),
        F02_93: convertCurrency(getPolicy['policyInfoView']['nonGuaranteedReversionaryBonusAmnt']),
        F02_94: convertCurrency(getPolicy['policyInfoView']['nonGuaranteedTerminalBonusAmnt']),
        F02_95: convertCurrency(getPolicy['policyInfoView']['outstandingLoanCapitalAmnt']),
        F02_96: convertCurrency(getPolicy['policyInfoView']['loanInterestAmnt']),
        F02_97: convertCurrency(getPolicy['policyInfoView']['loanAnnualInterestRate']),
        F02_98: convertCurrency(getPolicy['policyInfoView']['readyMoneyAmnt']),
        F02_99: convertCurrency(getPolicy['policyInfoView']['totalLoanBalanceAmnt']),
        F02_100: convertCurrency(getPolicy['policyInfoView']['availableLoanAmnt']),
        F02_101: convertCurrency(getPolicy['policyInfoView']['pdaNetAmount']),
        F02_102: convertCurrency(getPolicy['policyInfoView']['pdaInterestAmnt']),
        F02_103: zeroToNA(convertCurrency(getPolicy['policyInfoView']['pdaAnnualInterestRate'])),
        F02_104: convertCurrency(getPolicy['policyInfoView']['pdaTotalBalanceAmnt']),
        F02_105: convertCurrency(getPolicy['policyInfoView']['totalPolicyValueAmnt']),
        F02_116: convertCurrency(getPolicy['policyInfoView']['autoPremiumLoan']),
        F02_117: convertCurrency(getPolicy['policyInfoView']['autoPremiumLoanInterest']),
        F02_118: convertCurrency(getPolicy['policyInfoView']['fpdNetAmnt']),
        F02_120: convertCurrency(getPolicy['policyInfoView']['coiAmnt']),
        F02_122: convertCurrency(getPolicy['policyInfoView']['coiAmntLoading']),
        F02_123: convertCurrency(getPolicy['policyInfoView']['coiAmntTotal'])
      };
      data[policyNo]['payment_information'] = {
        F02_22: getPolicy['policyPaymentInfoView']['policyPaymentMode'],
        F02_23: getPolicy['policyPaymentInfoView']['paymentMethod'],
        F02_24: getPolicy['policyPaymentInfoView']['policyCurrency'],
        F02_25: convertCurrency(getPolicy['policyInfoView']['regPremiumAmount']),
        F02_25_LABEL: getPolicy['policyInfoView']['policyPremTermLabel'],
        F02_26: getPolicy['policyPaymentInfoView']['bankAccountName'],
        F02_27: getPolicy['policyPaymentInfoView']['bankAccountNo'],
        F02_28: getPolicy['policyPaymentInfoView']['bankAccountStatus'],
        F02_29: getPolicy['policyPaymentInfoView']['billingDate'],
        F02_30: getPolicy['policyPaymentInfoView']['ppsNo'],
        F02_31: getPolicy['policyPaymentInfoView']['premiumDueDate'],
        F02_32: convertCurrency(getPolicy['policyInfoView']['totalPremiumReceivedAmount']),
        F02_33: getPolicy['policyPaymentInfoView']['nextExtractionDate'],
        F02_34: convertCurrency(getPolicy['policyPaymentInfoView']['totalOutstandingPremiumAmount']),
        F02_35: convertCurrency(getPolicy['policyPaymentInfoView']['totalPremiumSuspenseAmount'])
      };

      if (getPolicy['policyPaymentInfoView']['isCreditCard'] === 'Y' && getPolicy['policyPaymentInfoView']['expiryDate'] !== null && getPolicy['policyPaymentInfoView']['expiryDate'] !== '') {
        data[policyNo]['payment_information']['F02_27'] = getPolicy['policyPaymentInfoView']['bankAccountNo'] + ' (' + getPolicy['policyPaymentInfoView']['expiryDate'] + ')';
      }

      if (getPolicy['policyAdviserInfoView'] === null) {
        data[policyNo]['advisory_fee_information'] = {
          F02_65: false,
          F02_66: '',
          F02_67_1: '',
          F02_67: ''
        };
      } else {
        if (getPolicy['policyAdviserInfoView']['adviserServiceEndDate'] !== '') {
          if (getPolicy['policyAdviserInfoView']['adviserFeePercentage'] === null) {
            data[policyNo]['advisory_fee_information'] = {
              F02_65: true,
              F02_66: getPolicy['policyAdviserInfoView']['adviserServiceStartDate'],
              F02_67_1: getPolicy['policyAdviserInfoView']['adviserName'],
              F02_67: ''
            };
          } else {
            data[policyNo]['advisory_fee_information'] = {
              F02_65: true,
              F02_66: getPolicy['policyAdviserInfoView']['adviserServiceStartDate'],
              F02_67_1: getPolicy['policyAdviserInfoView']['adviserName'],
              F02_67: getPolicy['policyAdviserInfoView']['adviserFeePercentage'] + '%'
            };
          }
          console.log(data[policyNo]['advisory_fee_information']);
        } else {
          data[policyNo]['advisory_fee_information'] = {
            F02_65: false,
            F02_66: '',
            F02_67_1: '',
            F02_67: ''
          };
        }
      }
      data[policyNo]['account_list'] = getPolicy['policyAccountView'];
    });
    return data;
  } catch (err) {
    console.log('f02 transform failed', err);

  }
}


function transformTableData(tableData, currency) {
  if (!tableData.policyCovrFundAllocationList || tableData.policyCovrFundAllocationList.length === 0) {
    return [];
  }
  const transformedTableData = {
    tableData: [],
    tableSummary: []
  };
  transformedTableData['tableData'] = tableData.policyCovrFundAllocationList.sort(function (a, b) {
    return a.fundAllocation < b.fundAllocation ? 1 : -1;
  }).map(row => {
    return {
      F02_39: row['fundName'],
      F02_48: row['fundCode'],
      F02_40: `${row['fundAllocation'].toFixed(2)}%`,
      F02_41: convertCurrency(row['fundAllocationAmount'], 2)
    };
  });
  transformedTableData['tableSummary'] = [{
    F02_54: convertCurrency(transformedTableData['tableData'].reduce((acc, val) => {
      // Fixed workitem 463
      return acc + Number(val.F02_41.replace(/,/g, ''));
    }, 0), 2)
  },
  {
    F02_24: currency,
  }];
  return transformedTableData;
}

function transformChartData(chartData) {
  if (!chartData.policyCovrUnitBalanceList || chartData.policyCovrUnitBalanceList.length === 0) {
    return [];
  }
  const transformedChartData = chartData.policyCovrUnitBalanceList.map(pie => {
    return {
      F02_39: pie['fundName'] + pie['fundSymbol'],
      F02_48: pie['fundCode'],
      F02_40: `${pie['weight']}`,
      F02_41: convertCurrency(pie['amountInPolicyCurr'], 2),
    };
  });
  return transformedChartData;
}

function transformChartTableData(chartData, currency) {
  if (!chartData.policyCovrUnitBalanceList || chartData.policyCovrUnitBalanceList.length === 0) {
    return [];
  }
  const transformedChartTableData = {
    tableData: [],
    tableSummary: []
  };
  transformedChartTableData['tableData'] = chartData.policyCovrUnitBalanceList.map(e => {
    return {
      F02_47: e['fundName'] + e['fundSymbol'],
      F02_48: e['fundCode'],
      F02_49: e['fundCurrency'],
      F02_50: e['unitBalance'].toFixed(5),
      F02_51: e['unitPriceDate'],
      F02_52: convertCurrency(e['unitPrice'], 3),
      F02_53: e['weight'] + '%',
      F02_55: convertCurrency(e['amountInFundCurr'], 2),
      F02_56: convertCurrency(e['amountInPolicyCurr'], 2)
    };
  });
  let acc = 0;
  transformedChartTableData['tableData'].forEach(val => {
    // console.log('val.F02_56', parseFloat(val.F02_56))
    acc = acc + parseFloat(val.F02_56.replace(/,/g, ''));
  });
  transformedChartTableData['tableSummary'] = [{
    // Fixed workitem 463
    F02_54: convertCurrency(acc, 2)
    // F02_54: (Math.round(acc * 100) / 100).toFixed(2)
    // F02_58: chartData.policyCovrUnitBalanceList[0].fundCurrencyy
  },
  {
    F02_24: currency,
  }];
  return transformedChartTableData;
}
