import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonicModule, Platform, } from '@ionic/angular';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})

export class PieChartComponent implements OnInit {

  // DEFAULT_SLICE_VISIBILITY_THRESHOLD = 0.01;
  DEFAULT_SLICE_VISIBILITY_THRESHOLD = 0.00;
  DEFAULT_COMBINE_SLICE_THRESHOLD = 20;
  pieChartData: any;
  // tslint:disable-next-line:no-input-rename
  @Input('chartData') chartData: any;

  constructor(public platform: Platform) {
  }

  ngOnInit() {
    this.loadPieChartData();
    window.addEventListener('resize', () => {
      this.loadPieChartData();
    });
  }

  loadPieChartData() {
    const width = this.platform.width();
    this.pieChartData = {
      chartType: 'PieChart',
      dataTable: this.combineSlices(),
      options: {
        height: 293,
        width: width > 1000 ? width * 0.65 : width * 0.9,
        // sliceVisibilityThreshold: this.chartData.sliceVisibilityThreshold || this.DEFAULT_SLICE_VISIBILITY_THRESHOLD,
        sliceVisibilityThreshold: this.DEFAULT_SLICE_VISIBILITY_THRESHOLD,
        is3D: true,
        legend: {
          position: 'right',
          maxLines: 2,
        },
        chartArea: {
          height: '95%',
          width: '100%',
          left: '1%',
        }
      }
    };
  }

  combineSlices() {
    const chartMetaData = this.chartData.data[0];
    const threshold = this.chartData.combineSliceThreshold || this.DEFAULT_COMBINE_SLICE_THRESHOLD;
    let data = this.chartData.data.slice(1, this.chartData.data.length);
    if (data.length > threshold) {
      data = data.sort((a, b) => b[1] - a[1]);
      const lastElements = data.slice(threshold - 1, data.length);
      const combinedValue = lastElements.map(e => e[1]).reduce((acc, val) => acc + val);
      const newData = data.slice(0, threshold - 1);
      newData.push(['Others', combinedValue]);
      return [chartMetaData].concat(newData);
    }
    return this.chartData.data;
  }
}
