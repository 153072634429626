import { Action } from '@ngrx/store';

// G01
export const SET_SELECTED_SWITCH_OUT_INVESTMENT_INDEX = '[g01 Component] SetSelectedSwitchOutInvestmentIndex';
export const UPDATE_SWITCH_OUT_INVESTMENT = '[g01 Component] UpdateSwitchOutInvestment';
export const SET_SELECTED_SWITCH_IN_INVESTMENT_INDEX = '[g01 Component] SetSelectedSwitchInInvestmentIndex';
export const ADD_SWITCH_IN_INVESTMENT = '[g01 Component] AddSwitchInInvestment';
export const DELETE_SWITCH_IN_INVESTMENT = '[g01 Component] DeleteSwitchInInvestment';
export const UPDATE_SWITCH_IN_INVESTMENT = '[g01 Component] UpdateSwitchInInvestment';
export const CHANGE_POLICY = '[g01 Component] ChangePolicy';
export const CHANGE_ACCOUNT = '[g01 Component] ChangeAccount';
export const SET_SWITCH_IN_CHART_AND_TABLE_DATA = '[g01 Component] SetSwitchInChartAndTableData';
export const SET_SWITCH_IN_FUND_MANAGER_LIST = '[g01 Component] SetSwitchInFundManagerList';
export const SET_SWITCH_IN_FUND_LIST = '[g01 Component] SetSwitchInFundList';
export const SET_G01_PENDING_TRANSACTION = '[g01 Component] SetG01PendingTransaction';
export const SET_SWITCH_IN_TRANSACTION_NO = '[g01 Component] SetSwitchInTransactionNo';
export const SWITCH_OUT_ALL = '[g01 Component] SwitchOutAll';
export const GO_TO_PAGE_G01 = '[Navigation Component] GoToPageG01';
export const SET_SELECTED_POLICY_NO = '[Navigation Component] InitPolicy';

export const RESET_G01_STATE = '[g01 Component] ResetG01State';

// G01
export class SetSelectedSwitchOutInvestmentIndex implements Action {
    readonly type = SET_SELECTED_SWITCH_OUT_INVESTMENT_INDEX;
    constructor(public payload: Number) { }
}

export class SetSelectedSwitchInInvestmentIndex implements Action {
    readonly type = SET_SELECTED_SWITCH_IN_INVESTMENT_INDEX;
    constructor(public payload: Number) { }
}

export class UpdateSwitchOutInvestment implements Action {
    readonly type = UPDATE_SWITCH_OUT_INVESTMENT;
    constructor(public payload: any) { }
}

export class AddSwitchInInvestment implements Action {
    readonly type = ADD_SWITCH_IN_INVESTMENT;
    constructor(public payload: any) { }
}

export class DeleteSwitchInInvestment implements Action {
    readonly type = DELETE_SWITCH_IN_INVESTMENT;
    constructor(public payload: any) { }
}

export class UpdateSwitchInInvestment implements Action {
    readonly type = UPDATE_SWITCH_IN_INVESTMENT;
    constructor(public payload: any) { }
}

export class ChangePolicy implements Action {
    readonly type = CHANGE_POLICY;
    constructor(public payload: any) { }
}

export class ChangeAccount implements Action {
    readonly type = CHANGE_ACCOUNT;
    constructor(public payload: any) { }
}

export class SetSwitchInChartAndTableData implements Action {
    readonly type = SET_SWITCH_IN_CHART_AND_TABLE_DATA;
    constructor(public payload: any) { }
}

export class SetSwitchInFundManagerList implements Action {
    readonly type = SET_SWITCH_IN_FUND_MANAGER_LIST;
    constructor(public payload: any) { }
}

export class SetSwitchInFundList implements Action {
    readonly type = SET_SWITCH_IN_FUND_LIST;
    constructor(public payload: any) { }
}

export class SetG01PendingTransaction implements Action {
    readonly type = SET_G01_PENDING_TRANSACTION;
    constructor(public payload: any) { }
}

export class SetSwitchInTransactionNo implements Action {
    readonly type = SET_SWITCH_IN_TRANSACTION_NO;
    constructor(public payload: any) { }
}

export class SwitchOutAll implements Action {
    readonly type = SWITCH_OUT_ALL;
    constructor(public payload: any) { }
}

export class GoToPageG01 implements Action {
    readonly type = GO_TO_PAGE_G01;
    constructor(public payload: any) { }
}

export class SetSelectedPolicyNo implements Action {
    readonly type = SET_SELECTED_POLICY_NO;
    constructor(public payload: any) { }
}

export class ResetG01State implements Action {
    readonly type = RESET_G01_STATE;
    constructor() { }
}


export type Actions
    =
    // G01
    SetSelectedSwitchOutInvestmentIndex |
    SetSelectedSwitchInInvestmentIndex |
    UpdateSwitchOutInvestment |
    AddSwitchInInvestment |
    DeleteSwitchInInvestment |
    UpdateSwitchInInvestment |
    ChangeAccount |
    ChangePolicy |
    SetSwitchInChartAndTableData |
    SetSwitchInFundList |
    SetSwitchInFundManagerList |
    SetG01PendingTransaction |
    SetSwitchInTransactionNo |
    SwitchOutAll |
    GoToPageG01 |
    SetSelectedPolicyNo |
    ResetG01State;
