import * as ia02b from '../actions/ia02b.actions';

export interface State {
  ia02b: any;
}

export const initialState = {
    'eRPQ_section2': {
      'IA02b_02': ''
    }
};

export function reducer(state = initialState, action: ia02b.Actions) {
  switch (action.type) {
    case ia02b.GO_TO_PAGE_IA02B: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'eRPQ_section2': {
            'IA02b_02': payload.investmentTenor
          }
      });
    }

    default:
      return state;
  }
}
