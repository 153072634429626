import * as device from '../actions/device.actions';

export interface State {
  device: any;
}

export const initialState = {
  deviceType: 'desktop',
  ipAddress: '123.456.789.74',
  systemType: 'web'
};

export function reducer(state = initialState, action: device.Actions) {
  switch (action.type) {
    case device.SET_DEVICE: {
      const devicePayload: any = action.payload;
      return Object.assign({}, state, {
        ...state,
        ...devicePayload
      });
    }

    default:
      return state;
  }
}
