import { Action } from '@ngrx/store';

// B01
export const STORE_B01_INFO = '[b01 Component] StoreB01Info';
export const STORE_B02_INFO = '[b01 Component] StoreB02Info';
export const STORE_B02A_INFO = '[b01 Component] StoreB02AInfo';
export const STORE_B02B_INFO = '[b01 Component] StoreB02BInfo';
// export const STORE_B02A_INFO = '[b01 Component] StoreB02AInfo';

// B01
export class StoreB01Info implements Action {
    readonly type = STORE_B01_INFO;
    constructor(public payload: any) { }
}

export class StoreB02Info implements Action {
    readonly type = STORE_B02_INFO;
    constructor(public payload: any) { }
}

export class StoreB02AInfo implements Action {
    readonly type = STORE_B02A_INFO;
    constructor(public payload: any) { }
}

export class StoreB02BInfo implements Action {
    readonly type = STORE_B02B_INFO;
    constructor(public payload: any) { }
}




export type Actions
  =
  // B01
  StoreB01Info |
  StoreB02Info |
  StoreB02AInfo |
  StoreB02BInfo;
