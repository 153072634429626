import * as page from '../actions/j03.actions';

export interface State {
  j03: any;
}

export const initialState = [{
    'policy_details': {
        'J03_05': '13888888',
        'J03_06': 'SLA Testing',
        'J03_07': 'SLA Testing',
        'J03_08': 'N/A',
        'J03_09': 'Wealth Amplifier Investment Plan',
        'J03_10': 'In Force',
        'J03_11': '05/02/2012',
        'J03_12': '05/02/2012',
        'J03_13': 'Whole of Life',
        'J03_14': 'Single',
        'J03_15': 'HKD'
    },
    'policy_roles': {
        'J03_18': 'SLA Testing',
        'J03_19': 'QB',
        'J03_20': 'N/A',
        'J03_21': 'SLA Testing',
        'J03_22': '07/07/1973',
        'J03_23': 'Male',
        'J03_24': 'QB'
    },
    'payment_information': {
        'J03_26': 'Single',
        'J03_27': 'Direct Billing',
        'J03_28': 'HKD',
        'J03_29': '30,000.00',
        'J03_30': 'N/A',
        'J03_31': 'N/A',
        'J03_32': 'N/A',
        'J03_33': 'N/A',
        'J03_34': 'N/A',
        'J03_35': 'N/A',
        'J03_36': '30,000.00',
        'J03_37': 'N/A',
        'J03_38': '0.00',
        'J03_39': '0.00'
    },
    'benefit_information': [
        {
            'J03_42': 'Wealth Amplifier Investment Plan',
            'J03_43': '05/02/2026',
            'J03_44': '05/20/2022',
            'J03_45': '33,303.82'
        }
    ],
    'consultant_information': {
        'J03_48': 'Good TR',
        'J03_49': 'SLA BROKER',
        'J03_50': '21690700',
        'J03_51': 'QB'
    }
}];

function convertAddress(...args) {
  let address = '';
  args.forEach(e => {
    if (e !== '') {
      if (address !== '') {
        address = address.concat('<br>');
      }
      address = address.concat(e);
    }
  });
  return address;
}

function convertBenefitInfo(benefit) {
  const benefitsArray = [];
  benefit.forEach(e => {
    benefitsArray.push({
      'J03_42': e.coverageNameEng,
      'J03_43': e.coverageExpiryDate,
      'J03_44': e.premiumExpiryDate,
      'J03_45': e.sumInsuredOrAmount
    });
  });
  return benefitsArray;
}

export function reducer(state = initialState, action: page.Actions) {
  switch (action.type) {
    case page.GO_TO_PAGE_J03: {
      const payload = action.payload;
      const result = [];
      payload[0].policy.forEach(e => {
          result.push({
            'policy_details': {
                'J03_05': e.policyInfoView.policyNo,
                'J03_06': e.policyInfoView.owner,
                'J03_07': e.policyInfoView.insured,
                'J03_08': e.policyInfoView.assignee,
                'J03_09': e.policyInfoView.productNameEng,
                'J03_10': e.policyInfoView.policyStatusEng,
                'J03_11': e.policyInfoView.policyInceptionDate,
                'J03_12': e.policyInfoView.policyRiskAcceptanceDate,
                'J03_13': e.policyInfoView.policyTerm,
                'J03_14': e.policyInfoView.policyPremTerm,
                'J03_15': e.policyInfoView.policyCurrencyEng
            },
            'policy_roles': {
                'J03_18': e.policyInfoView.owner,
                'J03_19': '',
                'J03_20': e.policyInfoView.assignee,
                'J03_21': e.policyInfoView.insured,
                'J03_22': e.policyInfoView.dob,
                'J03_23': e.policyInfoView.sex,
                'J03_24': convertAddress(payload[1].clientInfo.address1, payload[1].clientInfo.address2, payload[1].clientInfo.address3, payload[1].clientInfo.address4)
            },
            'payment_information': {
                'J03_26': e.policyPaymentInfoView.policyPaymentModeEng,
                'J03_27': e.policyPaymentInfoView.paymentMethodEng,
                'J03_28': e.policyPaymentInfoView.policyCurrencyEng,
                'J03_29': e.policyInfoView.regPremiumAmount,
                'J03_30': e.policyPaymentInfoView.bankAccountName,
                'J03_31': e.policyPaymentInfoView.bankAccountNo,
                'J03_32': e.policyPaymentInfoView.bankAccountStatusEng,
                'J03_33': e.policyPaymentInfoView.billingDate,
                'J03_34': e.policyPaymentInfoView.ppsNo,
                'J03_35': e.policyPaymentInfoView.premiumDueDate,
                'J03_36': e.policyPaymentInfoView.totalPremiumReceivedAmount,
                'J03_37': e.policyPaymentInfoView.nextExtractionDate,
                'J03_38': e.policyPaymentInfoView.totalOutstandingPremiumAmount,
                'J03_39': e.policyPaymentInfoView.totalPremiumSuspenseAmount
            },
            'benefit_information': convertBenefitInfo(e.policyBenefitInfoView),
            'consultant_information': {
                'J03_48': e.policyConsultantInfoView.consultantName,
                'J03_49': e.policyConsultantInfoView.brokerName,
                'J03_50': e.policyConsultantInfoView.officePhoneNo,
                'J03_51': e.policyConsultantInfoView.contactAddress
            }
        });
      });
      return Object.assign({}, state, result);
    }

    default:
      return state;
  }
}
