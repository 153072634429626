import * as g01 from '../actions/g01.actions';
import { convertCurrency } from '../utils';

export interface State {
  investment_list_display: [];
  investment_list: [];
  selected_switch_out_investment_index: Number;
  selected_switch_in_investment_index: Number;
  switch_in_investment_list: [];
  switch_in_total_percentage: Number;
  transaction_no: String;
  data: any;
  g01_page_content: any;
  g01b_page_content: any;
}

export const initialState = {
  initial_g01_policy: '',
  investment_list_display: [],
  investment_list: [],
  selected_switch_out_investment_index: null,
  selected_switch_in_investment_index: null,
  switch_in_investment_list: [],
  switch_in_total_percentage: 0,
  transaction_no: '',
  data: {},
  g01_page_content: {
    tableData: {
      tableData: [],
      tableSummary: []
    },
    chartData: [],
    policy_details_display: {},
    account_list: [],
    selected_policy: '',
    selected_account: {},
    pending_transactions: []
  },
  g01b_page_content: {
    fund_manager_list: [],
    fund_list: []
  }
};

export function reducer(state = initialState, action: g01.Actions) {
  switch (action.type) {
    case g01.SET_SELECTED_SWITCH_OUT_INVESTMENT_INDEX: {
      const index = action.payload;
      return Object.assign({}, state, {
        ...state,
        selected_switch_out_investment_index: index
      });
    }

    case g01.UPDATE_SWITCH_OUT_INVESTMENT: {
      const updatedInvestment = action.payload;
      const investment_list_display = JSON.parse(JSON.stringify(state.investment_list_display));
      investment_list_display[
        state.selected_switch_out_investment_index
      ] = updatedInvestment;
      return Object.assign({}, state, {
        ...state,
        investment_list_display: investment_list_display
      });
    }

    case g01.SET_SELECTED_SWITCH_IN_INVESTMENT_INDEX: {
      const index = action.payload;
      return Object.assign({}, state, {
        ...state,
        selected_switch_in_investment_index: index
      });
    }

    case g01.ADD_SWITCH_IN_INVESTMENT: {
      const new_switch_in_investment_list = JSON.parse(
        JSON.stringify(state.switch_in_investment_list)
      );
      new_switch_in_investment_list.push(action.payload);
      let percentage = state.switch_in_total_percentage;
      percentage += action.payload.switch_in_amount;
      return Object.assign({}, state, {
        ...state,
        switch_in_investment_list: new_switch_in_investment_list,
        switch_in_total_percentage: percentage
      });
    }

    case g01.DELETE_SWITCH_IN_INVESTMENT: {
      const index = action.payload;
      const new_switch_in_investment_list = JSON.parse(
        JSON.stringify(state.switch_in_investment_list)
      );
      const percentage =
        state.switch_in_total_percentage -
        new_switch_in_investment_list[index].switch_in_amount;
      new_switch_in_investment_list.splice(index, 1);
      return Object.assign({}, state, {
        ...state,
        switch_in_investment_list: new_switch_in_investment_list,
        switch_in_total_percentage: percentage
      });
    }

    case g01.UPDATE_SWITCH_IN_INVESTMENT: {
      const updated_investment = action.payload;
      const new_switch_in_investment_list = JSON.parse(
        JSON.stringify(state.switch_in_investment_list)
      );
      const percentage =
        state.switch_in_total_percentage -
        new_switch_in_investment_list[state.selected_switch_in_investment_index]
          .switch_in_amount +
        updated_investment.switch_in_amount;
      new_switch_in_investment_list[
        state.selected_switch_in_investment_index
      ] = updated_investment;
      return Object.assign({}, state, {
        ...state,
        switch_in_investment_list: new_switch_in_investment_list,
        switch_in_total_percentage: percentage
      });
    }

    case g01.CHANGE_POLICY: {
      const selected_policy = action.payload;
      const selected_account = state.data[selected_policy] && state.data[selected_policy]['account_list']
        ? state.data[selected_policy]['account_list'][0]
        : {};
      return Object.assign({}, state, {
        ...state,
        switch_in_investment_list: [],
        switch_in_total_percentage: 0,
        g01_page_content: {
          ...state.g01_page_content,
          ...state.data[selected_policy],
          selected_policy: selected_policy,
          selected_account: selected_account
        }
      });
    }

    case g01.CHANGE_ACCOUNT: {
      const account = state.g01_page_content['account_list'].find(
        acc => acc.policyAcctName === action.payload
      );
      return Object.assign({}, state, {
        ...state,
        switch_in_investment_list: [],
        switch_in_total_percentage: 0,
        g01_page_content: {
          ...state.g01_page_content,
          selected_account: account
        }
      });
    }

    case g01.SET_SWITCH_IN_CHART_AND_TABLE_DATA: {
      const tableData = transformTableData(action.payload.tableData, state.g01_page_content['policy_details_display']['G01_08']);
      const chartData = transformChartData(action.payload.chartData);
      const investment_list = action.payload.tableData.policyCovrUnitBalanceList;

      // Fixed workitem 391
      const tableData2 = transformTableData(action.payload.newTableData, state.g01_page_content['policy_details_display']['G01_08']);
      // const investment_list_display = tableData['tableData'] ? tableData['tableData'].map(row => {
      const investment_list_display = tableData2['tableData'] ? tableData2['tableData'].map(row => {
        return {
          ...row,
          G01_36: 0,
          G01_39: Number('0').toFixed(2)
        };
      }) : [];
      return Object.assign({}, state, {
        ...state,
        // investment_list_display: investment_list_display.filter(e => !(e['G01_31'].includes('*') || e['G01_31'].includes('#'))),
        investment_list_display: investment_list_display,
        investment_list: investment_list,
        g01_page_content: {
          ...state.g01_page_content,
          tableData: tableData,
          chartData: chartData,
        }
      });
    }

    case g01.SET_SWITCH_IN_FUND_MANAGER_LIST: {
      const fund_manager_list = action.payload;
      return Object.assign({}, state, {
        ...state,
        g01b_page_content: {
          ...state.g01b_page_content,
          fund_manager_list: fund_manager_list
        }
      });
    }

    case g01.SET_SWITCH_IN_FUND_LIST: {
      const fund_list = action.payload;
      return Object.assign({}, state, {
        ...state,
        g01b_page_content: {
          ...state.g01b_page_content,
          fund_list: fund_list
        }
      });
    }

    case g01.SET_G01_PENDING_TRANSACTION: {
      const pending_transactions = action.payload;
      // const investment_list_display = this.investment_list_display.filter(f => !f['G01_13'].includes(this.pending_transactions['fundCode']));
      return Object.assign({}, state, {
        ...state,
        // investment_list_display: investment_list_display,
        g01_page_content: {
          ...state.g01_page_content,
          pending_transactions: pending_transactions
        }
      });
    }

    case g01.SET_SWITCH_IN_TRANSACTION_NO: {
      const transaction_no = action.payload;
      return Object.assign({}, state, {
        ...state,
        transaction_no: transaction_no
      });
    }

    case g01.SWITCH_OUT_ALL: {
      const investment_list_display = state.investment_list_display.map(item => {
        return {
          ...item,
          G01_36: 100,
          unit: 'G01_37'
        };
      });
      return Object.assign({}, state, {
        ...state,
        investment_list_display: investment_list_display
      });
    }

    case g01.GO_TO_PAGE_G01: {
      const data = transformResponse(action.payload);
      const selected_policy = (state.initial_g01_policy && state.initial_g01_policy !== '') ? state.initial_g01_policy : data['policy_no_list'][0];
      const selected_account = data[selected_policy] && data[selected_policy]['account_list']
        ? data[selected_policy]['account_list'][0]
        : null;
      return Object.assign({}, state, {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        g01_page_content: {
          ...state.g01_page_content,
          ...data[selected_policy],
          selected_policy: selected_policy,
          selected_account: selected_account
        }
      });
    }

    case g01.SET_SELECTED_POLICY_NO: {
      const policy_no = action.payload;
      return Object.assign({}, state, {
        ...state,
        initial_g01_policy: policy_no,
      });
    }

    case g01.RESET_G01_STATE: {
      return Object.assign({}, initialState);
    }

    default:
      return state;
  }
}

function transformTableData(tableData, inPlanCurrency) {
  if (
    !tableData.policyCovrUnitBalanceList ||
    tableData.policyCovrUnitBalanceList.length === 0
  ) {
    return [];
  }
  const transformedTableData = {
    tableData: [],
    tableSummary: []
  };
  transformedTableData['tableData'] = tableData.policyCovrUnitBalanceList.map(row => {
    return {
      G01_12: row['fundName'] + ' ' + row['fundSymbol'],
      G01_13: row['fundCode'],
      G01_14: `${row['fundCurrency']}`,
      G01_15: row['unitBalance'].toFixed(5),
      G01_16: row['unitPriceDate'],
      G01_17: convertCurrency(row['unitPrice'], 3),
      G01_18: `${row['weight']}%`,
      G01_20: convertCurrency(row['amountInFundCurr'], 2),
      G01_21: row['amountInPolicyCurr'],
      G01_31: row['fundName'] + ' ' + row['fundSymbol']
    };
  });
  transformedTableData['tableSummary'] = [
    {
      G01_19: convertCurrency(transformedTableData['tableData'].reduce((acc, val) => {
        return acc + Number(val.G01_21);
      }, 0))
    },
    {
      G01_08: inPlanCurrency,
    }
  ];
  transformedTableData['tableData'].forEach(row => {
    row['G01_21'] = convertCurrency(row['G01_21'], 2);
  });
  return transformedTableData;
}

function transformChartData(chartData) {
  if (
    !chartData.policyCovrUnitBalanceList ||
    chartData.policyCovrUnitBalanceList.length === 0
  ) {
    return [];
  }
  const transformedChartData = chartData.policyCovrUnitBalanceList.map(pie => {
    return {
      G01_12: pie['fundName'] + pie['fundSymbol'],
      G01_13: pie['fundCode'],
      G01_14: `${pie['weight']}%`,
      G01_15: pie['amountInPolicyCurr'].toFixed(2)
    };
  });
  return transformedChartData;
}

function transformResponse(res) {
  try {
    const getSwitchInOrRedirectionPoliciesRes = res[0];
    // getSwitchInOrRedirectionPoliciesRes['policy'] = getSwitchInOrRedirectionPoliciesRes['policy'].filter(policy => {
    //   return policy['policyInfoView']['policyValueInHKD'] > 0;
    // });
    const data = {};
    data['policy_no_list'] = getSwitchInOrRedirectionPoliciesRes['policy'].map(
      policy => policy['policyInfoView']['policyNo']
    );
    // policy[i] cannot mapped correctly to a SORTED data['policy_no_list'], as the api will return the selected api as the first item in order\
    // Sort the policy as last
    // data['policy_no_list'].sort ();
    data['policy_no_list'].forEach((policyNo, i) => {
      data[policyNo] = { policy_details_display: {} };


      data[policyNo]['policy_details_display'] = {
        G01_03: policyNo,
        G01_04:
          // Why passing an array to a field?
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyAccountView'][
          'policyAcctName'
          ],
        G01_05:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'owner'
          ],
        G01_06:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'assignee'
          ],
        G01_07:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'productName'
          ],
        G01_08:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'policyCurrency'
          ],
        risk_tol:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'riskToleranceLevel'
          ] || '-',
        d_fund:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'isEligibleForDFund'
          ] || '-',
        income_fund:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'isEligibleForIncomeFund'
          ] || '-',
        last_updated:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'riskProfileLastUpdateDateInStrn'
          ] || '-',
        is_joint:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyInfoView'][
          'isJointOwnerPolicy'
          ] === 'Y',
        valid_bank:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'] !== null &&
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'][
            'achStatus'
          ] === 'Y',
        incomeFund_paymentmethod:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'] != null ?
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyIncomeFundBankAccountView'][
            'incomeFundPaymentMethod'
          ] : '-',
        provision_accepted:
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyProvisionAcceptance'] !== null &&
          getSwitchInOrRedirectionPoliciesRes.policy[i]['policyProvisionAcceptance'][
            'provisionAcceptanceFlag'
          ] === 'Y',
        server_timestamp:
          res[0].returnStatus['loginDateTime'] || '-'
      };
      data[policyNo]['account_list'] =
        getSwitchInOrRedirectionPoliciesRes['policy'][i]['policyAccountView'];

    });
    // data['policy_no_list'].sort();
    return data;
  } catch (err) {
    console.log('g01 transform failed', err);
  }
}
