import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InfoCardComponent } from './info-card/info-card.component';
import { InfoTableComponent } from './info-table/info-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { BannerComponent } from './banner/banner.component';
import { BannerAnnouncementComponent } from './banner-announcement/banner-announcement.component';
import { BannerInplusEntranceComponent } from './banner-inplus-entrance/banner-inplus-entrance.component';
import { ComplexTableComponent } from './complex-table/complex-table.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { ComplexTableRowDetailPage } from './complex-table/complex-table-row-detail/complex-table-row-detail.page';

@NgModule({
  declarations: [
    InfoCardComponent,
    InfoTableComponent,
    BannerComponent,
    BannerAnnouncementComponent,
    BannerInplusEntranceComponent,
    ComplexTableComponent,
    PieChartComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    Ng2GoogleChartsModule,
    FormsModule,
    TranslateModule.forChild(),
    RouterModule
  ],
  entryComponents: [
  ],
  exports: [
    InfoCardComponent,
    InfoTableComponent,
    BannerComponent,
    BannerAnnouncementComponent,
    BannerInplusEntranceComponent,
    ComplexTableComponent,
    PieChartComponent,
  ],
})
export class ComponentsModule { }
