import { Action } from '@ngrx/store';
export const SET_NAVIGATION_PAGE = '[Navigation Component] SetNavigationPage';
export const SET_TRANSACTION_STATUS = '[Navigation Component] SetTransactionStatus';

export class SetNavigationPage implements Action {
    readonly type = SET_NAVIGATION_PAGE;
    constructor(public payload: any) { }
}

export class SetTransactionStatus implements Action {
    readonly type = SET_TRANSACTION_STATUS;
    constructor(public payload: any) { }
}

export type Actions
  =
  SetNavigationPage |
  SetTransactionStatus;
