import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PasswordEncryptionService {

  constructor() {}

    encryptFunc(plainText, salt, hashAlgorithm = CryptoJS.algo.SHA1, passwordIterations = 10000, initialVector = '8h2b4d6f5e3c7g1a', aesKeySize = 256, password = 'gtstandardlife') {
      const hashedPlainText =  CryptoJS.enc.Utf8.parse(plainText);
      const hashedIv = CryptoJS.enc.Utf8.parse(initialVector);

      const symmetricKey = CryptoJS.PBKDF2(password, salt, {
          keySize: aesKeySize / 32,
          hasher: hashAlgorithm,
          iterations: passwordIterations
        });

      const encryptedText = CryptoJS.AES.encrypt(hashedPlainText, symmetricKey, {iv: hashedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, BlockSize: 128 });

      // console.log('encryptedText:' + encryptedText.toString());
      return encryptedText.toString();
    }

    decryptFunc(cipherText, salt, hashAlgorithm = CryptoJS.algo.SHA1, passwordIterations = 10000, initialVector = '8h2b4d6f5e3c7g1a', aesKeySize = 256, password = 'gtstandardlife') {
      const hashedIv = CryptoJS.enc.Utf8.parse(initialVector);

      const symmetricKey = CryptoJS.PBKDF2(password, salt, {
          keySize: aesKeySize / 32,
          hasher: hashAlgorithm,
          iterations: passwordIterations
        });

      const decryptedText = CryptoJS.AES.decrypt(cipherText, symmetricKey, {iv: hashedIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, BlockSize: 128 });
      // console.log('decryptedText: ' + decryptedText.toString(CryptoJS.enc.Utf8));
      return decryptedText.toString(CryptoJS.enc.Utf8);
    }

}
