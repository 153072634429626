import * as q01 from '../actions/q01.actions';

export interface State {
    isAccept: false;
}

export const initialState = {
    isAccept: false,
};

export function reducer(state = initialState, action: q01.Actions) {
    switch (action.type) {
        case q01.ACCEPT_IMPORTANT_INFORMATION: {
            const q01Payload: any = action.payload;
            return Object.assign({}, state, {
                ...state,
                ...q01Payload
            });
        }

        default:
            return state;
    }
}
