import { Action } from '@ngrx/store';

export const GO_TO_PAGE_F04 = '[Navigation Component] GoToPageF04 ';
export const CHANGE_POLICY = '[f04 Component] ChangePolicy';
export const SET_F04_PAST_TRANSACTION = '[f04 Component] SetF04PastTransaction';
export const SET_F04_SELECTED_TRANSACTION_NO = '[f04 Component] SetF04SelectedTransactionNo';
export const SET_F04_DIVIDEND_ACCOUNT_SUMMARY = '[f04 Component] SetF04DividendAccountSummary';
export const RESET_F04_STATE = '[h01 Component] ResetF04State';

export class GoToPageF04 implements Action { readonly type = GO_TO_PAGE_F04; constructor(public payload: any, public currentPage: any) { } }
export class F04ChangePolicy implements Action {
  readonly type = CHANGE_POLICY;
  constructor(public payload: any) { }
}

export class SetF04SelectedTransactionNo implements Action {
  readonly type = SET_F04_SELECTED_TRANSACTION_NO;
  constructor(public payload: any) { }
}


export class SetF04PastTransaction implements Action {
  readonly type = SET_F04_PAST_TRANSACTION;
  constructor(public payload: any, public currentPage) { }
}

export class SetF04DividendAccountSummary implements Action {
  readonly type = SET_F04_DIVIDEND_ACCOUNT_SUMMARY;
  constructor(public payload: any, public currentPage) { }
}

export class ResetF04State implements Action {
  readonly type = RESET_F04_STATE;
  constructor() { }
}
export type Actions
  =
  GoToPageF04 |
  F04ChangePolicy |
  SetF04PastTransaction |
  SetF04SelectedTransactionNo |
  SetF04DividendAccountSummary |
  ResetF04State;
