import * as moment from 'moment';

// Takes a currency string and format it into "##,###.##" format.
export function convertCurrency(curr, decimals = 2) {
    if (decimals === 0) {
        return curr.replace(/\B(?=(\d{3})+(?!\d))/g, ',').toString();
    } else {
        return curr.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',').toString();
    }
}

export function modifyDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

export function saveByteArray(reportName, byte, fileType) {
    const blob = new Blob([byte], {type: `application/${fileType}`});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
}

export function zeroToNA (number) {
    if (number > 0) {
        return number;
    } else {
        return 'N/A';
    }
}
