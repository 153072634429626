import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationService } from './providers/navigation.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'a01-login-page',
    pathMatch: 'full'
  },
  { path: 'app', loadChildren: './pages/tabs-page/tabs-page.module#TabsModule', canLoad: [NavigationService] },
  { path: 'a01-login-page', loadChildren: './pages/a01-login-page/a01-login-page.module#A01LoginPagePageModule' },
  { path: 'b01-signup-identity-verification', loadChildren: './pages/b01-signup-identity-verification/b01-signup-identity-verification.module#B01SignupIdentityVerificationPageModule' },
  { path: 'b02-signup-online-user-identity', loadChildren: './pages/b02-signup-online-user-identity/b02-signup-online-user-identity.module#B02SignupOnlineUserIdentityPageModule' },
  { path: 'b02a-signup-auto-enrollment', loadChildren: './pages/b02a-signup-auto-enrollment/b02a-signup-auto-enrollment.module#B02aSignupAutoEnrollmentPageModule' },
  { path: 'b02b-signup-auto-enrollment-step2', loadChildren: './pages/b02b-signup-auto-enrollment-step2/b02b-signup-auto-enrollment-step2.module#B02bSignupAutoEnrollmentStep2PageModule' },
  { path: 'b03-signup-terms-condition', loadChildren: './pages/b03-signup-terms-condition/b03-signup-terms-condition.module#B03SignupTermsConditionPageModule' },
  { path: 'b04-signup-registration-finished', loadChildren: './pages/b04-signup-registration-finished/b04-signup-registration-finished.module#B04SignupRegistrationFinishedPageModule' },
  { path: 'b04a-signup-registration-declined', loadChildren: './pages/b04a-signup-registration-declined/b04a-signup-registration-declined.module#B04aSignupRegistrationDeclinedPageModule'},
  { path: 'c01-forgot-password', loadChildren: './pages/c01-forgot-password/c01-forgot-password.module#C01ForgotPasswordPageModule' },
  { path: 'c02-reset-password', loadChildren: './pages/c02-reset-password/c02-reset-password.module#C02ResetPasswordPageModule' },
  { path: 'c03-email-confirmation', loadChildren: './pages/c03-email-confirmation/c03-email-confirmation.module#C03EmailConfirmationPageModule' },
  { path: 'c04-unlock-account', loadChildren: './pages/c04-unlock-account/c04-unlock-account.module#C04UnlockAccountPageModule' },
  { path: 'd01-forgot-userid', loadChildren: './pages/d01-forgot-userid/d01-forgot-userid.module#D01ForgotUseridPageModule' },
  { path: 'd02-forgot-userid-confirmation', loadChildren: './pages/d02-forgot-userid-confirmation/d02-forgot-userid-confirmation.module#D02ForgotUseridConfirmationPageModule' },
  { path: 'f01-j01-wealth-protection', loadChildren: './pages/f01-j01-wealth-protection/f01-j01-wealth-protection.module#F01J01PageModule', canLoad: [NavigationService] },
  { path: 'f01-my-wealth', loadChildren: './pages/f01-my-wealth/f01-my-wealth.module#F01MyWealthPageModule', canLoad: [NavigationService] },
  { path: 'f02-financial-summary', loadChildren: './pages/f02-financial-summary/f02-financial-summary.module#F02FinancialSummaryPageModule', canLoad: [NavigationService] },
  { path: 'f03-policy-information', loadChildren: './pages/f03-policy-information/f03-policy-information.module#F03PolicyInformationPageModule', canLoad: [NavigationService] },
  { path: 'f04-policy-transaction', loadChildren: './pages/f04-policy-transaction/f04-policy-transaction.module#F04PolicyTransactionPageModule', canLoad: [NavigationService] },
  { path: 'f04a-policy-transaction-detail', loadChildren: './pages/f04a-policy-transaction-detail/f04a-policy-transaction-detail.module#F04aPolicyTransactionDetailPageModule' },
  { path: 'g01-switching', loadChildren: './pages/g01-switching/g01-switching.module#G01SwitchingPageModule', canLoad: [NavigationService] },
  { path: 'g01a-switch-out-detail', loadChildren: './pages/g01a-switch-out-detail/g01a-switch-out-detail.module#G01aSwitchOutDetailPageModule', canLoad: [NavigationService] },
  { path: 'g01b-switch-in-detail', loadChildren: './pages/g01b-switch-in-detail/g01b-switch-in-detail.module#G01bSwitchInDetailPageModule', canLoad: [NavigationService] },
  { path: 'g02-switching-data-confirmation', loadChildren: './pages/g02-switching-data-confirmation/g02-switching-data-confirmation.module#G02SwitchingDataConfirmationPageModule', canLoad: [NavigationService] },
  { path: 'g03-switching-completed', loadChildren: './pages/g03-switching-completed/g03-switching-completed.module#G03SwitchingCompletedPageModule', canLoad: [NavigationService] },
  { path: 'g04-risk-warning', loadChildren: './pages/g04-risk-warning/g04-risk-warning.module#G04RiskWarningPageModule' },
  { path: 'h01-redirection', loadChildren: './pages/h01-redirection/h01-redirection.module#H01RedirectionPageModule', canLoad: [NavigationService] },
  { path: 'h01a-future-investment-detail', loadChildren: './pages/h01a-future-investment-detail/h01a-future-investment-detail.module#H01aFutureInvestmentDetailPageModule', canLoad: [NavigationService] },
  { path: 'h02-redirection-data-confirmation', loadChildren: './pages/h02-redirection-data-confirmation/h02-redirection-data-confirmation.module#H02RedirectionDataConfirmationPageModule', canLoad: [NavigationService] },
  { path: 'h03-redirection-completed', loadChildren: './pages/h03-redirection-completed/h03-redirection-completed.module#H03RedirectionCompletedPageModule', canLoad: [NavigationService] },
  { path: 'i01-my-documents', loadChildren: './pages/i01-my-documents/i01-my-documents.module#I01MyDocumentsPageModule', canLoad: [NavigationService] },
  { path: 'ia01-rpq-disclaimer', loadChildren: './pages/ia01-rpq-disclaimer/ia01-rpq-disclaimer.module#Ia01RpqDisclaimerPageModule', canLoad: [NavigationService] },
  { path: 'ia02a-rpq-basic-information', loadChildren: './pages/ia02a-rpq-basic-information/ia02a-rpq-basic-information.module#Ia02aRpqBasicInformationPageModule', canLoad: [NavigationService] },
  { path: 'ia02b-rpq-investment-tenor', loadChildren: './pages/ia02b-rpq-investment-tenor/ia02b-rpq-investment-tenor.module#Ia02bRpqInvestmentTenorPageModule', canLoad: [NavigationService] },
  { path: 'ia02c-rpq-investment-objective', loadChildren: './pages/ia02c-rpq-investment-objective/ia02c-rpq-investment-objective.module#Ia02cRpqInvestmentObjectivePageModule', canLoad: [NavigationService] },
  { path: 'ia02d-rpq-risk-tolerance', loadChildren: './pages/ia02d-rpq-risk-tolerance/ia02d-rpq-risk-tolerance.module#Ia02dRpqRiskTolerancePageModule', canLoad: [NavigationService] },
  { path: 'ia02e-rpq-financial-circumstances', loadChildren: './pages/ia02e-rpq-financial-circumstances/ia02e-rpq-financial-circumstances.module#Ia02eRpqFinancialCircumstancesPageModule', canLoad: [NavigationService] },
  { path: 'ia03-rpq-result', loadChildren: './pages/ia03-rpq-result/ia03-rpq-result.module#IA03RPQResultPageModule', canLoad: [NavigationService] },
  { path: 'ib01-movies-list', loadChildren: './pages/ib01-movies-list/ib01-movies-list.module#Ib01MoviesListPageModule' },
  { path: 'ib02-movie-detail', loadChildren: './pages/ib02-movie-detail/ib02-movie-detail.module#Ib02MovieDetailPageModule' },
  { path: 'j01-my-protection', loadChildren: './pages/j01-my-protection/j01-my-protection.module#J01MyProtectionPageModule', canLoad: [NavigationService] },
  { path: 'j02-protection-financial-summary', loadChildren: './pages/j02-protection-financial-summary/j02-protection-financial-summary.module#J02ProtectionFinancialSummaryPageModule', canLoad: [NavigationService] },
  { path: 'j03-protection-policy-information', loadChildren: './pages/j03-protection-policy-information/j03-protection-policy-information.module#J03ProtectionPolicyInformationPageModule', canLoad: [NavigationService] },
  { path: 'k02-personal-information', loadChildren: './pages/k02-personal-information/k02-personal-information.module#K02PersonalInformationPageModule', canLoad: [NavigationService] },
  { path: 'k03-change-personal-information', loadChildren: './pages/k03-change-personal-information/k03-change-personal-information.module#K03ChangePersonalInformationPageModule', canLoad: [NavigationService] },
  { path: 'k04-change-password', loadChildren: './pages/k04-change-password/k04-change-password.module#K04ChangePasswordPageModule', canLoad: [NavigationService] },
  { path: 'k05-change-bank-account', loadChildren: './pages/k05-change-bank-account/k05-change-bank-account.module#K05ChangeBankAccountPageModule' },
  { path: 'l02-service-centre', loadChildren: './pages/l02-service-centre/l02-service-centre.module#L02ServiceCentrePageModule', canLoad: [NavigationService] },
  { path: 'l03-service-forms', loadChildren: './pages/l03-service-forms/l03-service-forms.module#L03ServiceFormsPageModule', canLoad: [NavigationService] },
  { path: 'l04-payment-methods', loadChildren: './pages/l04-payment-methods/l04-payment-methods.module#L04PaymentMethodsPageModule', canLoad: [NavigationService] },
  { path: 'l05-sub-account-management', loadChildren: './pages/l05-sub-account-management/l05-sub-account-management.module#L05SubAccountManagementPageModule', canLoad: [NavigationService] },
  { path: 'la01-risk-profile', loadChildren: './pages/la01-risk-profile/la01-risk-profile.module#La01RiskProfilePageModule' , canLoad: [NavigationService] },
  { path: 'm02-notice-investment-choice', loadChildren: './pages/m02-notice-investment-choice/m02-notice-investment-choice.module#M02NoticeInvestmentChoicePageModule', canLoad: [NavigationService] },
  { path: 'm03-notice-latest-news', loadChildren: './pages/m03-notice-latest-news/m03-notice-latest-news.module#M03NoticeLatestNewsPageModule', canLoad: [NavigationService] },
  { path: 'n02-contact-us', loadChildren: './pages/n02-contact-us/n02-contact-us.module#N02ContactUsPageModule' },
  { path: 'n03-legal-and-privacy-statement', loadChildren: './pages/n03-legal-and-privacy-statement/n03-legal-and-privacy-statement.module#N03LegalAndPrivacyStatementPageModule' },
  { path: 'n04-site-map', loadChildren: './pages/n04-site-map/n04-site-map.module#N04SiteMapPageModule', canLoad: [NavigationService] },
  { path: 'p01-our-investment-choices', loadChildren: './pages/p01-our-investment-choices/p01-our-investment-choices.module#P01OurInvestmentChoicesPageModule', canLoad: [NavigationService] },
  { path: 'q01-Important-Information-Before-Investment', loadChildren: './pages/q01-important-information-before-investment/q01-important-information-before-investment.module#Q01ImportantInformationBeforeInvestmentPageModule', canLoad: [NavigationService] },
  { path: 'r01-top5-inflow-outflow', loadChildren: './pages/r01-top5-inflow-outflow/r01-top5-inflow-outflow.module#R01Top5InflowOutflowPageModule', canLoad: [NavigationService] },
  { path: 'complex-table-row-detail', loadChildren: './components/complex-table/complex-table-row-detail/complex-table-row-detail.module#ComplexTableRowDetailPageModule', canLoad: [NavigationService] },
  { path: 'fund-detail', loadChildren: './pages/fund-detail/fund-detail.module#FundDetailPageModule', canLoad: [NavigationService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
