import * as r01 from '../actions/r01.actions';

export interface State {
    r01: any;
}

export const initialState = {
    inflowFundList: [],
    outflowFundList: [],
};

export function reducer(state = initialState, action: r01.Actions) {
    switch (action.type) {
        case r01.GO_TO_PAGE_R01: {
            const payload = action.payload;
            return Object.assign({}, state, {
                inflowFundList: payload.inflowFundList,
                outflowFundList: payload.outflowFundList,
            });
        }

        default:
            return state;
    }
}
