import * as page from '../actions/l05.actions';
import * as moment from 'moment';

export interface State {
    l05: any;
}

export const initialState = [{
    'L05_03': '000000000000',
    'L05_04': 'XXXXXXXXXXX',
    'L05_05': 'XXXXX XXXXX',
    'L05_06': '01/01/2001',
    'L05_07': 'Registered',
    'L05_08': 'XXXXX'

}];

function modifyDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function reducer(state = initialState, action: page.Actions) {
    switch (action.type) {
        case page.GO_TO_PAGE_L05: {
            const payload = action.payload;
            const result = [];
            payload.forEach(e => {
                result.push({
                    'L05_03': e.policyNo,
                    'L05_04': e.v_USER_CD,
                    'L05_05': e.insuredName,
                    'L05_06': modifyDate(e.policyIssueDate),
                    'L05_07': e.v_STAT,
                    'L05_08': e.insuredSurname

                });
            });
            return Object.assign({}, state, [result]);
        }


        default:
            return state;
        }
}
