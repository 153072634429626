import * as page from '../actions/page.actions';

export interface State {
  k03: any;
}

export const initialState = {
  k03: {
    'personal_information': {
        'K03_03': 'SLA Testing',
        'K03_04': 'Male',
        'K03_05': '07/07/1973'
    },
    'contact_information': {
        'K03_08': 'Flat G, 50/F, standardlife building,',
        'K03_09': '40 - 50 Standardlife Road, Quarry Bay',
        'K03_10': 'Hong Kong',
        'K03_11': 'Hong Kong',
        'K03_13': '2879XXXX',
        'K03_14': '',
        'K03_15': '9888XXXX',
        'K03_17': 'benovelty.tester1@gmail.com'
    }
  },
};

export function reducer(state = initialState, action: page.Actions) {
  switch (action.type) {
    case page.GO_TO_PAGE_K03: {
      const payload = action.payload;
      return Object.assign({}, state, {
        'personal_information': {
          'K02_03': payload.clientInfo.clientName,
          'K02_04': payload.clientInfo.sex,
          'K02_05': payload.clientInfo.dob
      },
      'contact_information': {
          // 'K02_08': payload.clientInfo.address1,
          // 'K02_09': payload.clientInfo.address2,
          // 'K02_10': payload.clientInfo.address3,
          // 'K02_11': payload.clientInfo.address4,
          // 'K02_13': payload.clientInfo.phoneNo_Home,
          // 'K02_14': payload.clientInfo.phoneNo_Office,
          // 'K02_15': payload.clientInfo.phoneNo_Mobile,
          // 'K02_17': payload.clientInfo.emailAddress,
          'eStatement': payload.clientInfo.eStatement,
      }
      });
    }

    default:
      return state;
  }
}
