import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StubdataService {

  constructor(private http: HttpClient) { }

  getCall(endpoint, arg?) {
    let url = './assets/data/stub';
    switch (endpoint) {
        case '/f01_my_wealth': {
          console.log('/f01_my_wealth');
          url = `${url}/f01_my_wealth.json`;
          break;
        }

        case '/f02_policy_summary': {
            console.log('/f02_policy_summary');
            url = `${url}/f02_policy_summary.json`;
            break;
        }

        case '/f03_policy_information': {
          console.log('/f03_policy_information');
          url = `${url}/f03_policy_information.json`;
          break;
        }

        case '/f04_policy_transaction': {
          console.log('/f04_policy_transaction');
          url = `${url}/f04_policy_transaction.json`;
          break;
        }

        case '/g01_switching': {
          console.log('/g01_switching');
          url = `${url}/g01_switching.json`;
          break;
        }

        case '/g02_switching_dataconfirmation': {
          console.log('/g02_switching_dataconfirmation');
          url = `${url}/g02_switching_dataconfirmation.json`;
          break;
        }

        case '/g03_switching_completed': {
          console.log('/g03_switching_completed');
          url = `${url}/g03_switching_completed.json`;
          break;
        }

        case '/h01_redirection': {
          console.log('/h01_redirection');
          url = `${url}/h01_redirection.json`;
          break;
        }

        case '/h02_redirection_dataconfirmation': {
          console.log('/h02_redirection_dataconfirmation');
          url = `${url}/h02_redirection_dataconfirmation.json`;
          break;
        }

        case '/h03_redirection_completed': {
          console.log('/h03_redirection_completed');
          url = `${url}/h03_redirection_completed.json`;
          break;
        }

        case '/j02_protection_financial_summary': {
          console.log('/j02_protection_financial_summary');
          url = `${url}/j02_protection_financial_summary.json`;
          break;
        }

        case '/j03_protection_policy_information': {
          console.log('/j03_protection_policy_information');
          url = `${url}/j03_protection_policy_information.json`;
          break;
        }

        case '/k02_profile_information': {
          console.log('/k02_profile_information');
          url = `${url}/k02_profile_information.json`;
          break;
        }
    }

    return new Promise((resolve, reject) => {
        this.http.get(url).subscribe(res => {
            resolve(res);
       }, error => {
        reject(error);
      });
    });
  }
}
