import * as user from '../actions/user.actions';


export interface State {
  user: any;
}

export const initialState = {
  accessRight: '',
  accountNo: '',
  accountType: '',
  brokerNo: '',
  clientNo: '',
  name: '',
  sessionKey: '',
  username: '',
  emailAddress: '',
  currentPage: '',
  previousPage: '',
  greeting: '',
  isLoggedIn: false,
  startWithMyWealthFlag: true,
  showProvisionConsentMsgFlag: false,
  pagesAllowed: [],
  isTrustPolicySubAccountFlag: false,
  isTrustPolicyMainAccountFlag: false

};

export function reducer(state = initialState, action: user.Actions) {

  switch (action.type) {
    case user.SET_USER: {
      const userPayload: any = action.payload;
      // storeSession(userPayload);
      return Object.assign({}, state, {
        ...state,
        ...userPayload
      });
    }

    default:
      return state;
  }

}

// function storeSession(payload: any) {
//   sessionStorage.setItem('username', payload['username']);
//   sessionStorage.setItem('sessionKey', payload['sessionKey']);
//   sessionStorage.setItem('clientNo', payload['clientNo']);
//   sessionStorage.setItem('greeting', payload['greeting']);
//   sessionStorage.setItem('emailAddress', payload['emailAddress']);
// }


