import { Action } from '@ngrx/store';

export const GO_TO_PAGE_F01 = '[Navigation Component] GoToPageF01 ';
export const SET_F01_CHART_AND_TABLE_DATA = '[f01 Component] SetF01ChartAndTableData';
export const SET_SELECTED_POLICY_NO = '[f01 Component] SetSelectedPolicyNo ';

export class GoToPageF01 implements Action { readonly type = GO_TO_PAGE_F01; constructor(public payload: any) { } }
export class SetF01ChartAndTableData implements Action { readonly type = SET_F01_CHART_AND_TABLE_DATA; constructor(public payload: any) { } }
export class SetSelectedPolicyNo implements Action { readonly type = SET_SELECTED_POLICY_NO; constructor(public payload: any) { } }


export type Actions
  =
  GoToPageF01 |
  SetF01ChartAndTableData |
  SetSelectedPolicyNo;
