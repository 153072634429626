export const commonConfig = {
    basepath_cms: 'https://www.hengansl.com.hk:8443',
    publicsite_url: 'https://www.hengansl.com.hk/',

    morningStar_screener_url: '/investment-information/ec-Screener',
    morningStar_fund_comparison_url: '/investment-information/ec-InvestmentCompare?languageId=',
    ipAddress_url: 'https://api.ipify.org?format=json',

    deviceType_desktop: 'desktop',
    version: 'CS_3.0.10'
  };
