import * as f04 from '../actions/f04.actions';
import * as moment from 'moment';
import { convertCurrency } from '../utils';

export interface State {
    f04: any;
}

export const initialState = {
    policy_details: {},
    payment_information: {},
    payment_history: [],
    policy_list: [],
    data: {},
    selected_transaction: null,
    f04_page_content: {
        selected_policy: null,
        past_transactions: [],
        dividend_account_summary: []
    },
};

function modifyDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function reducer(state = initialState, action: f04.Actions) {
    switch (action.type) {
        case f04.GO_TO_PAGE_F04: {
            const data = transformResponse(action.payload, action.currentPage);
            const selected_policy = state.f04_page_content.selected_policy ? state.f04_page_content.selected_policy : data['policy_no_list'][0];
            return Object.assign({}, state, {
                ...state,
                data: {
                    ...state.data,
                    ...data
                },
                f04_page_content: {
                    ...state.f04_page_content,
                    ...data[selected_policy],
                    selected_policy: selected_policy
                }
            });
        }

        case f04.CHANGE_POLICY: {
            const selected_policy = action.payload;
            return Object.assign({}, state, {
                ...state,
                f04_page_content: {
                    ...state.f04_page_content,
                    ...state.data[selected_policy],
                    selected_policy: selected_policy
                }
            });
        }

        case f04.SET_F04_SELECTED_TRANSACTION_NO: {
            return Object.assign({}, state, {
                ...state,
                selected_transaction: action.payload
            });
        }

        case f04.SET_F04_PAST_TRANSACTION: {
            const past_transactions = transformPastTransactionResponse(action.payload, action.currentPage);
            return Object.assign({}, state, {
                ...state,
                f04_page_content: {
                    ...state.f04_page_content,
                    ...state.data,
                    past_transactions: past_transactions
                }
            });
        }

        case f04.SET_F04_DIVIDEND_ACCOUNT_SUMMARY: {
            const  dividend_account_summary = transformDividendAccountSummaryResponse(action.payload, action.currentPage);
            return Object.assign({}, state, {
                ...state,
                f04_page_content: {
                    ...state.f04_page_content,
                    ...state.data,
                    dividend_account_summary: dividend_account_summary
                }
            });

        }

        case f04.RESET_F04_STATE: {
            return Object.assign({}, initialState);
          }

        default:
            return state;
    }
}

function transformDividendAccountSummaryResponse(res, currentPage) {
    const result = [];

    try {
        const dividendAccountSummary = res[0]['dividendAccountSummary'];
        if (dividendAccountSummary != null) {
            dividendAccountSummary.forEach(e => {
                result.push({
                    'F04_69': e.displayName,
                    // 'F04_70': convertCurrency(e.totalCashDividend, 2),
                    // 'F04_71': convertCurrency(e.totalAdvisoryFee, 2),
                    // 'F04_72': convertCurrency(e.totalPaidCashDividend, 2),
                    'F04_73': convertCurrency(e.totalAvailableDividendBalance, 2)
                  });
            });
        }
        return result;
    } catch (err) {
        console.log('Error in transform DividendAccountSummary Response', err);
        return result;
    }
}

function transformPastTransactionResponse(res, currentPage) {
    const data = {
        data: {},
        content: [],
    };
    try {
        const policyTransactionsList = res[0]['policyTransactionsList'];
        if (policyTransactionsList !== null) {
        policyTransactionsList.forEach(transaction => {
            data['data'][transaction['transactionNo']] = {
                policyTransactionDetail: transaction,
                switchOutTransactionsList: res[0]['switchOutTransactionsList'] && res[0]['switchOutTransactionsList']
                    .filter(e => e['transactionNo'] === transaction['transactionNo'] && transaction['transactionTypeNameEng'] === 'Switching')
                    .map(e => {
                        return {
                            F04_35: e['fundHouse'],
                            F04_36: e['fundName'],
                            F04_37: e['fundCode'],
                            F04_38: e['trnxValue'],
                            RAW_F04_38: e['trnxValue'],
                            unit: e['unitOrPerc']
                        };
                    }),
                switchInTransactionsList: res[0]['switchInTransactionsList'] && res[0]['switchInTransactionsList']
                    .filter(e => e['transactionNo'] === transaction['transactionNo'] && transaction['transactionTypeNameEng'] === 'Switching')
                    .map(e => {
                        return {
                            F04_40: e['fundHouse'],
                            F04_41: e['fundName'],
                            F04_42: e['fundCode'],
                            F04_43: e['trnxValue'],
                            RAW_F04_43: e['trnxValue'],
                            unit: e['unitOrPerc']
                        };
                    }),
                switchingTransactionsAcctAndTypeList: res[0]['switchingTransactionsAcctAndTypeList'] && res[0]['switchingTransactionsAcctAndTypeList'].filter(e => e['transactionNo'] === transaction['transactionNo'] && transaction['transactionTypeNameEng'] === 'Switching'),
                redirectionTransactionsList: res[0]['redirectionTransactionsList'] && res[0]['redirectionTransactionsList']
                    .filter(e => e['transactionNo'] === transaction['transactionNo'] && transaction['transactionTypeNameEng'] === 'Premium Redirection')
                    .map(e => {
                        return {
                            F04_47: e['fundHouse'],
                            F04_48: e['fundName'],
                            F04_49: e['fundCode'],
                            F04_50: e['trnxValue'],
                            RAW_F04_50: e['trnxValue'],
                            unit: e['unitOrPerc']
                        };
                    }),
                redirectionTransactionsAcctAndTypeList: res[0]['redirectionTransactionsAcctAndTypeList'] && res[0]['redirectionTransactionsAcctAndTypeList'].filter(e => e['transactionNo'] === transaction['transactionNo'] && transaction['transactionTypeNameEng'] === 'Premium Redirection')
            };
            data['content'].push({
                F04_14: transaction['transactionNo'],
                F04_15: moment(transaction['requestDate']).format('YYYY-MM-DD'),
                F04_16: transaction['transactionTypeName'],
                F04_17: transaction['transactionStatusName'],
                F04_18: transaction['requestedByName'],
                F04_75: transaction['mismatchDeclarationFlag']
            });
        });
        }
        return data;
    } catch (err) {
        console.log('Error in transform past transaction', err);
        return data;
    }
}


function transformResponse(res, currentPage) {
    try {
        const getMyPoliciesRes = res[0];
        const data = {};
        data['policy_no_list'] = [];
        getMyPoliciesRes['policy'].forEach(policy => {
            if (currentPage === 'f04' || currentPage === 'f03') {
                if (policy.policyInfoView.isMyWealthFlag === 'Y') {
                    data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
                }
            } else if (currentPage === 'j04' || currentPage === 'j03') {
                if (policy.policyInfoView.isMyProtectionFlag === 'Y') {
                    data['policy_no_list'].push(policy['policyInfoView']['policyNo']);
                }
            }
        }
        );
        data['policy_no_list'].sort();
        data['policy_no_list'].forEach((policyNo) => {
            const getPolicy = getMyPoliciesRes.policy.find(p => p.policyInfoView.policyNo === policyNo);
            data[policyNo] = { policy_details: {}, details_of_account: {}, divident_payment: {} };
            data[policyNo]['policy_details'] = {
                F03_05: policyNo,
                F03_06: getPolicy['policyInfoView']['owner'],
                F03_07: getPolicy['policyInfoView']['insured'],
                F03_08: getPolicy['policyInfoView']['assignee'],
                F03_09: getPolicy['policyInfoView']['productName'],
                F03_10: getPolicy['policyInfoView']['policyStatus'],
                F03_11: getPolicy['policyInfoView']['policyInceptionDate'],
                F03_12: getPolicy['policyInfoView']['policyRiskAcceptanceDate'],
                F03_13: getPolicy['policyInfoView']['policyTerm'],
                F03_14: getPolicy['policyInfoView']['policyPremTerm'],
                F03_15: getPolicy['policyInfoView']['policyCurrency'],
                F04_IS_NEW_PAR_PRODUCT: getPolicy['policyInfoView']['isNewParProduct'],
                ProductCode: getPolicy['policyInfoView']['productCode'],
                LockinFlag: getPolicy['policyInfoView']['lockinFlag'],
                CiesPolicyFlag: getPolicy['policyInfoView']['ciesPolicyFlag'],
                CiesPolicy: getPolicy['policyInfoView']['ciesPolicy'],
                AppointAsFIFlag: getPolicy['policyInfoView']['appointAsFIFlag'],
                AppointAsFI: getPolicy['policyInfoView']['appointAsFI']

            };
            data[policyNo]['payment_information'] = {
                F03_26: getPolicy['policyPaymentInfoView']['policyPaymentMode'],
                F03_27: getPolicy['policyPaymentInfoView']['paymentMethod'],
                F03_28: getPolicy['policyPaymentInfoView']['policyCurrency'],
                F03_29: convertCurrency(getPolicy['policyInfoView']['regPremiumAmount']),
                F03_29_LABEL: getPolicy['policyInfoView']['policyPremTermLabel'],
                F03_30: getPolicy['policyPaymentInfoView']['bankAccountName'],
                F03_31: getPolicy['policyPaymentInfoView']['bankAccountNo'],
                F03_32: getPolicy['policyPaymentInfoView']['bankAccountStatus'],
                F03_33: getPolicy['policyPaymentInfoView']['billingDate'],
                F03_34: getPolicy['policyPaymentInfoView']['ppsNo'],
                F03_35: getPolicy['policyPaymentInfoView']['premiumDueDate'],
                F03_36: convertCurrency(getPolicy['policyInfoView']['totalPremiumReceivedAmount']),
                F03_37: getPolicy['policyPaymentInfoView']['nextExtractionDate'],
                F03_38: convertCurrency(getPolicy['policyPaymentInfoView']['totalOutstandingPremiumAmount']),
                F03_39: convertCurrency(getPolicy['policyPaymentInfoView']['totalPremiumSuspenseAmount'])
            };

            if (getPolicy['policyPaymentInfoView']['isCreditCard'] === 'Y' && getPolicy['policyPaymentInfoView']['expiryDate'] !== null && getPolicy['policyPaymentInfoView']['expiryDate'] !== '') {
              data[policyNo]['payment_information']['F03_31'] = getPolicy['policyPaymentInfoView']['bankAccountNo'] + ' (' + getPolicy['policyPaymentInfoView']['expiryDate'] + ')';
            }

            data[policyNo]['dividend_payment'] = getPolicy['policyIncomeFundDividendView'].map(e => {
                return {
                    F04_58: moment(e['paymentDate'], 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'),
                    F04_59: e['fundName'],
                    F04_60: e['noOfUnitEntitled'].toString(),
                    F04_61: e['dividendRate'].toString(),
                    F04_62: e['exchangeRate'].toString(),
                    F04_63: e['dividendAmtinFundCcy'].toString(),
                    F04_64: e['dividendAmtinHKD'].toString(),
                    F04_65: e['creditToName'],
                    F04_66: e['paymentStatus']

                };
            });
            data[policyNo]['payment_history'] = [];

            // TODO Update i18n when ready
            data[policyNo]['fund_dividend'] = getPolicy['policyFundDividendView'].map(e => {
                return {
                    F04_52: e['fundName'],
                    F04_53: moment(e['recordDate'], 'YYYYMMDD').format('DD/MM/YYYY'),
                    F04_54: e['unitsHeld'].toFixed(5),
                    F04_55: moment(e['reinvestmentDate'], 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'),
                    F04_56: e['unitsAllocated']
                };
            });

            getPolicy['policyPaymentHistoryView'].forEach((view) => {
                data[policyNo]['payment_history'].push({
                    F04_06: view['transactionDateInString'],
                    F04_07: view['dueDateInString'],
                    F04_08: view['paymentMethod'],
                    F04_09: view['policyCurrency'],
                    F04_10: convertCurrency(view['paymentAmount']),
                    F04_11: view['paymentStatus'],
                    F04_12: view['rejectReason']
                });
            });
        });
        return data;
    } catch (err) {
        console.log('f04 transform failed', err);
    }
}
