import { Action } from '@ngrx/store';

export const GO_TO_PAGE_F02 = '[Navigation Component] GoToPageF02 ';
export const CHANGE_POLICY = '[f02 Component] ChangePolicy';
export const CHANGE_ACCOUNT = '[f02 Component] ChangeAccount';
export const SET_F02_CHART_AND_TABLE_DATA = '[f02 Component] SetF02ChartAndTableData';
export const SET_F02_CASH_TABLE_DATA = '[f02 Component] SetF02CashTableData';
export const SET_F02_ASPIRE_INFORMATION = '[f02 Component] SetF02AspireInformation';
export const RESET_F02_STATE = '[h01 Component] ResetF02State';

export class GoToPageF02 implements Action { readonly type = GO_TO_PAGE_F02; constructor(public payload: any, public currentPage: any) { } }
export class ChangePolicy implements Action {
  readonly type = CHANGE_POLICY;
  constructor(public payload: any) { }
}
export class ChangeAccount implements Action {
  readonly type = CHANGE_ACCOUNT;
  constructor(public payload: any) { }
}
export class SetF02ChartAndTableData implements Action {
  readonly type = SET_F02_CHART_AND_TABLE_DATA;
  constructor(public payload: any) { }
}
export class SetF02CashTableData implements Action {
  readonly type = SET_F02_CASH_TABLE_DATA;
  constructor(public payload: any) { }
}
export class SetF02AspireInformation implements Action {
  readonly type = SET_F02_ASPIRE_INFORMATION;
  constructor(public payload: any) { }
}
export class ResetF02State implements Action {
  readonly type = RESET_F02_STATE;
  constructor() { }
}

export type Actions
  =
  GoToPageF02 |
  ChangePolicy |
  ChangeAccount |
  SetF02ChartAndTableData |
  SetF02CashTableData |
  SetF02AspireInformation |
  ResetF02State;
