import * as j01 from '../actions/j01.actions';
import { convertCurrency } from '../utils';

export interface State {
  j01: any;
}

export const initialState = {
    'policy_information': [
        {
        }
    ]
};

function sortPolicy(result) {
    const sortedResult = [];
    const sortOrder = [
        'Life Protection', '人壽保障',
        'Critical Illness', '危疾保障',
        'Disability protection', '意外保障',
        'Hospital Income', '住院保障',
        'Premium Waiver', '保費豁免保障',
    ];

    sortOrder.forEach(e => {
        if (Array.isArray(result[e])) {
            result[e].forEach(to_push => {
                sortedResult.push(to_push);
            });
        }
    });
    return sortedResult;
}

export function reducer(state = initialState, action: j01.Actions) {
  switch (action.type) {
    case j01.GO_TO_PAGE_J01: {
      const payload = action.payload;
      const result = [];

      const result2 = [];

     /* payload[0].policy.forEach(e => {
          e.policyBenefitInfoView.forEach(d => {
              if (!result[d.coverageType]) {
                  result[d.coverageType] = [];
              }
              result[d.coverageType].push({
                    'J01_04': d.coverageType,
                    'J01_05': d.policyNo,
                    'J01_06': d.coverageName + d.coverageSymbol,
                    'J01_07': d.policyCurrency,
                    'J01_08': d.coverageExpiryDate,
                    'J01_09': d.premiumExpiryDate,
                    'J01_10': convertCurrency(d.sumInsuredOrAmount),
                    'CovrNo': d.coverageNo
              });
          });
      }); */

      payload[0].policyBenefitView.forEach(e => {
            if (!result[e.coverageType]) {
                result[e.coverageType] = [];
            }
            result[e.coverageType].push({
                  'J01_04': e.coverageType,
                  'J01_05': e.policyNo,
                  'J01_06': e.coverageName + e.coverageSymbol,
                  'J01_07': e.policyCurrency,
                  'J01_08': e.coverageExpiryDate,
                  'J01_09': e.premiumExpiryDate,
                  'J01_10': convertCurrency(e.sumInsuredOrAmount),
                  'CovrNo': e.coverageNo
            });
        });

        // Fixed workitem 73
        payload[0].policy.forEach(e => {
            // console.log('f01 policy', e);
            result2.push({
               'PolicyNo': e.policyInfoView.policyNo,
               'IsTermProductFlag': e.policyInfoView.isTermProductFlag
            });
        });

      console.log(result2);

    // return Object.assign({}, state, {'policy_information': sortPolicy(result)});
    return Object.assign({}, state, {'policy_information': sortPolicy(result), 'policy_information2': result2});
    // return Object.assign({}, state, {'policy_information': result});
    }

    case j01.SET_SELECTED_POLICY_INFO: {
        const policy_Info = action.payload;
        return Object.assign({}, state, {
          ...state,
          selected_policy_info: policy_Info
        });
      }

    default:
      return state;
  }
}
