import { Action } from '@ngrx/store';

export const GO_TO_PAGE_J01 = '[Navigation Component] GoToPageJ01 ';
export const SET_SELECTED_POLICY_INFO = '[j01 Component] SetSelectedPolicyInfo ';

export class GoToPageJ01 implements Action { readonly type = GO_TO_PAGE_J01; constructor(public payload: any) { } }
export class SetSelectedPolicyInfo implements Action { readonly type = SET_SELECTED_POLICY_INFO; constructor(public payload: any) { } }

export type Actions
  =
  GoToPageJ01 |
  SetSelectedPolicyInfo;
