import { Action } from '@ngrx/store';

export const SET_DEVICE = '[Setting Component] SetDevice';

export class SetDevice implements Action {
  readonly type = SET_DEVICE;

  constructor(public payload: string) { }
}

export type Actions
  = SetDevice;
