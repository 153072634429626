import * as setting from '../actions/setting.actions';

export interface State {
  language: string;
}

export const initialState = {
  language: 'en',
};

export function reducer(state = initialState, action: setting.Actions) {
  switch (action.type) {
    case setting.SET_LANGUAGE: {
      const languageCode = action.payload;
      return Object.assign({}, state, {
        language: languageCode
      });
    }

    default:
      return state;
  }
}
