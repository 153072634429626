import * as ia02d from '../actions/ia02d.actions';

export interface State {
  ia02d: any;
}

export const initialState = {
    'eRPQ_section4': {
      'IA02d_02': '',
      'IA02d_08': '',
      'IA02d_14': ''
    }
};

export function reducer(state = initialState, action: ia02d.Actions) {
  switch (action.type) {
    case ia02d.GO_TO_PAGE_IA02D: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'eRPQ_section4': {
            'IA02d_02': payload.investmentAttitude,
            'IA02d_08': payload.priceFluctuation,
            'IA02d_14': payload.planChange
          }
      });
    }

    default:
      return state;
  }
}
