import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  /* Example
      <banner imageSource="assets/img/about/myProfile.png" title="My Profile" subtitle="Update your personal information at anytime"></banner>
  */

  // tslint:disable-next-line:no-input-rename
  @Input('imageSource')imageSource: any;
  // tslint:disable-next-line:no-input-rename
  @Input('title')title: string;
  // tslint:disable-next-line:no-input-rename
  @Input('subtitle')subtitle: string;

  constructor() { }

  ngOnInit() {
  }

}
