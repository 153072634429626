import * as page from '../actions/ib01.actions';

export interface State {
  ib01: any;
}

export const initialState = [];

export function reducer(state = initialState, action: page.Actions) {
  switch (action.type) {
    case page.GO_TO_PAGE_IB01: {
      const payload = action.payload;
      const result = [];

      result.push(payload);
      return Object.assign({}, state, result);
    }

    default:
      return state;
  }
}
