import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getLocalePluralCase } from '@angular/common';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private BASEPATH_CONTENT_VIEWER = environment.basepath_content_viewer;
  private BASEPATH_TRANSACTION = environment.basepath_transaction;
  private BASEPATH_USER_ACCOUNT_MANAGEMENT = environment.basepath_user_account_management;
  private BASEPATH_CMS = environment.basepath_cms;

  private CONTENT_VIEWER_MODELS = ['ChannelContent', 'ClientInfoContent', 'Content', 'PolicyInfoContent'];
  private USER_ACCOUNT_MANAGEMENT_MODELS = ['Session', 'UserAccount'];
  private TRANSACTIONS_MODELS = ['ChannelTransactions', 'FundTransactions', 'GeneralTransactions', 'SystemTransactions', 'UserTransactions'];
  //  Doing this just for consistency. Note CMS_MODEL is not an array
  private CMS_MODEL = 'CMS';

  private lang: String = 'en';
  private system_type: String = 'web';
  private device_type: String = 'desktop';
  private session_key: String = '';
  private username: String = '';
  private ipAddress: String = '';
  private brokerNo: String = '';
  private clientNo: String = '';
  private accountNo: String = '';
  private accountType: String = '';
  private emailAddress: String = '';
  private isBrokerSite: Boolean = false;
  private isDirectLogin: Boolean = false;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private store: Store<any>,
    public loadingController: LoadingController,
  ) {
    this.store.select('setting').subscribe(data => {
      this.lang = data.language;
    });
    this.store.select('user').subscribe(data => {
      this.session_key = data.sessionKey;
      this.username = data.username;
      this.brokerNo = data.brokerNo;
      this.clientNo = data.clientNo;
      this.accountNo = data.accountNo;
      this.accountType = data.accountType;
      this.emailAddress = data.emailAddress;
      this.isDirectLogin = data.isDirectLogin;
    });
    this.store.select('device').subscribe(data => {
      this.system_type = data.systemType;
      this.ipAddress = data.ipAddress;
      this.device_type = data.deviceType;
    });
  }

  private getBasepath(endpoint) {
    const endpoint_model = endpoint.split('/')[1];
    if (this.CONTENT_VIEWER_MODELS.includes(endpoint_model)) {
      return this.BASEPATH_CONTENT_VIEWER;
    } else if (this.TRANSACTIONS_MODELS.includes(endpoint_model)) {
      return this.BASEPATH_TRANSACTION;
    } else if (this.USER_ACCOUNT_MANAGEMENT_MODELS.includes(endpoint_model)) {
      return this.BASEPATH_USER_ACCOUNT_MANAGEMENT;
    } else if (endpoint_model === this.CMS_MODEL) {
      console.log(endpoint.split('/'));
      return this.BASEPATH_CMS;
    }
  }


  private getAxiosParams(endpoint, arg?) {
    const axios_param = {
      params: {
        lang: this.lang,
        type: this.system_type,
        ...arg
      }
    };
    const endpoint_model = endpoint.split('/')[1];
    switch (endpoint_model) {
      case 'Session':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'UserAccount':
        return axios_param;
      case 'ClientInfoContent':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'Content':
        return axios_param;
      case 'ChannelContent':
        axios_param['params']['sessionKey'] = this.session_key;
        axios_param['params']['brokerNo'] = this.brokerNo;
        return axios_param;
      case 'PolicyInfoContent':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'ChannelTransactions':
        axios_param['params']['username'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'FundTransactions':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'GeneralTransactions':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'SystemTransactions':
        axios_param['params']['sessionKey'] = this.session_key;
        return axios_param;
      case 'UserTransactions':
        return axios_param;
      case 'CMS':
        return { params: {} };
      default:
        return axios_param;
    }
  }

  private backendCall(endpoint, arg?) {
    const axios_param = this.getAxiosParams(endpoint, arg);
    let url = '';
    if (endpoint.split('/')[1] !== 'CMS') {
      url = `${this.getBasepath(endpoint)}${endpoint}`;
    } else {
      url = `${this.getBasepath(endpoint)}/${endpoint.split('/')[2]}`;
    }
    console.log(endpoint, url);
    let method = 'get';
    switch (endpoint) {
      case '/Session/GetValidSession':
        /* Sample argument
          no payload required
        */
        break;
      // User Account Management - UserAccount
      case '/UserAccount/GetLoginStatus':
        /* Sample argument
          { username: 'testcust_be1', pwd: 'KU6ONF4xDO3OpAOE4TzTBQ==', loginTime: loginTime }
        */
        axios_param['params']['deviceType'] = this.device_type;
        axios_param['params']['IPAdd'] = this.ipAddress;
        axios_param['params']['isBrokerSite'] = this.isBrokerSite;
        // TODO: remove below when login flow ready
        // method = 'mock';
        break;
      case '/UserAccount/GetLogoutStatus':
        /* Sample argument
          no payload required
        */
        axios_param['params']['sessionKey'] = this.session_key;
        axios_param['params']['username'] = this.username;
        break;
      case '/UserAccount/GetValidUserId':
        /* Sample argument
          no payload requried
        */
        axios_param['params']['username'] = this.username;
        break;
      case '/UserAccount/GetRegisteredPolicies':
        /* Sample argument
          { policyNo: '13998888', activationKey: '12345678' }
        */
        break;

      // Content Viewer - ChannelContent
      case '/ChannelContent/GetBrokerOrTRList':
        /* Sample argument
          no argument required
        */
        break;

      // Content Viewer - ClientInfoContent
      case '/ClientInfoContent/GetClientBirthdayList':
        /* Sample argument
          no argument required
        */
        axios_param['params']['brokerNo'] = this.brokerNo;
        break;
      case '/ClientInfoContent/GetClientPersonalInfo':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        // axios_param['params']['clientNo'] = this.clientNo;
        break;

      case '/ClientInfoContent/GetClientPolicyPersonalInfo':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        // axios_param['params']['clientNo'] = this.clientNo;
        break;

      // Content Viewer - Content
      case '/Content/GetLatestExchangeRateList':
        /* Sample argument
          no argument required
        */
        break;

      case '/Content/GetExchangeRatesListByCurrencyAndDateRange':
        /* Sample argument
          { currency: 'HKD', effectiveFromDate: '2020/01/18', effectiveToDate: '2020/01/20' }
        */
        break;

      case '/Content/GetSQSURL':
        /* Sample argument
          no argument required
        */
        break;

      case '/Content/GetLatestFundPriceList':
        /* Sample argument
          no argument required
        */
        break;

      case '/Content/GetLatestInterestRateList':
        /* Sample argument
          no argument required
        */
        break;

      case '/Content/GetInterestRatesListByCurrencyAndDateRange':
        /* Sample argument
          { currency: 'HKD', effectiveFromDate: '2020/01/18', effectiveToDate: '2020/01/20' }
        */
        break;

      case '/Content/GetLookUpValues':
        /* Sample argument
          { lookUpKey: 'ACCTSTAT' }
        */
        break;

      case '/Content/GetInvestmentChoiceNotice':
        /* Sample argument
          no argument required
        */
        // method = 'mock';
        break;

      case '/Content/GetLatestNews':
        /* Sample argument
          no argument required
        */
        // method = 'mock';
        break;

      case '/PolicyInfoContent/GetProducts':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/Content/GetFinexURL':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/Content/GetMorningStarURL':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
      break;

      case '/Content/GetBanksList':
        /* Sample argument
          no argument required
        */
        break;

      case '/Content/GetCurrencyList':
        /* Sample argument
          no argument required
        */
        break;

      // Content Viewer - PolicyContent
      case '/PolicyInfoContent/GetMyPolicies':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyExtraAllocationDetailForAspireOrAspiration':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetTransactionRecordsForPreviousTwelveMonths':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/Content/GetFileContent':
        axios_param['params']['sessionKey'] = this.session_key;
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetMyWithdrawalHistory':
        /* Sample argument
          { policyNo: '13998888', startDate: '2020/01/18', endDate: '2020/01/20' }
        */
        break;

      case '/PolicyInfoContent/GetMyFundDividendHistory':
        /* Sample argument
          { policyNo: '13998888', startDate: '2020/01/18', endDate: '2020/01/20' }
        */
        break;

      case '/PolicyInfoContent/GetPendingTransactions':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyEnquiryList':
        /* Sample argument
          { brokerNo: '123456', productCode: 'BAC', isInForce: true, isOverdue: true, isPolicyDue: true, isPending: true }
        */
        break;

      case '/PolicyInfoContent/GetPolicyAutoEnrollmentList':
        /* Sample argument
          { policyNo: '13998888' }
        */
        break;

      case '/PolicyInfoContent/GetSwitchInOrRedirectionPolicies':
        /* Sample argument
          { listType: 'SW' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicySwitchInFundManagerList':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicySwitchInFundList':
        /* Sample argument
          { policyNo: '16881488', fundHouseCode: 'CA' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/Content/GetFundInfo':
        /* Sample argument
          { policyNo: '16881488', fundHouseCode: 'CA' }
        */
        axios_param['params']['sessionKey'] = this.session_key;
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyRedirectionFundManagerList':
        /* Sample argument
          { policyNo: '16881488' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyRedirectionFundList':
        /* Sample argument
          { policyNo: '16881488', fundHouseCode: 'CA' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/ValidatePolicyExpired':
        /* Sample argument
          { policyNo: '16881488' }
        */
        break;

      case '/PolicyInfoContent/GetPolicyEstatementList':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['clientNo'] = this.clientNo;
        axios_param['params']['username'] = this.username;
        // method = 'mock';
        break;

      case '/PolicyInfoContent/GetPolicyCoverageFundAllocationList':
        /* Sample argument
          { policyNo: '16881488', coverageNo: '01' }
        */
        axios_param['params']['username'] = this.username;
        // method = 'mock';
        break;

      case '/PolicyInfoContent/GetPolicyPortfolioSummary':
        /* Sample argument
          { policyNo: '13998888' }
        */
        axios_param['params']['username'] = this.username;
        break;


      // Fixed Bug #339
      case '/PolicyInfoContent/GetPolicyCashAccountBalanceList':
        /* Sample argument
          { policyNo: '13998888', coverageNo: '01', accountType: 'I' }
        */
        axios_param['params']['username'] = this.username;
        break;

      // Fixed Bug #391
      case '/PolicyInfoContent/GetPendingSwitchOutTransactions':
        /* Sample argument
          { policyNo: '13998888', coverageNo: '01', accountType: 'I' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicySwitchOutList':
        /* Sample argument
          { policyNo: '13998888', coverageNo: '01', accountType: 'I' }
        */
        axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyCoverageBalanceListForCashAccountAndNonCashAccount':
        /* Sample argument
          { policyNo: '13998888', coverageNo: '01', accountType: 'I' }
        */
        axios_param['params']['username'] = this.username;
        break;


      case '/PolicyInfoContent/GetPolicyCoverageBalanceList':
        /* Sample argument
          { policyNo: '16881488', coverageNo: '01', accountType: 'I'}
        */
        axios_param['params']['username'] = this.username;
        // method = 'mock';
        break;

      case '/PolicyInfoContent/GeneratePDF':
          axios_param['params']['sessionKey'] = this.session_key;
          axios_param['params']['username'] = this.username;
        break;

      case '/PolicyInfoContent/GetPolicyIncomeFundDividendAccountSummaryByPolicyNo':
        axios_param['params']['username'] = this.username;
        break;
      // Transactions - ChannelTransactions
      case '/ChannelTransactions/UpdateBrokerOrTRStatus':
        /* Sample argument
          no argument required
        */
        method = 'put';
        break;

      // Transactions - FundTransactions
      case '/FundTransactions/ValidateFundList':
        /* Sample argument
          { policyNo: '16881488', fundList: 'CAAU,CAEU' }
        */
        break;

      case '/FundTransactions/PreSwitchingAction':
        /* Sample argument
          { policyNo: '16881488', fundList: 'CAAU,CAEU' }
        */
        axios_param['params']['deviceType'] = this.device_type;
        axios_param['params']['username'] = this.username;
        break;

      case '/FundTransactions/PreRedirectionAction':
        /* Sample argument
          { policyNo: '16881488', fundList: 'CAAU,CAEU' }
        */
        axios_param['params']['deviceType'] = this.device_type;
        axios_param['params']['username'] = this.username;
        break;

      case '/FundTransactions/SwitchingAction':
        /* Sample argument
          { accountNo: '01', subAccountNo: 'I', transactionNo: '<get from /FundTransactions/PreSwitchingAction>', switchOutList: 'CAAU;50;,CAEU;50;', switchInList: 'CAAU;50;,CAEU;50;' }
        */
        // axios_param['params']['deviceType'] = this.device_type;
        axios_param['params']['username'] = this.username;
        method = 'post';
        break;

      case '/FundTransactions/RedirectionAction':
        /* Sample argument
          { accountNo: '01', subAccountNo: 'I', transactionNo: '<get from /FundTransactions/PreRedirectionAction>', fundList: 'CAAU;50;,CAEU;50;' }
        */
        // axios_param['params']['deviceType'] = this.device_type;
        axios_param['params']['username'] = this.username;
        method = 'post';
        break;

      case '/FundTransactions/DeleteSessionTransactionAsync':
        /* Sample argument
          { policyNo: '16881488' }
        */
        axios_param['params']['username'] = this.username;
        method = 'delete';
        break;

      // Transactions - GeneralTransactions
      case '/GeneralTransactions/SendContactForm':
        /* Sample argument
          { directContactType: 'CS', name: 'Chan Minf', contactNo: '99990000', emailAddress: 'aa@aa.com', policyNo: '123456', message: 'Hi', regionCode: 'HK' }
        */
        axios_param['params']['regionCode'] = 'HK';
        method = 'post';
        break;

      // Transactions - SystemTransactions
      case '/SystemTransactions/InsertUserAccessLog':
        /* Sample argument
          { moduleID: '1234', subModuleId: '1234', functionID: '1234', accessRight: 'no right' }
        */
        method = 'post';
        axios_param['params']['userName'] = this.username;
        break;

      case '/SystemTransactions/UpdateEStatementReadStatus':
        /* Sample argument
          { eStatementIndexNo: '1234' }
        */
        axios_param['params']['userName'] = this.username;
        method = 'put';
        break;

      // Transactions - UserTransactions
      case '/UserTransactions/RequestGoGreen':
        method = 'put';
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      // Transactions - UserTransactions
      case '/UserTransactions/CreateSubAccount':
        // method = 'put';
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/UserTransactions/UpdatePersonalInformation':
        /* Sample argument
          { address1: 'HK', address2: 'Tai Po', address3: 'Room384', address4: 'Unit 21', homeNo: '12345678', officeNo: '12345678', mobileNo: '12345678', emailAddress: 'aa@aa.com' }
        */
        method = 'put';
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/UserTransactions/UpdatePassword':
        /* Sample argument
          { oldVal: '1234', newVal: '2345', confirmNewVal: '2345' }
        */
        method = 'put';
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/UserTransactions/ResetPassword':
        /* Sample argument
          { oldVal: '1234', newVal: '2345', confirmNewVal: '2345' }
        */
        method = 'put';
        break;

      case '/UserTransactions/ResetPasswordTokenValidation':
        /* Sample argument
          { userName: 'IATest381', token: '4A6FD220931AE8AA8D74C0E91ED677146484DF94F78334FFDCB3102B7CA98C7F' }
        */
        method = 'put';
        break;

      case '/UserTransactions/SignUp_ValidatePolicyNo':
        /* Sample argument
          { policyNo: '16881488' }
        */
        break;

      // case '/UserTransactions/SignUp_AutoEnrollment_ValidatePersonalInfo_1':
      //   /* Sample argument
      //     { policyNo: '1234', idNo: 'A1234', dateOfBirth: '1973/07/07', activationKey: '1234' }
      //   */
      //   break;


      case '/UserTransactions/SignUp_Original_ValidatePersonalInfo':
        /* Sample argument
          { policyNo: '1234', idNo: 'A1234', dateOfBirth: '1973/07/07', userName: 'AA', emailAddress: 'aa@aa.com' }
        */
        break;

      case '/UserTransactions/SignUp_AutoEnrollment_ValidatePersonalInfo_1':
        /* Sample argument
          { policyNo: '1234', idNo: 'A1234', dateOfBirth: '1973/07/07', activationKey: '1234' }
        */
        break;

      case '/UserTransactions/SignUp_AutoEnrollment_ValidatePersonalInfo_2':
        /* Sample argument
         { idNo: 'A1234', dateOfBirth: '1973/07/07', activationKey: '1234', userName: 'aa', newVal: '1234', confirmNewVal: '1234', emailAddress: 'aa@aa.com', policyNo: '0000000' }
       */
        break;

      case '/UserTransactions/SignUp_Original_Accept':
        /* Sample argument
          { policyNo: '1234', idNo: 'A1234', dateOfBirth: '1973/07/07', userName: 'aa', emailAddress: 'aa@aa.com' }
        */
        break;

      case '/UserTransactions/SignUp_AutoEnrollment_Accept':
        /* Sample argument
          { policyNo: '1234', idNo: 'A1234', dateOfBirth: '1973/07/07', userName: 'aa', emailAddress: 'aa@aa.com', password: '1234' }
        */
        break;

      case '/UserTransactions/ForgotPassword':
        /* Sample argument
          { dateOfBirth: '12/12/2012', idNo: 'A1234', username: 'testcust_be1' }
        */
        method = 'put';
        axios_param['params']['IPAdd'] = this.ipAddress;
        break;

      case '/UserTransactions/ForgotUserID':
        /* Sample argument
          { dateOfBirth: '12/12/2012', idNo: 'A1234', emailAdd: 'perry_cheung@standardlife.hk'}
        */
        method = 'put';
        axios_param['params']['IPAdd'] = this.ipAddress;
        break;

      case '/UserTransactions/UnlockAccount':
        /* Sample argument
          { dateOfBirth: '12/12/2012', idNo: 'A1234', emailAdd: 'perry_cheung@standardlife.hk'}
        */
        method = 'put';
        axios_param['params']['IPAdd'] = this.ipAddress;
        break;

      case '/UserTransactions/UpdateRiskProfile':
        /* Sample argument
          { policyNo: '1234', questAndAnsList: '1,A;2,B' }
        */
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        // axios_param['params']['isAppliedToAllPolicies'] = false;
        method = 'get';
        break;

      case '/UserTransactions/CreateOrUpdateBankInfo':
        /* Sample argument
          { policyNo: '1234', bankBranchNo: 'HSBC', bankAccountNo: '004-123-123123456', bankAccountName: 'Chan Tai Man', updateTime: '2020/11/05 10:10:27.000' }
        */
        method = 'put';
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/UserTransactions/UpdatePolicyProvisionAcceptance':
        /* Sample argument
          { isAppliedtoAll: false, policyNo: '1234', isAccepted: false }
        */
        method = 'get';
        axios_param['params']['userName'] = this.username;
        axios_param['params']['sessionKey'] = this.session_key;
        break;

      case '/PolicyInfoContent/GetTop5FundFlowSubscription':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        // axios_param['params']['clientNo'] = this.clientNo;
        break;

      case '/PolicyInfoContent/GetTop5FundFlowRedemption':
        /* Sample argument
          no argument required
        */
        axios_param['params']['username'] = this.username;
        // axios_param['params']['clientNo'] = this.clientNo;
        break;
    }
    if (method === 'get') {
      return axios.get(url, axios_param);
    } else if (method === 'post') {
      return axios.post(url, null, axios_param);
    } else if (method === 'put') {
      return axios.put(url, null, axios_param);
    } else if (method === 'delete') {
      return axios.delete(url, axios_param);
    } else if (method === 'mock') {
      const basepath = './assets/data/stub';
      const fileNameSplitArray = url.split('/');
      const fileName = fileNameSplitArray[fileNameSplitArray.length - 1];
      return axios.get(`${basepath}/${fileName}.json`);
    }
  }

  async apiCall(endpoints, arg?) {
    let pleaseWaitMessage = 'Please wait...';
    this.translate.get(['GEN_01']).subscribe(i18n => {
      pleaseWaitMessage = i18n.GEN_01;
    });
    const loader = await this.loadingController.create({
      message: pleaseWaitMessage
    });
    const promises = [];
    if (!endpoints.includes('/PolicyInfoContent/GetPolicyEstatementList')) {
      await loader.present();
    }
    return new Promise((resolve, reject) => {
      endpoints.forEach((endpoint, i) => {
        const argument = arg ? arg[i] : null;
        promises.push(this.backendCall(endpoint, argument));
      });
      Promise.all(promises).then((res) => {
        loader.dismiss();
        resolve(res);
      }).catch(err => {
        loader.dismiss();
        reject(err);
      });
    });
  }

}
