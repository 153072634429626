import * as ia01 from '../actions/ia01.actions';

export interface State {
  ia01: any;
}

export const initialState = {
  // 'rpq_form_input': {
  //   'IA02_06': '',
  //   'IA02_11': '',
  //   'IA02_17': [],
  //   'IA02_24': '',
  //   'IA02_30': '',
  //   'IA02_36': '',
  //   'IA02_42': [],
  //   'IA02_46': '',
  //   'IA02_52': '',
  //   'IA02_59': '',
  //   'IA02_62': '',
  //   'IA02_67': ''
  // }
  'policy_no': ''
};

export function reducer(state = initialState, action: ia01.Actions) {
  switch (action.type) {
    case ia01.GO_TO_PAGE_IA01: {
      const payload = action.payload;
      return Object.assign({}, state, { 'policy_no': payload.policy_no });
      // return Object.assign({}, state, {
      //     'rpq_form_input': {
      //         'IA02_06': payload.ageGroup,
      //         'IA02_11': payload.investmentExperience,
      //         'IA02_17': payload.investedProducts,
      //         'IA02_24': payload.derivativesKnowledge,
      //         'IA02_30': payload.investmentTenor,
      //         'IA02_36': payload.investmentObjective,
      //         'IA02_42': payload.agreement,
      //         'IA02_46': payload.investmentAttitude,
      //         'IA02_52': payload.priceFluctuation,
      //         'IA02_59': payload.netWorth,
      //         'IA02_62': payload.reserve,
      //         'IA02_67': payload.futureInvestments
      //     }
      // });
    }

    default:
      return state;
  }
}
