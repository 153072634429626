import { Action } from '@ngrx/store';

export const CHANGE_POLICY = '[la01 Component] ChangePolicy';
export const GO_TO_PAGE_LA01 = '[Navigation Component] GoToPageLA01 ';

export class GoToPageLA01 implements Action {
    readonly type = GO_TO_PAGE_LA01;
    constructor(public payload: any) { }
}

export class SetLA01Policy implements Action {
    readonly type = CHANGE_POLICY;
    constructor(public payload: any) { }
}

export type Actions =
    GoToPageLA01 |
    SetLA01Policy;
