import * as page from '../actions/i01.actions';
import * as moment from 'moment';

export interface State {
    i01: any;
}

export const initialState = [{
    'I01_04': '13888888',
    'I01_05': '23/01/2020',
    'I01_06': 'No Subject',
    'I01_07': 'Read'
}];

function modifyDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function reducer(state = initialState, action: page.Actions) {
    switch (action.type) {
        case page.GO_TO_PAGE_I01: {
            const payload = action.payload;
            const result = [];
            payload.forEach(e => {
                result.push({
                    'I01_04': e.policyNo,
                    'I01_05': modifyDate(e.createdDate),
                    'I01_06': e.letterType,
                    'I01_07': e.readStatus,
                    'indexNo': e.indexNo,
                    'filePath': e.fileName
                });
            });
            return Object.assign({}, state, [result]);
        }

        default:
            return state;
    }
}
