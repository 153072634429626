import * as b01 from '../actions/b01.actions';

export interface State {
  b01: any;
}

export const initialState = {
  b01_info: {
  },
  b02_info: {
  },
  b02a_info: {
  },
  b02b_info: {
  },
};

export function reducer(state = initialState, action: b01.Actions) {
  switch (action.type) {
    case b01.STORE_B01_INFO: {
      const payload = action.payload;
      return Object.assign({}, state, {
        b01_info: payload
      });
    }

    case b01.STORE_B02_INFO: {
      const payload = action.payload;
      return Object.assign({}, state, {
        b02_info: payload
      });
    }

    case b01.STORE_B02A_INFO: {
      const payload = action.payload;
      return Object.assign({}, state, {
        b02a_info: payload
      });
    }

    case b01.STORE_B02B_INFO: {
      const payload = action.payload;
      return Object.assign({}, state, {
        b02b_info: payload
      });
    }


    default:
      return state;
  }
}
