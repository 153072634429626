import * as f01 from '../actions/f01.actions';
import { convertCurrency } from '../utils';

export interface State {
  f01: any;
}
export const initialState = {
  'policy_information': [
    {
      // 'F01_03': '13888888',
      // 'F01_04': 'Wealth Amplifier Investment Plan',
      // 'F01_05': '30,000.00',
      // 'F01_06': 'Single',
      // 'F01_07': 'HKD',
      // 'F01_08': '32,974.08',
      // 'content': {
      //     'content_details': [
      //         {
      //             'F01_12': 'Aberdeen Standard SICAV I - North American Equity Fund - A Acc USD',
      //             'F01_13': '03/01/2020',
      //             'F01_14': 'USD',
      //             'F01_15': '167.26200',
      //             'F01_16': '25.274',
      //             'F01_17': '4,227.45',
      //             'F01_18': '32,974.08',
      //             'allocation': '100%'
      //         }
      //     ],
      //     'content_summary': {
      //         'currency': 'HKD',
      //         'total_amount': '32,974.08'
      //     }
      // }
    }
  ]
};
//  Takes an amount and returns the percentage in "INT_VALUE%" format.
function convertPercentage(amount, sum) {
  return Math.round(amount * 100.0 / sum) + '%';
}

export function reducer(state = initialState, action: f01.Actions) {
  switch (action.type) {
    case f01.GO_TO_PAGE_F01: {
      const payload = action.payload;
      const result = [];
      payload[0].policy.forEach(e => {
        // console.log('f01 policy', e);
        if (e.policyInfoView.isMyWealthFlag === 'Y') {
          result.push({
            'F01_03': e.policyInfoView.policyNo + e.policyInfoView.policySymbol,
            'F01_03a': e.policyInfoView.policyNo,
            'F01_04': e.policyInfoView.productName,
            'F01_05': convertCurrency(e.policyInfoView.totalModelPremium),
            'F01_06': e.policyInfoView.policyPaymentMode,
            'F01_07': e.policyInfoView.policyCurrency,
            'F01_08': convertCurrency(e.policyInfoView.policyValueInPolicyCurrency) + e.policyInfoView.policyValueSymbol,
            'F01_TERM_PRODUCT': e.policyInfoView.isTermProductFlag,
            'IS_JOINT': e.policyInfoView.isJointOwnerPolicy
          });
        }
      });
      return Object.assign({}, state, { 'policy_information': result });
    }

    case f01.SET_F01_CHART_AND_TABLE_DATA: {
      //  We only pass in tableData here. Chart data is not used
      action.payload.tableData.forEach((data, index) => {
        const policy_currency = state.policy_information[index]['F01_07'];
        const tableData = transformTableData(data, policy_currency);
        const chartData = transformChartData(data);
        // console.log(tableData, chartData);
        state.policy_information[index]['tableData'] = tableData;
        state.policy_information[index]['chartData'] = chartData;
        // const
      });
      // const chartData = transformChartData(action.payload.chartData);
      // const chartTableData = transformChartTableData(action.payload.chartData, state.f02_page_content['payment_information']['F02_24']);
      return Object.assign({}, state, {
        ...state
      });
    }

    case f01.SET_SELECTED_POLICY_NO: {
      const policy_no = action.payload;
      return Object.assign({}, state, {
        ...state,
        selected_policy_no: policy_no
      });
    }

    default:
      return state;
  }
}

function transformTableData(tableData, currency) {
  if ((!tableData.nonCashAccount || tableData.nonCashAccount.length === 0) && (!tableData.cashAccount || tableData.cashAccount.length === 0)) {
    return [];
  }

  const transformedTableData = {
    tableDataNonCash: [],
    tableDataCash: [],
    tableSummary: {}
  };

  const rowToTableData = (row) => {
    return {
      F01_12: row['fundName'] + row['fundSymbol'],
      F01_13: row['unitPriceDate'],
      F01_14: row['fundCurrency'],
      F01_15: row['unitBalance'].toFixed(5),
      F01_16: convertCurrency(row['unitPrice'], 3),
      F01_17: convertCurrency(row['amountInFundCurr'], 2),
      F01_18: convertCurrency(row['amountInPolicyCurr'], 2)
    };
  };

  if (tableData.nonCashAccount) {
    transformedTableData['tableDataNonCash'] = tableData.nonCashAccount.map(row => {
      return rowToTableData(row);
    });
  }
  if (tableData.cashAccount) {
    transformedTableData['tableDataCash'] = tableData.cashAccount.map(row => {
      return rowToTableData(row);
    });
  }

  transformedTableData['tableSummary'] = {
    currency: currency,
    total_amount_noncash: tableData.nonCashAccountTotalAmtInPolicyCurr,
    total_amount_cash: tableData.cashAccountTotalAmtInPolicyCurr
  };
  return transformedTableData;
}

function transformChartData(chartData) {
  if ((!chartData.nonCashAccount || chartData.nonCashAccount.length === 0) && (!chartData.cashAccount || chartData.cashAccount.length === 0)) {
    return [];
  }

  const rowToChartData = (row) => {
    return {
      fund_name: row['fundName'] + row['fundSymbol'],
      fund_code: row['fundCode'],
      weight: `${row['weight']}`,
      amount: row['amountInPolicyCurr']
    };
  };

  const transformedChartData = [];
  if (chartData.nonCashAccount) {
    chartData.nonCashAccount.forEach(pie => {
      transformedChartData.push(rowToChartData(pie));
    });
  }
  if (chartData.cashAccount) {
    chartData.cashAccount.forEach(pie => {
      transformedChartData.push(rowToChartData(pie));
    });
  }
  return transformedChartData;
}
