import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NavigationService } from '../../providers/navigation.service';

@Component({
  selector: 'complex-table',
  templateUrl: './complex-table.component.html',
  styleUrls: ['./complex-table.component.scss']
})
export class ComplexTableComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input() tableSummary = [];
  // tslint:disable-next-line:no-input-rename
  @Input() tableData = { title: '', subtitles: [], rightColumn: '', data: [], columnsOptions: [], filterDetailOptions: [] };
  // tslint:disable-next-line:no-input-rename
  @Input() clickAction;

  @Input() clickActionOpenPage;

  @Input() clickActionOpenRow;

  @Input() hasGoToFundDetailButton = false;

  @Input() fundCodeLabel = '';

  rightColumn: String = '';
  public wideTable = true;
  private colWidth = '';
  private allColumns = [];
  private summary = [];

  constructor(
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {
    if (!this.tableData.columnsOptions) {
      this.tableData.columnsOptions = this.tableData.data && this.tableData.data.length > 0 ? Object.keys(this.tableData.data[0]) : [];
    }
    this.rightColumn = this.tableData.rightColumn;

    this.allColumns = this.tableData.columnsOptions.filter(o => {
      return !this.tableData.filterDetailOptions || this.tableData.filterDetailOptions.indexOf(o) === -1;
    });

    this.wideTable = window.innerWidth >= 992;

    if (this.tableSummary !== []) {
      this.tableSummary.forEach(s => {
        for (const key in s) {
          if (s.hasOwnProperty(key)) {
            this.summary.push({ key: key, val: s[key] });
          }
        }
      });
    }

    if (this.allColumns.length > 6) {
      this.colWidth = 'table-size-' + (13 - this.allColumns.length);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.wideTable = window.innerWidth >= 992;
  }

  async openRowDetail(row) {
    console.log(this.tableData.filterDetailOptions);
    this.navigationService.navigateByUrl('/complex-table-row-detail', 'complex-table-row-detail', {
      tableData: this.tableData,
      data: row,
      filterDetailOptions: this.tableData.filterDetailOptions ? this.tableData.filterDetailOptions : [],
      summary: this.tableSummary,
      hasGoToFundDetailButton: this.hasGoToFundDetailButton,
      fundCode: row[this.fundCodeLabel]
    });
  }

  handleClick(row, index) {
    if (this.clickAction) {
      this.clickAction(index);
    } else if (this.clickActionOpenPage) {
      this.clickActionOpenPage(row);
    } else if (this.clickActionOpenRow) {
      this.clickActionOpenRow(index);
      this.openRowDetail(row);
    } else {
      this.openRowDetail(row);
    }
  }

}
