import * as page from '../actions/m03.actions';

export interface State {
  m03: any;
}

export const initialState = [{
    'M03_03': '11/12/2015',
    'M03_04': 'Change to depository of Threadneedle funds',
    'M03_05': 'path'
}];

export function reducer(state = initialState, action: page.Actions) {
  switch (action.type) {
    case page.GO_TO_PAGE_M03: {
      const payload = action.payload;
      const result = [];

      payload.latestNewsList.forEach(e => {
          result.push({
              'M03_03': e.issueDate,
              'M03_04': e.summary,
              'M03_05': e.fileDownloadPath
          });
      });
      return Object.assign({}, state, [result]);
    }

    default:
      return state;
  }
}
