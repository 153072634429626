import * as page from '../actions/m02.actions';

export interface State {
  m02: any;
}

export const initialState = [{
    'M02_03': '11/12/2015',
    'M02_04': 'Change to depository of Threadneedle funds',
    'M02_05': 'path',
    'M02_06': '01/01/2016'
}];

export function reducer(state = initialState, action: page.Actions) {
  switch (action.type) {
    case page.GO_TO_PAGE_M02: {
      const payload = action.payload;
      const result = [];

      payload.investmentChoiceNoticeList.forEach(e => {
          result.push({
              'M02_03': e.issueDate,
              'M02_04': e.summary,
              'M02_05': e.fileDownloadPath,
              'M02_06': e.effectiveDate
          });
      });
      return Object.assign({}, state, [result]);
    }

    default:
      return state;
  }
}
