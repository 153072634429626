import * as navigations from '../actions/navigation.actions';

export interface State {
  currentPage: '';
  previousPage: '';
  // Issue 691
  IsFromLA01ForIA01Flag: false;
  IsFromG01BForIA01Flag: false;
  IsFromH01AForIA01Flag: false;
  transactionStatus: boolean;
}

export const initialState = {
    currentPage: 'a01',
    previousPage: '',
    IsFromLA01ForIA01Flag: false,
    IsFromG01BForIA01Flag: false,
    IsFromH01AForIA01Flag: false,
    transactionStatus: false
};

export function reducer(state = initialState, action: navigations.Actions) {
  switch (action.type) {
    case navigations.SET_NAVIGATION_PAGE: {
      const navigationPayload: any = action.payload;
      const transactionStatus =
        !navigationPayload.currentPage ||
        navigationPayload.currentPage.charAt(0) === 'g' ||
        navigationPayload.currentPage.charAt(0) === 'h';
      console.log(state, action.payload, {
        ...state,
        ...navigationPayload,
        transactionStatus: transactionStatus
      });
      return Object.assign({}, state, {
        ...state,
        ...navigationPayload,
        transactionStatus: transactionStatus
      });
    }

    case navigations.SET_TRANSACTION_STATUS: {
      //  Not used for now.
      return Object.assign({}, state, {
        ...state,
        transactionStatus: action.payload
      });
    }

    default:
      return state;
  }
}
