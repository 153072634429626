import * as ia02c from '../actions/ia02c.actions';

export interface State {
  ia02c: any;
}

export const initialState = {
    'eRPQ_section3': {
      'IA02c_02': ''
    }
};

export function reducer(state = initialState, action: ia02c.Actions) {
  switch (action.type) {
    case ia02c.GO_TO_PAGE_IA02C: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'eRPQ_section3': {
            'IA02c_02': payload.investmentObjective
          }
      });
    }

    default:
      return state;
  }
}
