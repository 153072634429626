import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Events, MenuController, Platform, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Store, State } from '@ngrx/store';
import { SetLanguage } from './providers/actions/setting.actions';
import { TranslateService } from '@ngx-translate/core';
import { UserData } from './providers/user-data';
import { SetUser } from './providers/actions/user.actions';
import { SetNavigationPage } from './providers/actions/navigation.actions';
import { ResetAllState } from './providers/actions/index.actions';
import { NavigationService } from './providers/navigation.service';
import { PageService } from './providers/page.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from '../environments/environment';
import { PasswordEncryptionService } from './providers/password-encryption.service';

declare const gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  pages: any = [
    {
      title: 'E01_07',
      children: [
        {
          title: 'E01_07',
          url: 'f01-my-wealth',
          icon: 'alert',
          pageid: 'f01'
        },
        {
          title: 'E01_08',
          url: '/app/tabs/f02-financial-summary',
          icon: 'list',
          pageid: 'f02'
        },
        {
          title: 'E01_09',
          url: '/g04-risk-warning',
          icon: 'build',
          pageid: 'g04',
          redir: '/g01-switching'
        },
        {
          title: 'E01_10',
          url: '/g04-risk-warning',
          icon: 'construct',
          pageid: 'g04',
          redir: '/h01-redirection'
        },
        // {
        //   title: 'E01_11',
        //   url: '/i01-my-documents',
        //   icon: 'document',
        //   pageid: 'i01'
        // }
      ]
    },
    {
      title: 'E01_12',
      children: [
        {
          title: 'E01_12',
          url: 'j01-my-protection',
          icon: 'alert',
          pageid: 'j01'
        },
        {
          title: 'E01_13',
          url: '/app/tabs/f02-financial-summary',
          icon: 'list',
          pageid: 'j02'
        },
        // {
        //   title: 'E01_14',
        //   url: '/i01-my-documents',
        //   icon: 'document',
        //   pageid: 'i01'
        // }
      ]
    },
    {
      /* My Profile */
      title: 'E01_15',
      children: [
        {
          title: 'E01_16',
          url: '/k02-personal-information',
          icon: 'information',
          pageid: 'k02'
        },
        {
          title: 'E01_17',
          url: '/k03-change-personal-information',
          icon: 'build',
          pageid: 'k03'
        },
        {
          title: 'E01_18',
          url: '/k04-change-password',
          icon: 'lock',
          pageid: 'k04'
        },
        {
          title: 'E01_29',
          url: '/k05-change-bank-account',
          icon: 'build',
          pageid: 'k05'
        }
      ]
    },
    {
      title: 'E01_19',
      children: [
        {
          title: 'E01_20',
          url: '/l02-service-centre',
          icon: 'alert',
          pageid: 'l02'
        },
        {
          title: 'E01_21',
          url: '/l03-service-forms',
          icon: 'paper',
          pageid: 'l03'
        },
        {
          title: 'E01_22',
          // url: '/l04-payment-methods',
          url: 'l04',
          icon: 'document',
          pageid: 'l04'
        }
      ]
    },
    {
      title: 'LA01_01',
      url: '/la01-risk-profile',
      icon: 'document',
      pageid: 'la01'
    },
    {
      title: 'E01_23',
      children: [
        {
          title: 'E01_24',
          url: 'm02',
          icon: 'alert',
          pageid: 'm02'
        },
        // {
        //   title: 'E01_25',
        //   url: '/m03-notice-latest-news',
        //   icon: 'paper',
        //   pageid: 'm03'
        // },
        // {
        //   title: 'E01_26',
        //   url: '/i01-my-documents',
        //   icon: 'document',
        //   pageid: 'i01'
        // }
      ]
    },
    {
      title: 'E01_26',
      url: '/i01-my-documents',
      icon: 'document',
      pageid: 'i01'
    },
    // {
    //   title: 'E01_28',
    //   url: '/r01-top5-inflow-outflow',
    //   icon: 'analytics',
    //   pageid: 'r01'
    // },
    {
      title: 'E01_27',
      url: '/q01-Important-Information-Before-Investment',
      icon: 'globe',
      pageid: 'q01'
    },
    // Hide Video Pages
    // {
    //   title: 'IB01_01',
    //   url: '/ib01-movies-list',
    //   icon: 'document',
    //   pageid: 'ib01'
    // }
  ];
  appPages = [
    // {
    //   title: 'A01_11',
    //   url: '/a01-login-page',
    //   icon: 'log-in'
    // },
    // {
    //   title: 'A01_15',
    //   url: '/a01-login-page',
    //   icon: 'person-add'
    // },
    {
      title: 'N01_03',
      url: '/n02-contact-us',
      icon: 'contacts',
      pageid: 'n02'
    },
    {
      title: 'N01_04',
      url: '/n03-legal-and-privacy-statement',
      icon: 'paper',
      pageid: 'n03'
    },
    // {
    //   title: 'N01_05',
    //   url: '/n04-site-map',
    //   icon: 'navigate'
    // }
  ];
  greeting = String;
  loggedIn = false;
  currentPage: any;
  SWITCH_LANGUAGE_PROMPT_PAGES = ['h01', 'h02', 'h03', 'g01', 'g02', 'g03', 'h01a', 'g01a', 'g01b'];
  f01SubscribeStore: any;

  allowedPages = [];
  isTrustPolicySubAccountFlag = false;
  isTrustPolicyMainAccountFlag = false;

  @HostListener('window:unload', ['$event'])
  logoutOnUnload($event) {
    this.sendLogoutBeacon();
  }

  @HostListener('window:beforeunload', ['$event'])
  logoutBeforeUnload($event) {
    $event.preventDefault();
    $event.returnValue = '';
  }

  constructor(
    private events: Events,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public store: Store<any>,
    private state: State<any>,
    private userData: UserData,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private alertCtrl: AlertController,
    private pageService: PageService,
    private iab: InAppBrowser,
    private passwordService: PasswordEncryptionService
  ) {
    this.initializeApp();
    this.initTranslate();
  }

  ngOnInit() {
    this.store.select('user').subscribe(data => {
      this.loggedIn = data.isLoggedIn;
      this.greeting = data.greeting;
      if (!data.startWithMyWealthFlag) {

        // a. Uncomment below to disable my Wealth
        this.pages = this.pages.filter(e => e.title !== 'E01_07');

        // b. Uncomment below to disable PolicySummary under my Wealth
        // this.pages.forEach(section => {
        //   if (section.children) {
        //     section.children = section.children.filter(e => e['pageid'] !== 'f02');
        //   }
        // });

      }
    });
    this.store.select('navigation').subscribe(data => {
      this.currentPage = data.currentPage;
    });

    // console.log(this.state.getValue().user.username);

    this.store.select('user').subscribe(user => {
      this.allowedPages = user.pagesAllowed.map(e => e['pageID'] ? e['pageID'].toLowerCase() : null);
      console.log(this.allowedPages);
      console.log(this.pages);
      // console.log(user);
      // console.log(user.username);
      // console.log(user.isTrustPolicySubAccountFlag);

      this.isTrustPolicySubAccountFlag = user.isTrustPolicySubAccountFlag;
      this.isTrustPolicyMainAccountFlag = user.isTrustPolicyMainAccountFlag;

      console.log('PolicySubAccount: ', this.isTrustPolicySubAccountFlag);
      console.log('PolicyMainAccount: ', this.isTrustPolicyMainAccountFlag);

      if (this.isTrustPolicySubAccountFlag) {
        // if (this.allowedPages.length > 0) {
        //   this.pages.forEach(section => {
        //     if (section.children) {
        //       section.children = section.children.filter(e => this.allowedPages.some(page => page && page.includes(e['pageid'])));
        //     }
        //   });
        // }
        this.pages = [
          {
              'title': 'E01_07',
              'children': [
                  {
                      'title': 'E01_07',
                      'url': 'f01-my-wealth',
                      'icon': 'alert',
                      'pageid': 'f01'
                  },
                  {
                      'title': 'E01_08',
                      'url': '/app/tabs/f02-financial-summary',
                      'icon': 'list',
                      'pageid': 'f02'
                  },
                  {
                      'title': 'E01_09',
                      'url': '/g04-risk-warning',
                      'icon': 'build',
                      'pageid': 'g04',
                      'redir': '/g01-switching'
                  }
              ]
          },
          {
              'title': 'E01_12',
              'children': [
                  {
                      'title': 'E01_12',
                      'url': 'j01-my-protection',
                      'icon': 'alert',
                      'pageid': 'j01'
                  },
                  {
                      'title': 'E01_13',
                      'url': '/app/tabs/f02-financial-summary',
                      'icon': 'list',
                      'pageid': 'j02'
                  }
              ]
          },
          {
              'title': 'E01_15',
              'children': [
                  {
                      'title': 'E01_18',
                      'url': '/k04-change-password',
                      'icon': 'lock',
                      'pageid': 'k04'
                  }
              ]
          },

          {
              'title': 'LA01_01',
              'url': '/la01-risk-profile',
              'icon': 'document',
              'pageid': 'la01'
          }
      ];
        this.appPages = [];


      }

      if (this.isTrustPolicyMainAccountFlag) {
        // if (this.allowedPages.length > 0) {
        //   this.pages.forEach(section => {
        //     if (section.children) {
        //       section.children = section.children.filter(e => this.allowedPages.some(page => page && page.includes(e['pageid'])));
        //     }
        //   });
        // }
        this.pages = [
          {
            title: 'E01_07',
            children: [
              {
                title: 'E01_07',
                url: 'f01-my-wealth',
                icon: 'alert',
                pageid: 'f01'
              },
              {
                title: 'E01_08',
                url: '/app/tabs/f02-financial-summary',
                icon: 'list',
                pageid: 'f02'
              },
              {
                title: 'E01_09',
                url: '/g04-risk-warning',
                icon: 'build',
                pageid: 'g04',
                redir: '/g01-switching'
              },
              {
                title: 'E01_10',
                url: '/g04-risk-warning',
                icon: 'construct',
                pageid: 'g04',
                redir: '/h01-redirection'
              },
              // {
              //   title: 'E01_11',
              //   url: '/i01-my-documents',
              //   icon: 'document',
              //   pageid: 'i01'
              // }
            ]
          },
          {
            title: 'E01_12',
            children: [
              {
                title: 'E01_12',
                url: 'j01-my-protection',
                icon: 'alert',
                pageid: 'j01'
              },
              {
                title: 'E01_13',
                url: '/app/tabs/f02-financial-summary',
                icon: 'list',
                pageid: 'j02'
              },
              // {
              //   title: 'E01_14',
              //   url: '/i01-my-documents',
              //   icon: 'document',
              //   pageid: 'i01'
              // }
            ]
          },
          {
            /* My Profile */
            title: 'E01_15',
            children: [
              {
                title: 'E01_16',
                url: '/k02-personal-information',
                icon: 'information',
                pageid: 'k02'
              },
              {
                title: 'E01_17',
                url: '/k03-change-personal-information',
                icon: 'build',
                pageid: 'k03'
              },
              {
                title: 'E01_18',
                url: '/k04-change-password',
                icon: 'lock',
                pageid: 'k04'
              },
              {
                title: 'E01_29',
                url: '/k05-change-bank-account',
                icon: 'build',
                pageid: 'k05'
              }
            ]
          },
          {
            title: 'E01_19',
            children: [
              {
                title: 'E01_20',
                url: '/l02-service-centre',
                icon: 'alert',
                pageid: 'l02'
              },
              {
                title: 'E01_21',
                url: '/l03-service-forms',
                icon: 'paper',
                pageid: 'l03'
              },
              {
                title: 'E01_22',
                // url: '/l04-payment-methods',
                url: 'l04',
                icon: 'document',
                pageid: 'l04'
              },
              {
                title: 'E01_30',
                url: '/l05-sub-account-management',
                icon: 'contacts',
                pageid: 'l05'
              }
            ]
          },
          {
            title: 'LA01_01',
            url: '/la01-risk-profile',
            icon: 'document',
            pageid: 'la01'
          },
          {
            title: 'E01_23',
            children: [
              {
                title: 'E01_24',
                url: 'm02',
                icon: 'alert',
                pageid: 'm02'
              },
              // {
              //   title: 'E01_25',
              //   url: '/m03-notice-latest-news',
              //   icon: 'paper',
              //   pageid: 'm03'
              // },
              // {
              //   title: 'E01_26',
              //   url: '/i01-my-documents',
              //   icon: 'document',
              //   pageid: 'i01'
              // }
            ]
          },
          {
            title: 'E01_26',
            url: '/i01-my-documents',
            icon: 'document',
            pageid: 'i01'
          },
          // {
          //   title: 'E01_28',
          //   url: '/r01-top5-inflow-outflow',
          //   icon: 'analytics',
          //   pageid: 'r01'
          // },
          {
            title: 'E01_27',
            url: '/q01-Important-Information-Before-Investment',
            icon: 'globe',
            pageid: 'q01'
          },
          // Hide Video Pages
          // {
          //   title: 'IB01_01',
          //   url: '/ib01-movies-list',
          //   icon: 'document',
          //   pageid: 'ib01'
          // }
        ];

      }

      console.log(this.pages);

    });

  }

  initializeApp() {
    // Register GA to router page view change event
    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleTag, { page_path: event.url });
        console.log('nav', event.url);
      }
    });

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.platform.backButton.subscribe(() => {
        this.navigationService.back();
      });

      // Handle browser back button
      if (window.location.protocol !== 'file:') {
        window.onpopstate = (evt) => {
          this.navigationService.browserBack();
        };
      }

    });
  }

  async goToOurInvestmentChoice() {
    // const url = await this.pageService.get_finex_url();
    const url = await this.pageService.get_morningStar_screener_url();
    this.iab.create(url);
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    this.events.subscribe('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    this.events.subscribe('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    this.events.subscribe('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  // logout() {
  //   this.store.dispatch(new ResetAllState());
  //   this.userData.logout().then(() => {
  //     return this.router.navigateByUrl('/a01-login-page');
  //   });
  // }
  async logout() {
    const transaction_status = this.pageService.get_transaction_status();

    //  Perform confirmation if we want to log out.
    if (transaction_status) {
      const confirmation = confirm('Are you sure you want to log out?');
      if (!confirmation) {
        return;
      }
    }

    await this.pageService.get_logout_status();
    this.store.dispatch(new ResetAllState());
    this.store.select('user').subscribe(d => {
      if (d.greeting !== '') {
        return;
      }
      sessionStorage.clear();
      //  Clean pages open status.
      this.pages.forEach(p => {
        p.open = false;
      });

      this.userData.logout().then(() => {
        this.router.navigateByUrl('/a01-login-page');
        // return location.replace('/login/#/a01-login-page');

        // Local DEV:
        // return location.replace('/');
        // UAT/PRD:
        // return location.replace('/login/');
      });
    });
  }

  sendLogoutBeacon() {
    const username = this.state.getValue().user.username;
    const sessionKey = this.state.getValue().user.sessionKey;
    const url = environment.basepath_user_account_management + '/UserAccount/GetLogoutStatus';
    if (username !== '' && sessionKey !== '') {
      const para = '?type=web&sessionKey=' + sessionKey + '&username=' + username;
      navigator.sendBeacon(url + para, '');
    }
    // sessionStorage.clear();
  }

  // resumeSession() {
  //   const payload = {
  //     username: sessionStorage.getItem('username'),
  //     sessionKey: sessionStorage.getItem('sessionKey'),
  //     isLoggedIn: true,
  //     clientNo: sessionStorage.getItem('clientNo'),
  //     greeting: sessionStorage.getItem('greeting'),
  //     emailAddress: sessionStorage.getItem('emailAddress'),
  //   };
  //   this.store.dispatch(new SetUser(payload));
  // }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  async initTranslate() {
    this.translate.setDefaultLang('tc');
    this.translate.use('en');
    this.store.dispatch(new SetLanguage('en'));
  }

  async handleToggleLanguage() {
    if (this.SWITCH_LANGUAGE_PROMPT_PAGES.includes(this.state.getValue().navigation.currentPage) ||
      (this.SWITCH_LANGUAGE_PROMPT_PAGES.includes(this.state.getValue().navigation.previousPage) && this.state.getValue().navigation.currentPage === 'complex-table-row-detail' ||
        this.state.getValue().page.g01.g01_page_content.selected_policy !== '' ||
        this.state.getValue().page.h01.h01_page_content.selected_policy !== ''
      )
    ) {
      const alert = await this.alertCtrl.create({
        header: 'Warning',
        // TODO: i18n
        message: this.getTranslation('FDV_31'), // `If you switch language now, fund switching / redirection process will back to step 1. All unconfirmed changes will not be saved.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Confirm',
            handler: () => {
              this.toggleLanguage();
            }
          }
        ]
      });
      await alert.present();
      return;
    }
    this.toggleLanguage();
  }

  getTranslation(translateText) {
    let translated;
    this.translate.get(translateText).subscribe(res => {
      translated = res;
    });

    return translated;
  }

  toggleLanguage() {
    if (this.translate.currentLang === 'en') {
      this.store.dispatch(new SetLanguage('tc'));
      this.translate.use('tc');
    } else {
      this.store.dispatch(new SetLanguage('en'));
      this.translate.use('en');
    }
    this.events.publish('user:toggleLanguage');
  }


  setCurrentPage(url, pageid, redir) {
    if (url === 'm02') {
      this.visitPage(pageid);
    } else if (url === 'l04') {
      this.visitPage(pageid);
    } else {
      if (redir) {
        this.navigationService.navigateByUrl(url, pageid, { redir: redir });
      } else {
        this.navigationService.navigateByUrl(url, pageid);
      }
    }
  }

  visitPage(page) {
    const language = this.state.getValue().setting.language;
    let url = '';
    switch (page) {
      case 'm02':
        url = environment.publicsite_url + language + '/investment-information/notice-in-relation-to-investment-choices';
        break;
      case 'l04':
        url = environment.publicsite_url + language + '/help-and-support/payment-methods';
        break;
      default:
        return;
    }
    this.iab.create(url);
  }

  matchPage(page) {
    if (page.pageid !== 'g04') {
      return this.currentPage === page.pageid;
    } else {
      if (this.currentPage === 'g04') {
        //  Case 1 - on G04 page.
        return history.state && (history.state.redir === page.redir);
      } else {
        //  Case 2 - On G01 or H01 page check.
        return this.currentPage === page.redir.substring(1, 4);
      }
    }
  }
}
