import { Action } from '@ngrx/store';

export const GO_TO_PAGE_K02 = '[Navigation Component] GoToPageK02 ';
export const CHANGE_POLICY = '[k02 Component] ChangePolicy';

export class GoToPageK02 implements Action { readonly type = GO_TO_PAGE_K02; constructor(public payload: any) { } }

export class SetK02Policy implements Action {
  readonly type = CHANGE_POLICY;
  constructor(public payload: any) { }
}

export type Actions
  =
  GoToPageK02 |
  SetK02Policy ;
