import { commonConfig as commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,

  production: true,

  basepath_content_viewer: 'https://www.hengansl.com.hk/api',
  basepath_user_account_management: 'https://www.hengansl.com.hk/api',
  basepath_transaction: 'https://www.hengansl.com.hk/api',

  investplatform_url: 'https://sangria.aqumon.com/#/',

  googleTag: 'UA-173488825-1'
};
