import * as ia02a from '../actions/ia02a.actions';

export interface State {
  ia02a: any;
}

export const initialState = {
    'eRPQ_section1': {
      'IA02a_09': '',
      'IA02a_15': '',
      'IA02a_21': '',
      'IA02a_27': []
    }
};

export function reducer(state = initialState, action: ia02a.Actions) {
  switch (action.type) {
    case ia02a.GO_TO_PAGE_IA02A: {
      const payload = action.payload;
      return Object.assign({}, state, {
          'eRPQ_section1': {
            'IA02a_09': payload.ageGroup,
            'IA02a_15': payload.educationLevel,
            'IA02a_21': payload.investmentExperience,
            'IA02a_27': payload.investedProducts
          }
      });
    }

    default:
      return state;
  }
}
