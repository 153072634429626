import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'banner-inplus-entrance',
  templateUrl: './banner-inplus-entrance.component.html',
  styleUrls: ['./banner-inplus-entrance.component.scss']
})
export class BannerInplusEntranceComponent implements OnInit {

  /* Example
      <banner-inplus-entrance imageSource="assets/img/about/myProfile.png" title="My Profile" subtitle="Update your personal information at anytime"></banner>
  */

  // tslint:disable-next-line:no-input-rename
  @Input('imageSource')imageSource: any;
  // tslint:disable-next-line:no-input-rename
  @Input('redirectUrl')redirectUrl: any;

  constructor() { }

  ngOnInit() {
  }

}
