import { Action } from '@ngrx/store';

export const GO_TO_PAGE_F03 = '[Navigation Component] GoToPageF03 ';
export const CHANGE_POLICY = '[f03 Component] ChangePolicy';
export const RESET_F03_STATE = '[h01 Component] ResetF03State';
export class GoToPageF03 implements Action { readonly type = GO_TO_PAGE_F03; constructor(public payload: any, public currentPage: any) { } }
export class F03ChangePolicy implements Action {
  readonly type = CHANGE_POLICY;
  constructor(public payload: any) { }
}
export class ResetF03State implements Action {
  readonly type = RESET_F03_STATE;
  constructor() { }
}

export type Actions
  =
  GoToPageF03 |
  F03ChangePolicy |
  ResetF03State;
