import { Component, OnInit, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  toleranceLevels = [
    {name: '1'},
    {name: '2'},
    {name: '3'},
    {name: '4'},
    {name: '5'}
  ];

  toleranceLevelsDescription = [
    {name: 'F02_85'},
    {name: 'F02_86'},
    {name: 'F02_87'},
    {name: 'F02_88'},
    {name: 'F02_89'}
  ];

/* Example
  params: any = [
    { label: 'subtitle', content: 'Lorem ipsum dolor sit amet', class: 'no-bottom-padding' },
    { label: 'title', content: 'Lorem ipsum dolor sit amet', anchor: 'an-optional-example', target: 'scroll-to-corresponding-anchor-if-found', class: 'no-padding' },
    { label: 'content', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { label: 'download', content: 'Lorem ipsum dolor sit amet', clickAction: () => { this.action(); } },
    { label: 'download', content: 'Lorem ipsum dolor sit amet', subcontents: ['Issue Date: 2020/01/13'], clickAction: () => { this.action(); } },
    { label: 'link', content: 'Lorem ipsum dolor sit amet', clickAction: () => { this.action(); } },
    { label: 'listInfo', content: [
      {fieldLabel: 'H03_03', fieldContent: '1378888', type: 'dropdown', dropdownListOptions: ['a', 'b', 'c'], selectedValue: 'a', onChangeHandler: ()=>{action();}// optional},
      {fieldLabel: 'H03_04', fieldContent: 'Acc 3456'},
      {fieldLabel: 'H03_05', fieldContent: 'David Chan'}
    ]},
    { label: 'pieChart', content: { data: [
        [ 'Pie Chart Title', '%' ],
        [ 'Fund A', '33' ]
      ],
      sliceVisibilityThreshold: 0.03, // optional: default 0.03
      combineSliceThreshold: 20 // optional: default 20
    }},
    { label: 'complexTable', content: { title: "Fund Name", subtitles: ["Reference Code", "Unit Currency"], rightColumn: "Value in Plan", data: [
      {
        "Fund Name": "Aberdeen Standard SICAV I - North American Equity Fund - A Acc USD",
        "Policy Number": "138888888",
        "Reference Code": "01AU",
        "Unit Currency": "USD",
        "Value in Plan": "32,975",
      },
      {
        "Fund Name": "Aberdeen Standard SICAV I - North American Equity Fund - A Acc USD",
        "Policy Number": "138888888",
        "Reference Code": "01AU",
        "Unit Currency": "USD",
        "Value in Plan": "32,975",
      },
      {
        "Fund Name": "Aberdeen Standard SICAV I - North American Equity Fund - A Acc USD",
        "Policy Number": "138888888",
        "Reference Code": "01AU",
        "Unit Currency": "USD",
        "Value in Plan": "32,975",
      }
      ]},
      tableSummary: [
        {"label": "Balance", "value": "289"},
        {"label": "Balance", "value": "289"},
        {"label": "Balance", "value": "289"}
      ],
      hasGoToFundDetailButton: true, // optional, default: false
      fundCodeLabel: 'H01_13' ,// optional
      clickAction: ()=>{}
    }
  ]
*/

  // tslint:disable-next-line:no-input-rename
  @Input('cardContents')cardContents: any;

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit() {
  }

  scrollToAnchor(target) {
      //    First condition of scrolling - we have a target to scroll to, ie: target is defined.
      if (target !== undefined) {
          //    Next find the target of scrolling.
          const scrollTarget = document.querySelector('#' + target);
          if (scrollTarget) {
              scrollTarget.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }

  // Function to sanitize HTML content
  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  // Function to handle click events
  handleClick(event: MouseEvent, url: string) {
    const target = event.target as HTMLElement;
    event.preventDefault();

    // Check if the clicked element is a link
    if (target.tagName === 'A') {
      event.preventDefault(); // Prevent default anchor behavior
      // const url = target.getAttribute('href');
      // if (url) {
        this.router.navigateByUrl('/la01-risk-profile'); // Use Angular's router to navigate
      // }
    }
  }

}
