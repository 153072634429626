import { Action } from '@ngrx/store';

export const SET_USER = '[Setting Component] SetUser';

export class SetUser implements Action {
  readonly type = SET_USER;

  constructor(public payload: any) { }
}

export type Actions
  = SetUser;
