import { Action } from '@ngrx/store';

export const GO_TO_PAGE_F02 = '[Navigation Component] GoToPageF02 ';
export const GO_TO_PAGE_F03 = '[Navigation Component] GoToPageF03 ';
export const GO_TO_PAGE_J03 = '[Navigation Component] GoToPageJ03 ';
export const GO_TO_PAGE_K02 = '[Navigation Component] GoToPageK02 ';
export const GO_TO_PAGE_K03 = '[Navigation Component] GoToPageK03 ';
export const GO_TO_PAGE_H01 = '[Navigation Component] GoToPageK03 ';

export class GoToPageF02 implements Action { readonly type = GO_TO_PAGE_F02; constructor(public payload: any) { } }
export class GoToPageF03 implements Action { readonly type = GO_TO_PAGE_F03; constructor(public payload: any) { } }
export class GoToPageJ03 implements Action { readonly type = GO_TO_PAGE_J03; constructor(public payload: any) { } }
export class GoToPageK02 implements Action { readonly type = GO_TO_PAGE_K02; constructor(public payload: any) { } }
export class GoToPageK03 implements Action { readonly type = GO_TO_PAGE_K03; constructor(public payload: any) { } }
export class GoToPageH01 implements Action { readonly type = GO_TO_PAGE_H01; constructor(public payload: any) { } }

export type Actions
  =
  GoToPageF02 |
  GoToPageF03 |
  GoToPageJ03 |
  GoToPageK02 |
  GoToPageK03 |
  GoToPageH01;
