import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { StubdataService } from './stubdata.service';
import { ApiService } from './api.service';
import { Store, State } from '@ngrx/store';
import { GoToPageF01, SetF01ChartAndTableData } from '../providers/actions/f01.actions';
import { GoToPageF02, SetF02ChartAndTableData, SetF02CashTableData, SetF02AspireInformation } from '../providers/actions/f02.actions';
import { GoToPageF03 } from '../providers/actions/f03.actions';
import { GoToPageF04, SetF04PastTransaction, SetF04DividendAccountSummary } from '../providers/actions/f04.actions';
import { GoToPageI01 } from '../providers/actions/i01.actions';
import { GoToPageL05 } from '../providers/actions/l05.actions';
import { GoToPageIA01 } from '../providers/actions/ia01.actions';
import { GoToPageIA02A } from '../providers/actions/ia02a.actions';
import { GoToPageIA02B } from '../providers/actions/ia02b.actions';
import { GoToPageIA02C } from '../providers/actions/ia02c.actions';
import { GoToPageIA02D } from '../providers/actions/ia02d.actions';
import { GoToPageIA02E } from '../providers/actions/ia02e.actions';
import { GoToPageIB01 } from '../providers/actions/ib01.actions';
import { GoToPageJ01 } from '../providers/actions/j01.actions';
import { GoToPageJ03 } from '../providers/actions/j03.actions';
import { GoToPageK02, SetK02Policy } from '../providers/actions/k02.actions';
import { GoToPageK03 } from '../providers/actions/k03.actions';
import { GoToPageK05, SetK05BankAccounts } from '../providers/actions/k05.actions';
import { GoToPageLA01, SetLA01Policy } from '../providers/actions/la01.actions';
import { GoToPageM02 } from '../providers/actions/m02.actions';
import { GoToPageM03 } from '../providers/actions/m03.actions';
import { GoToPageN03 } from '../providers/actions/n03.actions';
import { GoToPageR01 } from '../providers/actions/r01.actions';
import { GoToPageH01, SetH01ChartAndTableData, SetFundManagerList, SetFundList, SetTransactionNo } from './actions/h01.actions';
import { GoToPageG01, SetSwitchInChartAndTableData, SetSwitchInFundManagerList, SetSwitchInFundList, SetSwitchInTransactionNo, SetG01PendingTransaction } from './actions/g01.actions';
import { SetUser } from './actions/user.actions';
import { SetTransactionStatus } from './actions/navigation.actions';
import * as _ from 'lodash';
import { PasswordEncryptionService } from './password-encryption.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { NavigationService } from './navigation.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';



@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private stubdataService: StubdataService,
    private apiService: ApiService,
    private alertController: AlertController,
    public store: Store<any>,
    private state: State<any>,
    private loadingController: LoadingController,
    public translate: TranslateService,
    private passwordService: PasswordEncryptionService,
    private navigationService: NavigationService,
    private iab: InAppBrowser

  ) { }

  async a01_get_login_status(username, pwd, loginTime, salt, isDirectLogin) {
    try {
      const res = await this.apiService.apiCall(['/UserAccount/GetLoginStatus'], [{ username: username, pwd: pwd, loginTime: loginTime, isDirectLogin: isDirectLogin }]);

      const payload = {
        username: res[0]['data']['username'],
        sessionKey: res[0]['data']['sessionKey'],
        isLoggedIn: true,
        clientNo: res[0]['data']['clientNo'],
        greeting: res[0]['data']['lastName'] + ' ' + res[0]['data']['firstName'],
        emailAddress: this.passwordService.decryptFunc(res[0]['data']['email'], salt),
        // Fixed workitem 73
        startWithMyWealthFlag: res[0]['data']['startWithMyWealthFlag'],
        showProvisionConsentMsgFlag: res[0]['data']['showProvisionConsentMsgFlag'] === 'Y',
        isDirectLogin: res[0]['data']['isDirectLogin'],
        pagesAllowed: res[0]['data']['pagesAllowed'],
        isTrustPolicySubAccountFlag: res[0]['data']['isTrustPolicySubAccountFlag'],
        isTrustPolicyMainAccountFlag: res[0]['data']['isTrustPolicyMainAccountFlag']
      };
      this.updateStore('a01', payload);
      return res[0]['data']['isFirstLogin'] === 'Y';
    } catch (err) {
      console.log('a01_get_login_status err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      if (err.response.status === 435) {
        setTimeout(() => {
          // console.log('sleep');
          this.navigationService.navigateByUrl('/c04-unlock-account', 'c04');
        }, 2000);
      }
      throw err;
    }
  }

  get_transaction_status() {
    return this.state.getValue().navigation.transactionStatus;
  }

  set_transaction_status(status) {
    this.store.dispatch(new SetTransactionStatus(status));
  }

  async get_logout_status() {
    try {
      return await this.apiService.apiCall(['/UserAccount/GetLogoutStatus']);
    } catch (err) {
      console.log('get_logout_status err', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  record_provision_consent(status) {
    try {
      this.updateStore('a01', { showProvisionConsentMsgFlag: status });
      return true;
    } catch (err) {
      console.log('record_provision_consent err', err);
    }
  }

  async set_provision_consent(payload) {
    try {
      //  Payload format: {
      //    action?: action,
      //    policyNo: 'string' or null
      //    isApplyToAllPolicy: 'Y' or 'N'
      //  }
      const res = await this.apiService.apiCall(['/UserTransactions/UpdatePolicyProvisionAcceptance'], [payload]);
      return res[0]['data'];
    } catch (err) {
      console.log('set_provision_consent err', err);
    }
  }

  async get_finex_url(fundCode?) {
    try {
      let res: any;
      if (fundCode) {
        res = await this.apiService.apiCall(['/Content/GetFinexURL'], [{ fundCode: fundCode }]);
      } else {
        res = await this.apiService.apiCall(['/Content/GetFinexURL']);
      }
      return res[0]['data']['finexLink'];
    } catch (err) {
      console.log('get_finex_url err', err);
    }
  }

  async get_morningStar_screener_url(fundCode?) {
    try {
      const language = this.state.getValue().setting.language;
      let res: any;

      if (fundCode) {
        res = await this.apiService.apiCall(['/Content/GetMorningStarURL'], [{ fundCode: fundCode }]);
        res = res[0]['data']['morningStarLink'];
        console.log(res);

      } else {
        // res = environment.morningStar_screener_url + (language === 'tc' ? 'zh-HK' : 'en-GB');
        res = environment.publicsite_url + language + environment.morningStar_screener_url;

      }
      return res;
    } catch (err) {
      console.log('get_morningStar_url err', err);
    }
  }

  get_morningStar_comparison_url() {
    try {
      const language = this.state.getValue().setting.language;
      let res: any;
      // res = environment.morningStar_fund_comparison_url + (language === 'tc' ? 'zh-HK#?' : 'en-GB#?') + 'idType=msid&securityIds=F0GBR04AWT%7CF0GBR04SEX%7CFOGBR05KLK';
      res = environment.publicsite_url + language + environment.morningStar_fund_comparison_url + (language === 'tc' ? 'zh-HK#?' : 'en-GB#?');
      return res;
    } catch (err) {
      console.log('get_morningStar_url err', err);
    }
  }

  async b01_validate_policy_no(policyNo) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_ValidatePolicyNo'], [{ policyNo: policyNo }]);
    } catch (err) {
      console.log('b01_validate_policy_no err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b02_original_validate_personal_info(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_Original_ValidatePersonalInfo'], [payload]);
    } catch (err) {
      console.log('b02_original_validate_personal_info err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b02a_autoenrollment_validate_personal_info(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_AutoEnrollment_ValidatePersonalInfo_1'], [payload]);
    } catch (err) {
      console.log('b02a_autoenrollment_validate_personal_info err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b02a_send_activation_key(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SendActivationKey'], [payload]);
    } catch (err) {
      console.log('b02a_send_activation_key err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b02b_autoenrollment_validate_personal_info(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_AutoEnrollment_ValidatePersonalInfo_2'], [payload]);
    } catch (err) {
      console.log('b02a_autoenrollment_validate_personal_info err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b03_autoenrollment_accept(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_AutoEnrollment_Accept'], [payload]);
    } catch (err) {
      console.log('b03_autoenrollment_accept err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async b03_original_accept(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SignUp_Original_Accept'], [payload]);
    } catch (err) {
      console.log('b03_original_accept err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async c01_forget_password(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/ForgotPassword'], [payload]);
    } catch (err) {
      console.log('c01_forget_password err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async c02_reset_password(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/ResetPassword'], [payload]);
    } catch (err) {
      console.log(`c02_reset_password err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async c02_reset_password_token_validation(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/ResetPasswordTokenValidation'], [payload]);
    } catch (err) {
      console.log(`c02_reset_password_token_validation err: `, err);
      return null;
    }
  }

  async c04_send_otp(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/SendOtp'], [payload]);
    } catch (err) {
      console.log('c04_send_otp err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      if (err.response.status === 498) {
        setTimeout(() => {
          // console.log('sleep');
          this.navigationService.navigateByUrl('/a01-login-page', 'a01');
        }, 2000);
      }
      throw err;
    }
  }

  async c04_unlock_account(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/UnlockAccount'], [payload]);
    } catch (err) {
      console.log('c04_unlock_account err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async d01_forget_user_id(emailAdd, dateOfBirth, idNo) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/ForgotUserID'], [{ emailAdd: emailAdd, dateOfBirth: dateOfBirth, idNo: idNo }]);
    } catch (err) {
      console.log('d01_forget_user_id err', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async get_f01_my_wealth() {
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies',
        // '/PolicyInfoContent/GetPolicyCoverageBalanceList'
      ]);
      // console.log(`get_f01_my_wealth res: `, res);
      const storeRes = [];
      res.forEach(e => {
        storeRes.push(e.data);
      });
      this.updateStore('f01', storeRes);
    } catch (err) {
      console.log(`get_f01_my_wealth err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_f01_chart_and_table(policy_no) {
    //  Takes an array of policy_no
    try {
      const policies = policy_no.map(p => {
        return { policyNo: p };
      });
      const res: any = await this.apiService.apiCall(Array(policies.length).fill('/PolicyInfoContent/GetPolicyPortfolioSummary'), policies);
      // console.log(`get_f01_chart_data res: `, res);
      const storeRes = [];
      res.forEach(e => {
        storeRes.push(e.data);
      });
      this.updateStore('f01-update-chart-and-table', {
        tableData: storeRes,
        policyNo: policies
      });
    } catch (err) {
      console.log(`get_f01_chart_data err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_f02_policy_summary() {
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies',
      ]);
      // console.log('GetMyPolicies f02', res);
      this.updateStore('f02', res.map(e => e.data));
    } catch (err) {
      console.log(`get_f02_policy_summary err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  // Fixed Bug #339
  async get_f02_cash_account(policyNo, coverageNo, accountType) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyCashAccountBalanceList',
      ], [{ policyNo: policyNo, coverageNo: coverageNo, accountType: accountType }]);
      res = res.map(e => e.data);
      console.log('GetPolicyCashAccountBalanceList f02', res);
      this.updateStore('f02-update-cash-table', { chartData: res[0] });
    } catch (err) {
      console.log(`get_f02_cash_account err: `, err);
      // this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      this.updateStore('f02-update-cash-table', {
        chartData: []
      });
      // throw err;
    }
  }

  async get_f02_chart_and_table(policyNo, coverageNo, accountType) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyCoverageFundAllocationList',
        '/PolicyInfoContent/GetPolicyCoverageBalanceList',
        '/PolicyInfoContent/GetPolicyCoverageBalanceListForCashAccountAndNonCashAccount'
      ],
      [
        { policyNo: policyNo, coverageNo: coverageNo },
        { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType },
        { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType }
      ]);
      res = res.map(e => e.data);
      this.updateStore('f02-update-chart-and-table', {
        tableData: res[0],
        chartData: res[1],
        newChartData: res[2]
      });
    } catch (err) {
      console.log('get_f02_chart_and_table', err.response.status);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      this.updateStore('f02-update-chart-and-table', {
        tableData: [],
        chartData: [],
        newChartData: []
      });
      // throw err;
    }
  }

  async get_f02_aspire_or_aspiration_information(policyNo) {
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyExtraAllocationDetailForAspireOrAspiration'
      ], [{ policyNo: policyNo }]);
      this.updateStore('f02-aspire-information', res.map(e => e.data));
    } catch (err) {
      console.log(`get_f02_aspire_or_aspiration_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_f03_policy_information() {
    // console.log(`get_f03_policy_informations: `);
    let pleaseWaitMessage = 'Please wait...';
    this.translate.get(['GEN_01']).subscribe(i18n => {
      pleaseWaitMessage = i18n.GEN_01;
    });
    const loader = await this.loadingController.create({
      message: pleaseWaitMessage
    });
    try {
      const policies: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ]);
      // Fixed workitem 78
      const jointInsuredNo = policies[0]['data']['policy'].map(policy => {
        return {
          policyNo: {
            jointInsuredCode: policy['policyInfoView']['jointInsuredCode']
          }
        };
      });

      if (jointInsuredNo.jointInsuredCode != null) {
        const policyNo = policies[0]['data']['policy'].map(policy => {
          return {
            policyNo: {
              ownerNo: policy['policyInfoView']['ownerNo'],
              insuredCode: policy['policyInfoView']['insuredCode'],
              // Fixed workitem 78
              jointInsuredCode: policy['policyInfoView']['jointInsuredCode']
            }
          };
        });
        const result = [];
        console.log('GetClientPersonalInfo policyNo', policyNo);
        loader.present();
        for (const policy of policyNo) {
          try {
            const clientInfo = {};
            // Fixed workitem 78
            // const res = await this.apiService.apiCall(['/ClientInfoContent/GetClientPersonalInfo', '/ClientInfoContent/GetClientPersonalInfo'], [{ clientNo: policy.policyNo.ownerNo }, { clientNo: policy.policyNo.insuredCode }]);
            const res = await this.apiService.apiCall(['/ClientInfoContent/GetClientPersonalInfo', '/ClientInfoContent/GetClientPersonalInfo', '/ClientInfoContent/GetClientPersonalInfo'], [{ clientNo: policy.policyNo.ownerNo }, { clientNo: policy.policyNo.insuredCode }, { clientNo: policy.policyNo.jointInsuredCode }]);
            // console.log('GetClientPersonalInfo res', res);
            clientInfo['ownerNo'] = res[0]['data']['clientInfo'];
            clientInfo['insuredCode'] = res[1]['data']['clientInfo'];
            clientInfo['jointInsuredCode'] = res[2]['data']['clientInfo'];
            result.push(clientInfo);
          } catch (err) {
            this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
          }
        }
        for (let i = 0; i < policies[0]['data']['policy'].length; i++) {
          policies[0]['data']['policy'][i]['clientInfo'] = result[i];
        }
        // console.log('policies with clientInfo ', policies)
        loader.dismiss();
        this.updateStore('f03', policies.map(e => e.data));
      } else {
        const policyNo = policies[0]['data']['policy'].map(policy => {
          return {
            policyNo: {
              ownerNo: policy['policyInfoView']['ownerNo'],
              insuredCode: policy['policyInfoView']['insuredCode']
            }
          };
        });
        const result = [];
        console.log('GetClientPersonalInfo policyNo', policyNo);
        loader.present();
        for (const policy of policyNo) {
          try {
            const clientInfo = {};
            const res = await this.apiService.apiCall(['/ClientInfoContent/GetClientPersonalInfo', '/ClientInfoContent/GetClientPersonalInfo'], [{ clientNo: policy.policyNo.ownerNo }, { clientNo: policy.policyNo.insuredCode }]);
            // console.log('GetClientPersonalInfo res', res);
            clientInfo['ownerNo'] = res[0]['data']['clientInfo'];
            clientInfo['insuredCode'] = res[1]['data']['clientInfo'];
            result.push(clientInfo);
          } catch (err) {
            this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
          }
        }
        for (let i = 0; i < policies[0]['data']['policy'].length; i++) {
          policies[0]['data']['policy'][i]['clientInfo'] = result[i];
        }
        // console.log('policies with clientInfo ', policies)
        loader.dismiss();
        this.updateStore('f03', policies.map(e => e.data));
      }


    } catch (err) {
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      loader.dismiss();
    }
  }

  async get_f04_policy_transaction() {
    console.log(`get_f04_policy_transaction: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ]);
      this.updateStore('f04', res.map(e => e.data));
      // await this.f04_get_transaction_records_for_past_12_months(res[0]['data']['policy'][0]['policyInfoView']['policyNo']);
    } catch (err) {
      console.log(`get_f04_policy_transaction err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async f04_get_transaction_records_for_past_12_months(policyNo) {
    console.log(`f04_get_transaction_records_for_past_12_months: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetTransactionRecordsForPreviousTwelveMonths'
      ], [{ policyNo: policyNo }]);
      this.updateStore('f04-past-transaction', res.map(e => e.data));
    } catch (err) {
      console.log(`f04_get_transaction_records_for_past_12_months err: `, err);
      this.updateStore('f04-past-transaction', []);
    }
  }

  async f04_get_dividend_account_summary(policyNo) {
    console.log(`f04_get_dividend_account_summary: `);

    if (policyNo.length < 12) {
      return this.updateStore('f04-dividend-account-summary', []);
    }
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyIncomeFundDividendAccountSummaryByPolicyNo'
      ], [{policyNo: policyNo, username: this.state.getValue().user.username, }]);
      this.updateStore('f04-dividend-account-summary', res.map(e => e.data));
    } catch (err) {
      console.log(`f04_get_dividend_account_summary err: `, err);
      this.updateStore('f04-dividend-account-summary', []);
    }

  }

  async downloadPDF(policyNo, coverageNo, accountType, pageID, isBrokerSite, searchFromDate, searchToDate) {
    console.log(`Download PDF: `);
    try {
      if (searchFromDate === '' && searchToDate === '') {
        const res: any = await this.apiService.apiCall([
          '/PolicyInfoContent/GeneratePDF'
        ], [{ policyNo: policyNo, coverageNo: coverageNo, accountType: accountType, pageID: pageID, isBrokerSite: isBrokerSite }]);
        return res[0]['data'];
      } else if (searchFromDate !== '' && searchToDate === '') {
        const res: any = await this.apiService.apiCall([
          '/PolicyInfoContent/GeneratePDF'
        ], [{ policyNo: policyNo, coverageNo: coverageNo, accountType: accountType, pageID: pageID, isBrokerSite: isBrokerSite, searchFromDate: searchFromDate }]);
        return res[0]['data'];
      } else if (searchFromDate === '' && searchToDate !== '') {
        const res: any = await this.apiService.apiCall([
          '/PolicyInfoContent/GeneratePDF'
        ], [{ policyNo: policyNo, coverageNo: coverageNo, accountType: accountType, pageID: pageID, isBrokerSite: isBrokerSite, searchToDate: searchToDate }]);
        return res[0]['data'];
      } else if (searchFromDate !== '' && searchToDate !== '') {
        const res: any = await this.apiService.apiCall([
          '/PolicyInfoContent/GeneratePDF'
        ], [{ policyNo: policyNo, coverageNo: coverageNo, accountType: accountType, pageID: pageID, isBrokerSite: isBrokerSite, searchFromDate: searchFromDate, searchToDate: searchToDate }]);
        return res[0]['data'];
      }
    } catch (err) {
      console.log(`Download PDF err: `, err);
      return null;
    }
  }

  async get_g01_switching(policyNo) {
    console.log(`get_g01_switching: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetSwitchInOrRedirectionPolicies'
      ], [{ listType: 'SW', isBrokerSite: false, policyNo: policyNo }]); // Restore conflict for work item 382
      res[0]['data']['policy'] = res[0]['data']['policy'].filter(policy => policy['policyInfoView']['policyValueInHKD'] > 0);
      this.updateStore('g01', res.map(e => e.data));
      if (res[0]['data']['policy'].length > 0) {
        const policy = res[0]['data']['policy'][0];
        // await this.get_g01_get_pending_transaction(policy['policyInfoView']['policyNo']);
        if (policy['policyAccountView'].length > 0) {
          // Workitem 391
          await this.get_g01_get_pending_transaction(policy['policyInfoView']['policyNo'], policy['policyAccountView'][0]['policyAcctNo'], policy['policyAccountView'][0]['policySubAcctNo']);
          await this.get_g01_chart_and_table(policy['policyInfoView']['policyNo'], policy['policyAccountView'][0]['policyAcctNo'], policy['policyAccountView'][0]['policySubAcctNo']);
          // await this.get_g01_get_switchout_list(policy['policyInfoView']['policyNo'], policy['policyAccountView'][0]['policyAcctNo'], policy['policyAccountView'][0]['policySubAcctNo']);
        }
      }
    } catch (err) {
      console.log(`get_g01_switching err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_g01_get_pending_transaction(policyNo, coverageNo, accountType) {
    try {
      const res: any = await this.apiService.apiCall([
        // Workitem 391
        // '/PolicyInfoContent/GetPendingTransactions'
        '/PolicyInfoContent/GetPendingSwitchOutTransactions'
      ],
        [
          // Workitem 391
          // { policyNo: policyNo }
          { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType }
        ]);
      this.updateStore('g01-update-pending-transactions', res[0]['data']['policyPendingTransactionsList']);
    } catch (err) {
      this.updateStore('g01-update-pending-transactions', []);
      // throw err;
    }
  }

  async get_g01_chart_and_table(policyNo, coverageNo, accountType) {
    try {
      let res: any = await this.apiService.apiCall([
        // '/PolicyInfoContent/GetPolicyCoverageBalanceList'
        '/PolicyInfoContent/GetPolicyCoverageBalanceListForCashAccountAndNonCashAccount',
        '/PolicyInfoContent/GetPolicySwitchOutList'
      ],
        [
          { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType },
          { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType }
        ]);
      res = res.map(e => e.data);
      this.updateStore('g01-update-chart-and-table', {
        tableData: res[0],
        chartData: res[0],
        newTableData: res[1]
      });
    } catch (err) {
      console.log('get_g01_chart_and_table', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      this.updateStore('g01-update-chart-and-table', {
        tableData: [],
        chartData: [],
        newTableData: []
      });
      // throw err;
    }
  }

  async get_g01b_fund_manager_list(policyNo) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicySwitchInFundManagerList',
      ], [{ policyNo: policyNo }]);
      res = res[0]['data']['fundHouseList'];
      this.updateStore('g01b-set-fund-manager-list', res);
    } catch (err) {
      console.log('get_g01b_fund_manager_list', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async get_g01b_fund_list(policyNo, fundHouseCode) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicySwitchInFundList',
      ], [{ policyNo: policyNo, fundHouseCode: fundHouseCode }]);
      res = res[0]['data']['fundList'];
      this.updateStore('g01b-set-fund-list', res);
    } catch (err) {
      console.log('get_g01b_fund_list', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async get_g01b_fund_info2(policyNo, fundCode) {
    try {
      const res = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicySwitchInFundList'
      ], [{policyNo: policyNo, fundCode: fundCode }]);
      return res[0]['data']['fundList'][0];
    } catch (err) {
      console.log('get_g01b_fund_info2', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async g01a_pre_switching_action(policyNo, fundList) {
    try {
      let res: any = await this.apiService.apiCall([
        '/FundTransactions/PreSwitchingAction'
      ], [{ policyNo: policyNo, fundList: fundList }]);
      res = res[0]['data']['newTransactionNo'];
      this.updateStore('g01-pre-redirection-action', res);
    } catch (err) {
      console.log('g01a_pre_switching_action', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }


  async g02_switching_action(policyNo, accountNo, subAccountNo, emailAddress, transactionNo, switchOutList, switchInList, rpqEffectiveDate, riskScore, flgMismatch, flgRPQExpired, flgRPQNeverSubmit, flgFundRiskLevelExceed, flgDeclaration) {
    try {
      await this.apiService.apiCall([
        '/FundTransactions/SwitchingAction'
      ], [
        {
          policyNo: policyNo,
          accountNo: accountNo,
          subAccountNo: subAccountNo,
          emailAddress: emailAddress,
          transactionNo: transactionNo,
          switchOutList: switchOutList,
          switchInList: switchInList,
          rpqEffectiveDate: rpqEffectiveDate,
          riskScore: riskScore,
          flgMismatch: flgMismatch,
          flgRPQExpired: flgRPQExpired,
          flgRPQNeverSubmit: flgRPQNeverSubmit,
          flgFundRiskLevelExceed: flgFundRiskLevelExceed,
          flgDeclaration: flgDeclaration
        }
      ]);
    } catch (err) {
      console.log(`g02_switching_action err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_g03_switching_completed() {
    console.log(`get_g03_switching_completed: `);
    try {
      return await this.stubdataService.getCall('/g03_switching_completed');
    } catch (err) {
      console.log(`get_g03_switching_completed err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_h01_redirection(policyNo) {
    console.log(`get_h01_redirection: `);
    try {
      // return await this.stubdataService.getCall('/h01_redirection');
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetSwitchInOrRedirectionPolicies'
      ], [{ listType: 'PR', policyNo: policyNo }]);
      this.updateStore('h01', res.map(e => e.data));
      if (res[0]['data']['policy'].length > 0) {
        const policy = res[0]['data']['policy'][0];
        if (policy['policyAccountView'].length > 0) {
          await this.get_h01_chart_and_table(policy['policyInfoView']['policyNo'], policy['policyAccountView'][0]['policyAcctNo'], policy['policyAccountView'][0]['policySubAcctNo']);
        }
      }
    } catch (err) {
      console.log(`get_h01_redirection err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_h01_chart_and_table(policyNo, coverageNo, accountType) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyCoverageFundAllocationList',
        // '/PolicyInfoContent/GetPolicyCoverageBalanceList'
      ],
      [
        { policyNo: policyNo, coverageNo: coverageNo },
        // { policyNo: policyNo, coverageNo: coverageNo, accountType: accountType }
      ]);
      res = res.map(e => e.data);
      this.updateStore('h01-update-chart-and-table', {
        tableData: res[0],
        chartData: res[0]
      });
    } catch (err) {
      console.log('get_h01_chart_and_table', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      this.updateStore('h01-update-chart-and-table', {
        tableData: [],
        chartData: []
      });
      // throw err;
    }
  }

  async get_h01a_fund_manager_list(policyNo) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyRedirectionFundManagerList',
      ], [{ policyNo: policyNo }]);
      res = res[0]['data']['fundHouseList'];
      this.updateStore('h01a-set-fund-manager-list', res);
    } catch (err) {
      console.log('get_h01a_future_investment_detail', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async get_h01a_fund_list(policyNo, fundHouseCode) {
    try {
      let res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyRedirectionFundList',
      ], [{ policyNo: policyNo, fundHouseCode: fundHouseCode }]);
      res = res[0]['data']['fundList'];
      this.updateStore('h01a-set-fund-list', res);
    } catch (err) {
      console.log('get_h01a_future_investment_detail', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async get_h01a_fund_info2(policyNo, fundCode) {
    try {
      const res = await this.apiService.apiCall([
        '/PolicyInfoContent/GetPolicyRedirectionFundList'
      ], [{policyNo: policyNo, fundCode: fundCode }]);
      return res[0]['data']['fundList'][0];
    } catch (err) {
      console.log('get_h01a_fund_info2', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
    }
  }

  async h01a_pre_redirection_action(policyNo, fundList) {
    try {
      let res: any = await this.apiService.apiCall([
        '/FundTransactions/PreRedirectionAction'
      ], [{ policyNo: policyNo, fundList: fundList }]);
      res = res[0]['data']['newTransactionNo'];
      this.updateStore('h01-pre-redirection-action', res);
    } catch (err) {
      console.log('h01a_pre_redirection_action', err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_h02_redirection_data_confirmation() {
    console.log(`get_h02_redirection_data_confirmation: `);
    try {
      return await this.stubdataService.getCall('/h02_redirection_dataconfirmation');
    } catch (err) {
      console.log(`get_h02_redirection_data_confirmation err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  // async h02_redirection_action(policyNo, accountNo, subAccountNo, emailAddress, transactionNo, fundList) {
  async h02_redirection_action(policyNo, accountNo, subAccountNo, emailAddress, transactionNo, fundList, rpqEffectiveDate, riskScore, flgMismatch, flgRPQExpired, flgRPQNeverSubmit, flgFundRiskLevelExceed, flgDeclaration) {
    try {
      await this.apiService.apiCall([
        '/FundTransactions/RedirectionAction'
      ], [
        {
          policyNo: policyNo,
          accountNo: accountNo,
          subAccountNo: subAccountNo,
          emailAddress: emailAddress,
          transactionNo: transactionNo,
          fundList: fundList,
          rpqEffectiveDate: rpqEffectiveDate,
          riskScore: riskScore,
          flgMismatch: flgMismatch,
          flgRPQExpired: flgRPQExpired,
          flgRPQNeverSubmit: flgRPQNeverSubmit,
          flgFundRiskLevelExceed: flgFundRiskLevelExceed,
          flgDeclaration: flgDeclaration
        }
      ]);
    } catch (err) {
      console.log(`get_h02_redirection_data_confirmation err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_h03_redirection_completed() {
    console.log(`get_h03_redirection_completed: `);
    try {
      return await this.stubdataService.getCall('/h03_redirection_completed');
    } catch (err) {
      console.log(`get_h03_redirection_completed err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async delete_session_transaction_async(policyNo) {
    try {
      await this.apiService.apiCall([
        '/FundTransactions/DeleteSessionTransactionAsync'
      ], [
        {
          policyNo: policyNo,
          // crossdomain: true
          // headers: {'Access-Control-Allow-Origin': '*'}
        }
      ]);
    } catch (err) {
      console.log(`delete_session_transaction_async err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_i01_my_documents() {
    let pleaseWaitMessage = 'Please wait...';
    this.translate.get(['GEN_01']).subscribe(i18n => {
      pleaseWaitMessage = i18n.GEN_01;
    });
    console.log(`get_i01_my_documents: `);
    const loader = await this.loadingController.create({
      message: pleaseWaitMessage
    });
    try {
      const policies: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ]);
      const policyNo = policies[0]['data']['policy'].map(policy => {
        return {
          policyNo: policy['policyInfoView']['policyNo']
        };
      });
      const result = [];
      loader.present();
      for (const policy of policyNo) {
        try {
          const res = await this.apiService.apiCall(['/PolicyInfoContent/GetPolicyEstatementList'], [policy]);
          result.push(res[0]['data']['policyEStatement']);
        } catch (err) { }
      }
      loader.dismiss();
      this.updateStore('i01', _.flatten(result));
    } catch (err) {
      loader.dismiss();
    }
  }

  async get_l05_sub_account_management() {
    let pleaseWaitMessage = 'Please wait...';
    this.translate.get(['GEN_01']).subscribe(i18n => {
      pleaseWaitMessage = i18n.GEN_01;
    });
    console.log(`get_l05_sub_account_management: `);
    const loader = await this.loadingController.create({
      message: pleaseWaitMessage
    });
    try {

      const result = [];
      loader.present();

        try {
          const res = await this.apiService.apiCall(['/PolicyInfoContent/GetPolicySubAccountList'], [{ username: this.state.getValue().user.username, clientNo: this.state.getValue().user.clientNo }]);
          result.push(res[0]['data']['policySubAccount']);
          console.log(result);
          console.log('levlevlevlevlevlevlevlevlevlevlev');
        } catch (err) { }

      loader.dismiss();
      this.updateStore('l05', _.flatten(result));
    } catch (err) {
      loader.dismiss();
    }
  }

  async i01_get_file_content(file_name) {
    try {
      const res = await this.apiService.apiCall(['/Content/GetFileContent'], [{ filePath: file_name }]);
      return res[0]['data'];
    } catch (err) {
      console.log('i01_get_file_content', err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      return null;
    }
  }

  async i01_update_read_status(payload) {
    try {
      return await this.apiService.apiCall(['/SystemTransactions/UpdateEStatementReadStatus'], [payload]);
    } catch (err) {
      console.log(`i01_update_read_status err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async l05_register_sub_account(payload) {
    try {
       const res = await this.apiService.apiCall(['/UserTransactions/CreateSubAccount'], [payload]);
       console.log(res[0].data);

      //  if (res[0].data.errorCode == '662')
      //  {
      //   this.presentAlert(err.response.status, err.response.data.errorMsg);
      //  }

       return res[0].data;

    } catch (err) {
      console.log(`l05_register_sub_account err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async ia02e_submit_ia_form(payload) {
    try {
      console.log('payload', payload);
      const res = await this.apiService.apiCall(['/UserTransactions/UpdateRiskProfile'], [payload]);
      return res[0].data;
    } catch (err) {
      console.log(`ia02e_submit_ia_form err: `, err);
      this.presentAlert(err.response.status, err.reponse.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_ib01_video_list() {
    console.log(`ib01_get_video_list: `);
    try {
      const res: any = await this.apiService.apiCall(['/CMS/video-pages'], []);
      console.log('ib01_get_video_list', res);
      this.updateStore('ib01', res[0].data);
    } catch (err) {
      console.log(`ib01_get_video_list err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_j01_my_protection() {
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ]);
      console.log('GetMyPolicies j01', res);
      const storeRes = [];
      res.forEach(e => {
        storeRes.push(e.data);
      });
      this.updateStore('j01', storeRes);
    } catch (err) {
      console.log(`get_j01_my_wealth err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_j02_protection_financial_summary() {
    console.log(`get_j02_protection_financial_summary: `);
    try {
      return await this.stubdataService.getCall('/j02_protection_financial_summary');
    } catch (err) {
      console.log(`get_j02_protection_financial_summary err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_j03_protection_policy_information() {
    console.log(`get_j03_protection_policy_information: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies',
        '/ClientInfoContent/GetClientPersonalInfo'
      ], [{}, { clientNo: this.state.getValue().user.clientNo }]);
      const storeRes = [];
      res.forEach(e => {
        storeRes.push(e.data);
      });
      this.updateStore('j03', storeRes);
    } catch (err) {
      console.log(`get_j03_protection_policy_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_k02_personal_information() {
    console.log(`get_k02_personal_information: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/ClientInfoContent/GetClientPersonalInfo'
      ], [{ clientNo: this.state.getValue().user.clientNo }]);
      console.log('GetClientPersonalInfo', res);
      this.updateStore('k02', res[0].data);
    } catch (err) {
      console.log(`get_k02_personal_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_k02_personal_policy_information() {
    console.log(`get_k02_personal_policy_information: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/ClientInfoContent/GetClientPolicyPersonalInfo'
      ], [{ clientNo: this.state.getValue().user.clientNo }]);
      console.log('GetClientPolicyPersonalInfo', res);
      this.updateStore('k02', res[0].data);
    } catch (err) {
      console.log(`get_k02_personal_policy_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async k02_change_policy(policy_no) {
    try {
      this.updateStore('k02-change-policy', policy_no);
    } catch (err) {
      console.log(`k02_change_policy err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_k03_personal_information() {
    console.log(`get_k03_personal_information: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/ClientInfoContent/GetClientPersonalInfo'
      ], [{ clientNo: this.state.getValue().user.clientNo }]);
      console.log('GetClientPersonalInfo', res);
      this.updateStore('k03', res[0].data);
    } catch (err) {
      console.log(`get_k03_personal_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async k03_update_personal_information(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/UpdatePersonalInformation'], [payload]);
    } catch (err) {
      console.log(`k03_update_personal_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async k03_request_go_green(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/RequestGoGreen'], [payload]);
    } catch (err) {
      console.log(`k03_request_go_green err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async k04_update_password(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/UpdatePassword'], [payload]);
    } catch (err) {
      console.log(`k04_update_password err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async get_k05_banks_list() {
    try {
      const bankListAndCurrList = {};
      const res: any = await this.apiService.apiCall([
        '/Content/GetBanksList', '/Content/GetCurrencyList'
        // '/Content/GetBanksList'
      ]);
      bankListAndCurrList['bankList'] = res[0]['data']['banksInfoViewList'];
      bankListAndCurrList['currList'] = res[1]['data']['currencyInfoViewList'];
      // res = res[0]['data']['banksInfoViewList'];
      console.log('bankList', bankListAndCurrList['bankList']);
      console.log('currList', bankListAndCurrList['currList']);
      console.log('k05 bl ', bankListAndCurrList);
      this.updateStore('k05', bankListAndCurrList);
    } catch (err) {
      console.log(`get_k05_banks_list err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async get_k05_currency_list() {
    try {
      let res: any = await this.apiService.apiCall([
        '/Content/GetCurrencyList'
      ]);
      res = res[0]['data']['currencyInfoViewList'];
      console.log('k05 bl ', res);
      this.updateStore('k05', res);
    } catch (err) {
      console.log(`get_k05_currency_list err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async get_k05_bank_accounts() {
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ], [{ page: 'k05' }]);

      this.updateStore('k05-bank-accounts', res.map(e => e.data));
    } catch (err) {
      console.log(`get_k05_bank_accounts err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async k05_update_bank_accounts(payload) {
    try {
      return await this.apiService.apiCall(['/UserTransactions/CreateOrUpdateBankInfo'], [payload]);
    } catch (err) {
      console.log(`k05_update_bank_accounts err: `, err);
      this.presentAlert(err.response.status, err.response.data.errorMsg);
      throw err;
    }
  }

  async get_la01_risk_profiles_list() {
    console.log(`get_la01_risk_profiles_list: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetMyPolicies'
      ], [{ page: 'la01' }]);
      console.log('GetMyPolicies', res);

      this.updateStore('la01', res.map(e => e.data));
    } catch (err) {
      console.log(`get_la01_risk_profiles_list err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async la01_change_policy(policy_no) {
    try {
      this.updateStore('la01-change-policy', policy_no);
    } catch (err) {
      console.log(`la01_change_policy err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_m02_notice_investment_choice() {
    console.log(`get_m02_notice_investment_choice: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/Content/GetInvestmentChoiceNotice'
      ]);
      console.log('GetInvestmentChoiceNotice', res);
      this.updateStore('m02', res[0].data);
    } catch (err) {
      console.log(`get_m02_notice_investment_choice err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_m03_notice_latest_news() {
    console.log(`get_m03_notice_latest_news: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/Content/GetLatestNews'
      ]);
      console.log('GetLatestNews', res);
      this.updateStore('m03', res[0].data);
    } catch (err) {
      console.log(`get_m03_notice_latest_news err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async n02_contact_us(payload) {
    console.log(`n02_contact_us: `);
    try {
      const res: any = await this.apiService.apiCall([
        '/GeneralTransactions/SendContactForm'
      ], [payload]);
      console.log('n02_contact_us', res);
      return res;
    } catch (err) {
      console.log(`n02_contact_us err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async n03_get_legal_privacy_statement() {
    console.log(`n03_get_legal_privacy_statement: `);
    try {
      const res: any = await this.apiService.apiCall(['/CMS/Languages'], []);
      console.log('n03_get_legal_privacy_statement', res);
      this.updateStore('n03', res[0].data);
    } catch (err) {
      console.log(`n03_get_legal_privacy_statement err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }

  async get_r01_top5_inflow_outflow_information() {
    console.log(`get_r01_top5_inflow_outflow_information: `);
    try {
      const fundFlowInfo = {};
      const res: any = await this.apiService.apiCall([
        '/PolicyInfoContent/GetTop5FundFlowSubscription', '/PolicyInfoContent/GetTop5FundFlowRedemption'
      ]);
      fundFlowInfo['inflowFundList'] = res[0]['data']['fundFlowList'];
      fundFlowInfo['outflowFundList'] = res[1]['data']['fundFlowList'];
      // console.log('GetTop5FundFlowSubscription', fundFlowInfo['inflowFundList']);
      // console.log('GetTop5FundFlowRedemption', fundFlowInfo['inoutflowFundListlowFundList']);
      this.updateStore('r01', fundFlowInfo);
    } catch (err) {
      console.log(`get_r01_top5_inflow_outflow_information err: `, err);
      this.presentAlert(err.response.status, err.response.data.returnStatus.errorMsg);
      throw err;
    }
  }


  updateStore(pageKey, payload) {
    const currentPage: any = this.state.getValue().navigation.currentPage;
    switch (pageKey) {
      case 'a01':
        this.store.dispatch(new SetUser(payload));
        break;
      case 'f01':
        this.store.dispatch(new GoToPageF01(payload));
        break;
      case 'f01-update-chart-and-table':
        this.store.dispatch(new SetF01ChartAndTableData(payload));
        break;
      case 'f02':
        this.store.dispatch(new GoToPageF02(payload, currentPage));
        break;
      case 'f02-update-chart-and-table':
        this.store.dispatch(new SetF02ChartAndTableData(payload));
        break;
      case 'f02-update-cash-table':
        this.store.dispatch(new SetF02CashTableData(payload));
        break;
      case 'f02-aspire-information':
        this.store.dispatch(new SetF02AspireInformation(payload));
        break;
      case 'f03':
        this.store.dispatch(new GoToPageF03(payload, currentPage));
        break;
      case 'f04':
        this.store.dispatch(new GoToPageF04(payload, currentPage));
        break;
      case 'f04-past-transaction':
        this.store.dispatch(new SetF04PastTransaction(payload, currentPage));
        break;
      case 'f04-dividend-account-summary':
        this.store.dispatch(new SetF04DividendAccountSummary(payload, currentPage));
        break;
      case 'g01':
        this.store.dispatch(new GoToPageG01(payload));
        break;
      case 'g01-update-pending-transactions':
        this.store.dispatch(new SetG01PendingTransaction(payload));
        break;
      case 'g01-update-chart-and-table':
        this.store.dispatch(new SetSwitchInChartAndTableData(payload));
        break;
      case 'g01b-set-fund-manager-list':
        this.store.dispatch(new SetSwitchInFundManagerList(payload));
        break;
      case 'g01b-set-fund-list':
        this.store.dispatch(new SetSwitchInFundList(payload));
        break;
      case 'g01-pre-redirection-action':
        this.store.dispatch(new SetSwitchInTransactionNo(payload));
        break;
      case 'h01':
        this.store.dispatch(new GoToPageH01(payload));
        break;
      case 'h01-pre-redirection-action':
        this.store.dispatch(new SetTransactionNo(payload));
        break;
      case 'h01-update-chart-and-table':
        this.store.dispatch(new SetH01ChartAndTableData(payload));
        break;
      case 'h01a-set-fund-manager-list':
        this.store.dispatch(new SetFundManagerList(payload));
        break;
      case 'h01a-set-fund-list':
        this.store.dispatch(new SetFundList(payload));
        break;
      case 'i01':
        this.store.dispatch(new GoToPageI01(payload));
        break;
      case 'l05':
          this.store.dispatch(new GoToPageL05(payload));
          break;
      case 'ia01':
        this.store.dispatch(new GoToPageIA01(payload));
        break;
      case 'ia02a':
        this.store.dispatch(new GoToPageIA02A(payload));
        break;
      case 'ia02b':
        this.store.dispatch(new GoToPageIA02B(payload));
        break;
      case 'ia02c':
        this.store.dispatch(new GoToPageIA02C(payload));
        break;
      case 'ia02d':
        this.store.dispatch(new GoToPageIA02D(payload));
        break;
      case 'ia02e':
        this.store.dispatch(new GoToPageIA02E(payload));
        break;
      case 'ib01':
        this.store.dispatch(new GoToPageIB01(payload));
        break;
      case 'j01':
        this.store.dispatch(new GoToPageJ01(payload));
        break;
      case 'j03':
        this.store.dispatch(new GoToPageJ03(payload));
        break;
      case 'k02':
        this.store.dispatch(new GoToPageK02(payload));
        break;
      case 'k02-change-policy':
        this.store.dispatch(new SetK02Policy(payload));
        break;
      case 'k03':
        this.store.dispatch(new GoToPageK03(payload));
        break;
      case 'k05':
        this.store.dispatch(new GoToPageK05(payload));
        break;
      case 'k05-bank-accounts':
        this.store.dispatch(new SetK05BankAccounts(payload));
        break;
      case 'la01':
        this.store.dispatch(new GoToPageLA01(payload));
        break;
      case 'la01-change-policy':
        this.store.dispatch(new SetLA01Policy(payload));
        break;
      case 'm02':
        this.store.dispatch(new GoToPageM02(payload));
        break;
      case 'm03':
        this.store.dispatch(new GoToPageM03(payload));
        break;
      case 'n03':
        this.store.dispatch(new GoToPageN03(payload));
        break;
      case 'r01':
        this.store.dispatch(new GoToPageR01(payload));
        break;
      default:
        break;
    }
  }

  async presentAlert(errorCode, errorMsg) {
    const tmpErrorCode = 'ERR_' + errorCode;
    let tmpMessage: any;
    const language = this.state.getValue().setting.language;
    // Fixed Workitem 425
    tmpMessage = errorMsg;
    if (errorCode === '520') {
      this.translate.get(tmpErrorCode).subscribe(res => {
        tmpMessage = res;
      });
    }
    if (language === 'tc') {
      const alert = await this.alertController.create({
        header: '錯誤提示',
        message: `代號: ${errorCode}` + '<br>' + `訊息: ${tmpMessage}`,
        buttons: ['確定']
      });
      await alert.present();
    } else {
      const alert = await this.alertController.create({
        header: 'Error Alert',
        message: `Code: ${errorCode}` + '<br>' + `Message: ${tmpMessage}`,
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  async get_token(forSystem, fromSystem, clientID) {
    try {
      // const language = this.state.getValue().setting.language;
      let res: any;
      if (forSystem === 'aqumon') {
        res = await this.apiService.apiCall(['/UserAccount/GenerateToken'], [{ type: fromSystem, clientID: clientID }]);
        res = res[0]['data']['token'];
      }
      // console.log(res);
      return res;
    } catch (err) {
      console.log('get_token err', err);
    }
  }

  async redirectToInvestPlatform(redirectFuntion, policy_no, username) {
    const lang = this.translate.currentLang;
    const token = await this.get_token('aqumon', 'web_c', username);
    // let token = 'eyJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.e30.tb45rGty021BClaubPPYwgU8Sng2wb8VBfA4rSgRhfW9ZgMK8KRBrLxKe8lQzYmoYW8wP4zNHHN8LqhZO-XFkvyShWj-fFha8wDw6_BqtypAuqf-8Mdl1bM-4fp3wJi0uy3d8bCwHE_voQ8Yr7dYf7ogv5T0G9B2DtGxtStXFJQLSv6QDk3YY40s9MN4yIJi-rnUNpjXz3o7Mu2TuSo4HkrDoS0hISDrmrsiKCamlUeGOmq65maVgP22p_VR8A5Zcp3Ou4nH4d2FhxHZoAa9m6UQ8r4WIr0oCAcS8rek_QPDeMBw1wBrc0MG9kBPSexEiInRviiwW0P3bezlH3Xl_Q';
    // this.iab.create(url);
    // window.open(environment.investplatform_url + '?clientId=' + this.state.getValue().user.username + '&token=' + 'eyJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.e30.tb45rGty021BClaubPPYwgU8Sng2wb8VBfA4rSgRhfW9ZgMK8KRBrLxKe8lQzYmoYW8wP4zNHHN8LqhZO-XFkvyShWj-fFha8wDw6_BqtypAuqf-8Mdl1bM-4fp3wJi0uy3d8bCwHE_voQ8Yr7dYf7ogv5T0G9B2DtGxtStXFJQLSv6QDk3YY40s9MN4yIJi-rnUNpjXz3o7Mu2TuSo4HkrDoS0hISDrmrsiKCamlUeGOmq65maVgP22p_VR8A5Zcp3Ou4nH4d2FhxHZoAa9m6UQ8r4WIr0oCAcS8rek_QPDeMBw1wBrc0MG9kBPSexEiInRviiwW0P3bezlH3Xl_Q');
    // C000477 Aa111111

    // console.log(environment.investplatform_url + '?clientId=' + this.state.getValue().user.username + '&token=' + token + '&redirectFunction=policyDetail&policyNo=' + this.pageContent.selected_policy);
    let res: any;
    res = environment.investplatform_url + '?clientId=' + username + '&token=' + token + '&redirectFunction=' + redirectFuntion + '&policyNo=' + policy_no + '&lang=' + lang;
    // console.log(res)
    this.iab.create(res);

    // window.location.href = environment.investplatform_url + '?clientId=' + this.state.getValue().user.username + '&token=' + token;
  }

}
