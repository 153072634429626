import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { State, Store } from '@ngrx/store';
import { SetNavigationPage } from './actions/navigation.actions';
import { Location } from '@angular/common';

// declare const ga: any;
@Injectable({
  providedIn: 'root'
})
export class NavigationService implements CanLoad {

  constructor(
    private router: Router,
    private state: State<any>,
    private store: Store<any>,
    private location: Location
  ) { }

  navigateByUrl(url, nextPage, params?) {
    this.store.dispatch(new SetNavigationPage({
      currentPage: nextPage,
      previousPage: this.state.getValue().navigation.currentPage
    }));
    // console.log('this is plain text');
    // console.log('outside if', ga);
    // if (ga) {
    //   console.log('inside if', ga);
    //   ga('send', 'pageview', url);
    // }
    // console.log('this is plain text on later lines');
    this.router.navigateByUrl(url, { state: params ? params : {} });
  }

  browserBack() {
    this.store.dispatch(new SetNavigationPage({
      currentPage: this.state.getValue().navigation.previousPage,
      previousPage: this.state.getValue().navigation.currentPage
    }));
  }

  back() {
    // this.store.dispatch(new SetNavigationPage({
    //   currentPage: this.state.getValue().navigation.previousPage,
    //   previousPage: this.state.getValue().navigation.currentPage
    // }));
    this.location.back();
  }

  // Fix the issue 691
  setFromPageForIA01(previousPageID) {
    console.log(previousPageID);
    if (previousPageID === 'la01') {
      this.store.dispatch(new SetNavigationPage({
        IsFromLA01ForIA01Flag: true,
        IsFromG01BForIA01Flag: false,
        IsFromH01AForIA01Flag: false
      }));
    } else if (previousPageID === 'g01b') {
      this.store.dispatch(new SetNavigationPage({
        IsFromLA01ForIA01Flag: false,
        IsFromG01BForIA01Flag: true,
        IsFromH01AForIA01Flag: false
      }));
    } else if (previousPageID === 'h01a') {
      this.store.dispatch(new SetNavigationPage({
        IsFromLA01ForIA01Flag: false,
        IsFromG01BForIA01Flag: false,
        IsFromH01AForIA01Flag: true
      }));
    }
  }

  canLoad() {
    if (this.state.getValue().user.isLoggedIn) {
      return true;
    } else {
      this.router.navigateByUrl('/a01-login-page');
      return false;
    }
  }
}
