import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private translate: TranslateService) {
    }

  convertToDate(dateString: string): Date | null {
    if (dateString === '-') {
      console.log('convertToDate: - is not a date string');
      return null;
    }

    // Split the date string into components
    const parts = dateString.split('/');

    // Check if the format is correct
    if (parts.length !== 3) {
      console.error('convertToDate: Invalid date format. Please use dd/mm/yyyy.');
      return null;
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
    const year = parseInt(parts[2], 10);

    // Create and return the Date object
    const date = new Date(year, month, day);

    // Check for invalid date
    if (isNaN(date.getTime())) {
      console.error('convertToDate: Invalid date.');
      return null;
    }

    return date;
  }

  isDatePlusOneYearLessThanToday(dateToCheck: Date, serverDate: Date): boolean {
    const today = serverDate;

    // Case that RPQ never submitted
    if (dateToCheck == null) {
      return true;
    }

    const datePlusOneYear = new Date(dateToCheck);
    datePlusOneYear.setFullYear(dateToCheck.getFullYear() + 1);

    return datePlusOneYear < today;
  }

  isDate(variable: any): variable is Date {
    return variable instanceof Date && !isNaN(variable.getTime());
  }

  isLisPolicy(policyNo: string): boolean {
    return policyNo.length === 12 ? true : false;
  }

  isPresent(value: any): boolean {
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (value && typeof value === 'object') {
        return !Array.isArray(value) && Object.keys(value).length > 0;
    }

    return false; // For null, undefined, numbers, etc.
  }

  getTranslation(translateText) {
    let translated;
    this.translate.get(translateText).subscribe(res => {
      translated = res;
    });
    return translated;
  }

}
